<nav class="align-items-center flex">
    <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
        <span class="text-800 text-lg" style="text-transform: uppercase">{{item.label}}</span>
    </ng-template>
    <span *ngIf="showCopyToClipboard">
      <button pButton pRipple type="button" icon="pi pi-copy" class="p-button-rounded p-button-text"
              [cdkCopyToClipboard]="getTicketNumberText()"
              (cdkCopyToClipboardCopied)="onCopySuccess($event)"></button>
    </span>
    <span class="{{textClass}}" *ngIf="text">{{text}}</span>
</nav>
