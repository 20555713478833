import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfigService, NotificationService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {Messages} from '../../../../common/messages';
import {Area} from '../../../../api/model/Area.model';
import {ConfirmationService} from 'primeng/api';
import {ZipCode} from '../../../../api/model/ZipCode.model';
import {download} from '../../../../api/model/shared/functions';
import {
  ZipCodeImportExampleComponent
} from '../zip-codes-configurations-component/zip-code/zip-code-import-example/zip-code-import-example.component';
import {
  ZipCodeInsertExampleComponent
} from '../zip-codes-configurations-component/zip-code/zip-code-insert-example/zip-code-insert-example.component';
import {ContentType} from '../../../../api/model/shared/content.type';

@Component({
  selector: 'tj-area-configurations-component',
  templateUrl: './area-configurations.component.html',
  styleUrls: ['./area-configurations.component.scss']
})

export class AreaConfigurationsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  areaEditDialog = false;

  showZipCodeEditDialog = false;

  showInsertZipCodeDialog: boolean;

  areas: Area[];

  _area: Area;

  private _zipCode: ZipCode;

  zipCodes: ZipCode[];

  zipCodesDialog: boolean;

  zipCodeDialog: boolean;

  isSaving = false;

  @ViewChild(ZipCodeImportExampleComponent) infoDialog: ZipCodeImportExampleComponent;
  @ViewChild(ZipCodeInsertExampleComponent) insertInfoDialog: ZipCodeInsertExampleComponent;

  get zipCode(): ZipCode {
    return this._zipCode;
  }

  set zipCode(value: ZipCode) {
    this._zipCode = value;
  }

  set area(area: Area) {
    this._area = area;
  }

  get area() {
    return this._area;
  }

  constructor(private api: ApiService,
              private confirmationService: ConfirmationService,
              private configService: ConfigService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.loadAreas();
  }

  loadAreas() {
    this.api.area.findList()
      .subscribe((areas) => {
        this.areas = areas;
      }, () => {
        this.notificationService.error('Cannot reload areas list');
      });
  }

  private loadZipCodesForArea(area: Area) {
    this.api.area.getZipCodes(area.id).subscribe(
      (areaZipCodes) => {
        this.zipCodes = [...areaZipCodes.zipCodes];
      });
  }

  onEditArea(area: Area) {
    this.area = {...area};
    this.areaEditDialog = true;
  }

  onDeleteArea(areaToRemove: Area) {

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the area?',
      header: 'Area deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletion',
      accept: () => {
        this.area = {...areaToRemove};
        this.deleteArea(areaToRemove.id);
      }
    });
  }

  onNewArea() {
    this.area = {id: null, name: '', code: '', zipCodes: []};
    this.areaEditDialog = true;
  }

  updateArea(area: Area): void {
    const savedAreaIndex = this.areas.findIndex(it => it.id === area.id);
    if (savedAreaIndex !== -1) {
      this.api.area.update(area)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
            this.loadAreas();
            this.notificationService.success(Messages.AREA_UPDATED);
            this.areas[savedAreaIndex] = area;
          },
          errorMessage => {
            this.notificationService.error(errorMessage);
          }, () => {
            this.areaEditDialog = false;
          });
    }
  }

  createArea(area: Area): void {
    this.api.area.create(area)
      .pipe(takeUntil(this.destroy$))
      .subscribe((newArea) => {
          this.loadAreas();
          this.areas.unshift({...area, ...newArea});
          this.configService.reloadAreas();
          this.notificationService.success(Messages.AREA_CREATED);
        },
        errorMessage => {
          this.notificationService.error(errorMessage);
        },
        () => {
          this.areaEditDialog = false;
        });
  }

  deleteArea(areaId: number): void {
    this.api.area.delete(areaId)
      .subscribe(() => {
        const savedAreaIndex = this.areas.findIndex(area => area.id === this.area.id);
        if (savedAreaIndex !== -1) {
          this.areas.splice(savedAreaIndex, 1);
        }
        this.loadAreas();
        this.configService.reloadAreas();
        this.notificationService.success(Messages.AREA_DELETED);
      }, errorMessage => {
        this.notificationService.error(errorMessage);
      });
  }

  hideAreaEditDialog() {
    this.areaEditDialog = false;
  }

  onEditZipCodes(area: Area) {
    this.loadZipCodesForArea(area);
    this.area = area;
    this.zipCodesDialog = true;
  }

  onEditZipCode(zipCode: ZipCode) {
    this._zipCode = zipCode;
    this.showZipCodeEditDialog = true;
  }

  onAreaNewZipCode(area: Area) {
    this.zipCode = {zipCode: '', tag: '', travelFee: 0, active: true, areaCode: ''};
    this.showZipCodeEditDialog = true;
  }

  onDeleteZipCode(zipCodeToRemove: ZipCode) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the [ ' + zipCodeToRemove.zipCode + ' ] zipCode?',
      header: 'ZipCode deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletion',
      accept: () => {
        this._zipCode = {...zipCodeToRemove};
        this.api.area.deleteZipCode(this.area.id, zipCodeToRemove.id)
          .subscribe(() => {
              this.notificationService.success(Messages.ZIP_CODE_DELETED);
              this.loadZipCodesForArea(this.area);
            },
            errorMessage => {
              this.notificationService.error(errorMessage);
            });
      }
    });
  }

  updateAreaZipCode(zipCode: ZipCode, checkExistAreaByZipCode: boolean): void {
    this.api.area.updateZipCode(zipCode, checkExistAreaByZipCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
          this.zipCodes = [...this.zipCodes];
          this.loadZipCodesForArea(this.area);
          this.notificationService.success(Messages.ZIP_CODE_UPDATED);
        },
        error => {
          if (error.status === 409) {
            if (confirm('Zip Code exist in other areas , Press OK to override.')) {
              this.updateAreaZipCode(zipCode, false);
            }
          } else {
            this.notificationService.error(error.error.message);
          }
        },
        () => {
          this.showZipCodeEditDialog = false;
          this.isSaving = false;
        });
  }

  addAreaNewZipCode(zipCode: ZipCode): void {
    zipCode.areaCode = this.area.code;
    this.api.area.addAreaNewZipCode(this.area.id, zipCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((newZipCode) => {
          this.loadZipCodesForArea(this.area);
          this.notificationService.success(Messages.ZIP_CODE_CREATED);
        },
        errorMessage => {
          this.notificationService.error(errorMessage);
        },
        () => {
          this.isSaving = false;
          this.showZipCodeEditDialog = false;
        });
  }

  hideZipCodeEditDialog() {
    this.showZipCodeEditDialog = false;
  }

  onExportAreaZipCodes(area: Area) {
    this.isSaving = true;
    this.api.area.exportAreaZipCodes(area.id).subscribe((value: Blob) => {
        const date = new Date().toISOString().split('T')[0];
        const name = `${area.name}-${area.code}-${date}`;
        download(value, name, ContentType.TEXT_CSV);
        this.notificationService.success(Messages.ZIP_CODE_EXPORTED);
      },
      errorMessage => {
        this.notificationService.error(errorMessage);
      }, () => {
        this.isSaving = false;
      });
  }

  onNewInsertZipCode(zipCodes: []): void {
    this.api.area.addAreaInsertNewZipCodes(this.area.id, zipCodes)
      .subscribe(() => {
          this.loadZipCodesForArea(this.area);
          this.notificationService.success(Messages.ZIP_CODE_INSERTED);
          this.showInsertZipCodeDialog = false;
        },
        errorMessage => {
          this.notificationService.error(errorMessage);
        },
        () => {
          this.isSaving = false;
        });
  }

  onInsertInfo() {
    this.insertInfoDialog.show();
  }

  onEditInsertZipCodeDialog() {
    this.showInsertZipCodeDialog = true;
  }

  hideInsertZipCodeDialog() {
    this.showInsertZipCodeDialog = false;
  }

  onImportZipCodes($event: any, areaId: number) {
    this.isSaving = true;
    this.api.area.importZipCodes($event.event.files[0], areaId).subscribe(
      () => {
        this.loadZipCodesForArea(this.area);
        this.notificationService.success(Messages.ZIP_CODE_IMPORTED);
      },
      errorMessage => {
        this.notificationService.error(errorMessage);
      }, () => {
        this.isSaving = false;
      });
  }

  onImportInfo() {
    this.infoDialog.show();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
