import {Component, OnInit} from '@angular/core';
import {ConfigService, TjKeycloakService} from '../core/services';

@Component({
  selector: 'tj-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  private allMenuItems: any[] = [
    // {
    //   label: 'Dashboard',
    //   // routerLink: ['tickets'],
    //   icon: 'pi pi-home',
    //   requiredRole: '',
    //   restrictedFor: [],
    // },
    {
      label: 'Dashboard',
      routerLink: ['tickets'],
      icon: 'pi pi-ticket',
      requiredRole: 'TICKET_VIEW',
      restrictedFor: [],
    },
    {
      label: 'Invoices',
      routerLink: ['invoices'],
      icon: 'pi pi-dollar',
      requiredRole: 'INVOICE_VIEW'
    },
    {
      label: 'Payroll',
      routerLink: ['payforms'],
      icon: 'pi pi-money-bill',
      requiredRole: 'PAYFORM_VIEW'
    },
    {
      label: 'Accounts',
      routerLink: ['accounts'],
      icon: 'pi pi-users',
      requiredRole: 'PARTNER_VIEW'
    },
    {
      label: 'Technicians',
      routerLink: ['technicians'],
      icon: 'pi pi-briefcase',
      requiredRole: 'TECHNICIAN_VIEW'
    },
    {
      label: 'Routes & Planing',
      routerLink: ['routes/new'],
      icon: 'pi pi-directions',
      requiredRole: ['ROUTES_PLANING_VIEW', 'ROUTES_PLANING_VIEW_ASSIGNED']
    },
    {
      label: 'Invoices stats',
      routerLink: ['invoices/stats'],
      icon: 'pi pi-bars',
      requiredRole: 'INVOICE_VIEW'
    },
    {
      label: 'Scheduler & Calendar',
      routerLink: ['scheduler/calendar'],
      icon: 'pi pi-clock',
      requiredRole: ['CALENDAR_VIEW']
    },
    {
      label: 'Configuration',
      routerLink: ['configurations', 'main'],
      icon: 'pi pi-cog',
      requiredRole: 'GLOBAL_CONFIG_VIEW_MENU'
    },
    {
      label: 'Premium',
      icon: 'pi pi-fw pi-star',
      class: 'in-development',
      requiredRole: 'IN_DEVELOPMENT_VIEW',
      items: [
        {
          label: 'Tracking & Location',
          //   routerLink: '#',
          icon: 'pi pi-eye',
        },
        {
          label: 'Role management',
          url: 'https://auth.servicejan.com',
          icon: 'pi pi-user',
          requiredRole: 'ACCESS_MANAGEMENT_VIEW'
        },
        {
          label: 'Partner login',
          //   routerLink: ['routes'],
          icon: 'pi pi-user',
        },
        {
          label: 'Requirements & COI\'s',
          routerLink: '#',
          icon: 'pi pi-bars',
        },
        {
          label: 'Instructions & Docs',
          routerLink: '#',
          icon: 'pi pi-info-circle',
        },
        {
          label: 'Inventory & Supply',
          routerLink: '#',
          icon: 'pi pi-list',
        },

        {
          label: 'Logs & Statistics',
          url: 'https://st.furnitureservices.com/admin/home.php?display=log_list',
          icon: 'pi pi-chart-bar',
        },
        {
          label: 'Communication',
          routerLink: '#',
          icon: 'pi pi-comments',
        },
        {
          label: 'Reviews & Feedback',
          routerLink: '#',
          icon: 'pi pi-comment',
        },
        {
          label: 'Leads & Posts',
          routerLink: '#',
          icon: 'pi pi-circle-off',
        },
        {
          label: 'Domain Management',
          routerLink: '#',
          icon: 'pi pi-inbox',
        },
        {
          label: 'Marketplace',
          routerLink: '#',
          icon: 'pi pi-money-bill',
        },
      ],
    },

  ];

  model: any[];

  constructor(public keycloakService: TjKeycloakService,
              private configService: ConfigService) {
  }

  ngOnInit() {
    if (this.configService.isEnabledTerritory() && this.keycloakService.hasRole('TERRITORY_MANAGEMENT_VIEW')) {
      const lastElementIndex = this.allMenuItems.length - 1;
      this.allMenuItems.splice(lastElementIndex, 0,
        // {
        //   label: 'Zones Areas',
        //   routerLink: ['configurations', 'zipcodes'],
        //   icon: 'pi pi-map',
        // },
        {
          label: 'Areas And Zip Codes',
          routerLink: ['configurations', 'area', 'zipCodes'],
          icon: 'pi pi-map',
        },
        {
          label: 'Territories Management',
          routerLink: ['configurations', 'areas'],
          icon: 'pi pi-table',
        });
    }
    this.model = this.allMenuItems;
  }
}
