import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'tj-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  menuMode = 'static';

  colorScheme = 'light';

  menuTheme = 'layout-sidebar-darkgray';

  inputStyle = 'outlined';

  ripple: boolean;

  title = 'app';

  constructor(private primengConfig: PrimeNGConfig) {
  }

  ngOnInit() {
      this.primengConfig.ripple = true;
  }
}
