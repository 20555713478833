import {AddressDetails} from './Address.model';
import {ContactPerson} from './ContactPerson.model';
import {Type} from 'class-transformer';
import {BaseIdModel} from './BaseIdModel';
import {PayFormDateRangeType} from '../../main/shared/enums/payFormDateRange.type';
import {PayType} from '../../main/shared/enums/pay.type';
import {Area} from './Area.model';
import {Vacation} from './Vacation.model';
import {WeeklyOff} from './WeeklyOff.model';


export class Technician extends BaseIdModel {

  name: string;

  username: string;

  personnelCode: string;

  password: string;

  firstName: string;

  lastName: string;

  companyName: string;

  phoneNumber: string;

  email: string;

  speciality: string;

  notes: string;

  percent: number;

  active?: boolean;

  payType: PayType;

  showOnCalendar: boolean;
  visibleInsideTicket: boolean;

  maxStops: number;

  maxWorkHours: number;

  payFormDateRangeType: string;

  unit: number;

  areas: Area[];

  vacations: Vacation [];

  @Type(() => AddressDetails)
  address: AddressDetails;

  @Type(() => ContactPerson)
  contactPersons: ContactPerson[];

  weeklyOffs: WeeklyOff[];

  static buildMinimalTechnician(): Technician {
    const technician = new Technician();
    technician.contactPersons = [];
    technician.payFormDateRangeType = PayFormDateRangeType.WEEKLY;
    technician.visibleInsideTicket = true;
    return technician;
  }
}
