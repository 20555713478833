<div *ngIf="findZipCode === null">
  <div class="w-full small-3 cell bg-red-600 cell my-3 p-1 small-3 text-center text-white">
    Out Of Service Area
  </div>
</div>
<div *ngIf="findZipCode != null && findZipCode.travelFee > 0">
  <div class="w-full small-3 cell bg-orange-600 cell my-3 p-1 small-3 text-center text-white">
    Additional travel fee  {{findZipCode?.travelFee | currency: 'USD'}}
  </div>
</div>
<div *ngIf="findZipCode != null && findZipCode.travelFee === 0">
  <div class="w-full small-3 cell bg-green-600 cell my-3 p-1 small-3 text-center text-white">
    Within Local Service Area
  </div>
</div>
