import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ZipCode} from '../../../../../../api/model/ZipCode.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ConfigService, NotificationService} from '../../../../../../core/services';
import {Messages} from '../../../../../../common/messages';

@Component({
  selector: 'tj-zip-code-chips-form',
  templateUrl: './zip-code-chips-form.component.html',
  styleUrls: ['./zip-code-chips-form.component.scss']
})
export class ZipCodeChipsFormComponent implements OnInit {

  private readonly zipCodeRegExp = new RegExp('^\\d{5}(?:#\\d+(?:\\.\\d{1,2})?$)?$');

  @Output('onCreateInsertZipCodes') createZipCodesEmitter: EventEmitter<any> = new EventEmitter<ZipCode>();

  @Output('onCancel') cancelEmitter: EventEmitter<any> = new EventEmitter<any>();

  insetZipCodeForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private notificationService: NotificationService,
              public config: ConfigService) {
  }

  ngOnInit(): void {
    this.insetZipCodeForm = this.fb.group({
      zipCodes: this.fb.control([]),
    });
  }

  getControl(fcName: string): UntypedFormControl {
    return this.insetZipCodeForm?.get(fcName) as UntypedFormControl;
  }

  onSaveZipCode() {
    this.createZipCodesEmitter.emit(this.insetZipCodeForm.value);
  }

  onCancel() {
    this.cancelEmitter.emit();
  }

  onInsertZipCodes(event) {
    const zipCode = event.value.trim();
    if (zipCode.match(this.zipCodeRegExp) !== null) {
      const allZipCodes = this.getControl('zipCodes').value;
      this.getControl('zipCodes').value.pop();
      allZipCodes.push(zipCode);
      this.getControl('zipCodes').setValue(allZipCodes);
    } else {
      this.notificationService.error(Messages.ZIP_CODE_ADD_FAILED + ' "' + event.value + '"');
      this.getControl('zipCodes').value.pop();
    }
  }

  clearZipCodes() {
    this.getControl('zipCodes').setValue([]);
  }
}
