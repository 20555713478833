import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { StyleClassModule } from 'primeng/styleclass';
import { AppConfigModule } from './config/app.config.module';
import { AppLayoutComponent } from './app.layout.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppRightMenuComponent } from './app.rightmenu.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppSearchComponent } from './app.search.component';
import { AppFooterComponent } from './app.footer.component';
import { BreadcrumbService } from './service/app.breadcrumb.service';
import { CoreModule } from '../core/core.module';
import {TicketModule} from '../main/ticket/ticket.module';
import {ClipboardModule} from '@angular/cdk/clipboard';


@NgModule({
    declarations: [
      AppLayoutComponent,
      AppBreadcrumbComponent,
      AppSidebarComponent,
      AppTopbarComponent,
      AppRightMenuComponent,
      AppMenuComponent,
      AppMenuitemComponent,
      AppSearchComponent,
      AppFooterComponent
    ],
  imports: [
    CoreModule,
    BreadcrumbModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    ButtonModule,
    TooltipModule,
    RippleModule,
    MenuModule,
    RouterModule,
    DropdownModule,
    DividerModule,
    AppConfigModule,
    DialogModule,
    TicketModule,
    StyleClassModule,
    ClipboardModule
  ],
  exports: [
    AppLayoutComponent
  ],
  providers: [
    BreadcrumbService
  ]
})
export class AppLayoutModule { }
