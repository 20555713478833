import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AreaToZipCodeModel} from '../../../../../../api/model/AreaToZipCode.model';
import {environment} from '../../../../../../../environments/environment';


@Component({
  selector: 'tj-area-to-zip-code-list',
  templateUrl: './area-to-zip-code-list.component.html',
  styleUrls: ['./area-to-zip-code-list.component.scss']
})
export class AreaToZipCodeListComponent {

  protected readonly env = environment;

  @Input('areaToZipCodes') areaToZipCodes: AreaToZipCodeModel[];

  @Output('onDelete') deleteEmitter: EventEmitter<AreaToZipCodeModel> = new EventEmitter<any>();
  @Output('onSort') onSortEmitter: EventEmitter<AreaToZipCodeModel> = new EventEmitter<any>();

  constructor() {
  }

  onDeleteZipCode(areaToZipCodeToRemove: AreaToZipCodeModel) {
    this.deleteEmitter.emit(areaToZipCodeToRemove);
  }

  sort($event: any) {
    this.onSortEmitter.emit($event);
  }
}

