import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-route-list-filter-form',
  templateUrl: './route-filter-form.component.html',
  styleUrls: ['./route-filter-form.component.scss'],
})
export class RouteListFilterFormComponent extends BaseFormGroup implements OnInit, OnChanges {

  @Input() routeFilter;

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  private formControls;

  isMobile = false;

  constructor(public configService: ConfigService) {
    super();
  }

  ngOnInit() {
    const filter = this.routeFilter || {};
    this.formControls = {
      id: new UntypedFormControl(filter['id'] || ''),
      date: new UntypedFormControl(filter['date'] || ''),
      ticketId: new UntypedFormControl(filter['ticketId'] || ''),
      technicianId: new UntypedFormControl(filter['technicianId'] || ''),
    };
    this.buildForm(this.form, this.formControls, this.group);
    this.defineGetters(this.formControls);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routeFilter && changes.routeFilter.currentValue) {
      this.routeFilter = {};
    }
  }
}
