import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { User } from "../../api/model/User.model";
import { Subject } from "rxjs";
import {environment} from '../../../environments/environment';

declare var Tawk_API: any;

export interface TawkConfiguration {
  uri: string;
  propertyId: string;
  widgetId: string;
  apiKey: string;
}

@Injectable()
export class TawkService {

  private loaded: boolean;
  private loadSubject: Subject<boolean> = new Subject<boolean>();
  private renderer: Renderer2;
  private readonly configuration: TawkConfiguration = environment.tawkTo;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
    // this.load();
  }

  private load(){
    if(this.loaded) {
      return;
    }
    const url = this.configuration.uri + this.configuration.propertyId + '/' + this.configuration.widgetId;
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = 'var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); ' +
      '(function(){ ' +
      'var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; ' +
      's1.async=true; ' +
      's1.src=\'' + url + '\'; ' +
      's1.charset=\'UTF-8\'; ' +
      's1.setAttribute(\'crossorigin\',\'*\'); ' +
      's0.parentNode.insertBefore(s1,s0); ' +
      '})();'
    this.renderer.appendChild(this._document.body, script);
    Tawk_API.onLoad = this.loadedEvent.bind(this);
  }

  private loadedEvent(){
    this.loaded = true;
    this.loadSubject.next(this.loaded);
  }

  public updateUser(user: User) {
    this.loadedWrapper(() => {this.updateAttributes(user)});
  }

  private loadedWrapper(func: any){
    if(!this.loaded){
      const sub = this.loadSubject.asObservable().subscribe({
        next: () => {
          func();
          sub.unsubscribe();
        },
        error: () => {}
      });
    } else {
      func();
    }
  }

  public expandChatWindow(show: boolean = false){
    this.loadedWrapper(() => show ? Tawk_API.maximize() : Tawk_API.minimize());
  }

  public setChatVisibility(show: boolean = false) {
    this.loadedWrapper(() => show ? Tawk_API.showWidget() : Tawk_API.hideWidget());
  }

  private updateAttributes(user: User){
    Tawk_API.setAttributes({
      'name'  : user.name,
      'email' : user.email,
      'userId'  : user.id,
    }, function(){});
  }
}
