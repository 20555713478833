import {Component, OnDestroy, OnInit} from '@angular/core';
import {loadScript} from '@paypal/paypal-js';
import {ApiService} from '../../../../api/service/api.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {ConfigService, NotificationService} from '../../../../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {Ticket} from '../../../../api/model/Ticket.model';
import {Invoice} from '../../../../api/model/Invoice.model';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {DropdownModel} from '../../../../api/model/shared/dropdown.model';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {forkJoin} from 'rxjs';
import {InvoiceService} from '../../../invoice/service/invoice.service';


enum PaymentStep {
  INITIAL = 'INITIAL',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

enum PaymentItemType {
  TICKET = 'TICKET',
  INVOICE = 'INVOICE',
}


@Component({
  selector: 'tj-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent extends BaseFormGroup implements OnInit, OnDestroy {

  form: UntypedFormGroup;

  private readonly taxRate = 8.875;

  private readonly ccFeeRate = 3.5;

  readonly PAYMENT_BY_CARD = 0;

  readonly PAYMENT_BY_VAULT = 1;

  isSubmitting = false;

  paypal;

  cardFields;

  vaultId;

  paymentStep: PaymentStep = PaymentStep.INITIAL;

  paymentSteps: typeof PaymentStep = PaymentStep;

  paymentMethodIndex = this.PAYMENT_BY_CARD;

  sourceEntityType: PaymentItemType | null = null;

  ticket: Ticket | null = null;

  invoice: Invoice | null = null;

  orderData = null;

  lastError = null;

  paymentDetails = {
    netAmount: 0,
    applyTax: false,
    taxRate: 0,
    taxFee: 0,
    applyCCFee: false,
    total: 0,
    ccFeeRate: 0,
    ccFee: 0
  };

  person = {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  };

  formChangesSubscription;

  itemType: PaymentItemType = null;

  items: Ticket[] | Invoice[];

  itemId: number[];

  isSandbox = true;

  itemTypes: DropdownModel[] = [
    {label: 'Ticket', value: PaymentItemType.TICKET},
    {label: 'Invoice', value: PaymentItemType.INVOICE}
  ];

  private cardFieldsInitialized = false; // Track credit card fields initialization state

  constructor(
    private api: ApiService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.initParams();
    this.initForm();
    this.initPaypal();
  }

  initParams() {
    this.route.queryParams.subscribe(params => {
      if (params.itemType === 'invoice') {
        this.itemType = PaymentItemType.INVOICE;
      }
      if (params.itemType === 'ticket') {
        this.itemType = PaymentItemType.TICKET;
      }
      this.itemId = params.itemId.split(',').map(it => +it);
      this.loadItem();
    });
  }

  initForm() {
    this.form = new UntypedFormGroup({});
    const formControls = {
      itemType: this.fb.control(this.itemType),
      netAmount: this.fb.control(0),
      applyTax: this.fb.control(false),
      taxRate: this.fb.control(this.taxRate),
      applyCCFee: this.fb.control(false),
      ccFeeRate: this.fb.control(this.ccFeeRate),
      email: this.fb.control(''),
      description: this.fb.control(''),
      invoiceId: this.fb.control(''),
      saveCard: this.fb.control(true),
      customerName: this.fb.control(''),
      address: this.fb.control('')
    };

    this.buildForm(this.form, formControls, this.group);
    this.defineGetters(formControls);
    this.formChangesSubscription = this.form.valueChanges
      .subscribe(c => {
        const pd = this.paymentDetails;

        pd.netAmount = parseFloat(c.netAmount);
        pd.applyTax = c.applyTax;
        pd.taxRate = parseFloat(c.taxRate);
        pd.applyCCFee = c.applyCCFee;
        pd.ccFeeRate = parseFloat(c.ccFeeRate);

        pd.taxFee = pd.applyTax ? pd.netAmount * c.taxRate / 100 : 0;

        pd.ccFee = pd.applyCCFee ? pd.netAmount * pd.ccFeeRate / 100 : 0;

        pd.total = pd.netAmount + pd.taxFee + pd.ccFee;
      });
  }

  onSubmit() {
    if (this.paymentMethodIndex === this.PAYMENT_BY_CARD) {
      this.onSubmitByCard();
    } else {
      this.onSubmitByVaultId();
    }
  }

  onSubmitByCard() {
    const netAmount = this.form.get('netAmount').getRawValue() as number;
    if (netAmount <= 0) {
      this.notificationService.error('Net amount should be greater than 0');
    } else if (this.form.valid && this.cardFields) {
      this.isSubmitting = true;
      this.cardFields.submit();
    } else {
      this.isSubmitting = false;
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  onSubmitByVaultId() {
    const netAmount = this.form.get('netAmount').getRawValue() as number;
    if (netAmount <= 0) {
      this.notificationService.error('Net amount should be greater than 0');
    } else if (!this.vaultId || !this.vaultId.trim()) {
      this.notificationService.error('Vault id is not specified');
    } else if (this.form.valid) {
      const vaultId = this.vaultId;
      const order = this.prepareOrder();

      this.isSubmitting = true;
      return this.api.payment
        .createPaypalOrder({
          ...order,
          vaultId
        }, this.isSandbox)
        .toPromise()
        .then((orderData) => {
          this.orderData = orderData;
          this.paymentStep = PaymentStep.COMPLETED;
          return orderData.id as string;
        })
        .catch((error) => {
          this.paymentStep = PaymentStep.FAILED;
          this.lastError = error;
          this.isSubmitting = false;
          console.error('Something went wrong:', error);
        });
    } else {
      this.isSubmitting = false;
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  ngOnDestroy() {
    this.formChangesSubscription.unsubscribe();
  }

  getControl(fcName: string): UntypedFormControl {
    return this.currentFormGroup.get(fcName) as UntypedFormControl;
  }

  private initPaypal() {
    this.isSandbox = this.configService.isSandbox();
    const paypalClientId = this.configService.getPaypalClientId(this.isSandbox);
    loadScript({
      // client id should be coming from configs
      clientId: paypalClientId,
      vault: true,
      components: 'buttons,marks,card-fields',
    }).then((paypal) => {
      this.paypal = paypal;

      if (this.paymentMethodIndex === this.PAYMENT_BY_CARD) {
        // Create the Card Fields Component and define callbacks
        const cardField = paypal.CardFields({

          createOrder: () => {
            const order = this.prepareOrder();

            return this.api.payment
              .createPaypalOrder(order, this.isSandbox)
              .toPromise()
              .then((orderData) => {
                return orderData.id as string;
              });
          },

          // This method is called when the user approves the payment and orderid is returned
          onApprove: (data) => {
            const orderId = data.orderID as string;
            return this.api.payment
              .capturePaypalOrder(orderId, this.isSandbox)
              .toPromise()
              .then((orderData) => {
                this.isSubmitting = false;
                this.orderData = orderData;
                this.paymentStep = PaymentStep.COMPLETED;
              });
          },

          onError: (error) => {
            this.paymentStep = PaymentStep.FAILED;
            this.lastError = error;
            console.error('Something went wrong:', error);
          },
        });


        // Render each field after checking for eligibility
        if (cardField.isEligible()) {
          // Clear existing fields to avoid duplication
          this.clearCardFieldContainers();

          const nameField = cardField.NameField({placeholder: 'Full Name'});
          nameField.render('#card-name-field-container');
          const numberField = cardField.NumberField({
            placeholder: 'Card Number',
          });
          numberField.render('#card-number-field-container');

          const cvvField = cardField.CVVField({placeholder: 'CVV'});
          cvvField.render('#card-cvv-field-container');

          const expiryField = cardField.ExpiryField({placeholder: 'MM/YY'});
          expiryField.render('#card-expiry-field-container');
        }
        this.cardFields = cardField;
        this.cardFieldsInitialized = true; // Mark as credit card fields initialized
        console.log('init credit card');
      }
    });
  }

  private prepareOrder() {
    const data = this.form.getRawValue();
    const items = this.items.map(it => it.id);
    const order = {
      order: [{
        itemType: this.itemType,
        items,
        description: data.description,
        customerName: data.customerName,
        address: data.address,
        netAmount: this.paymentDetails.netAmount,
        total: this.paymentDetails.total,
        applyTax: this.paymentDetails.applyTax,
        applyCCFee: this.paymentDetails.applyCCFee,
        taxRate: 0,
        taxFee: 0,
        ccFeeRate: 0,
        ccFee: 0
      }],
      email: data.email,
      savePaymentMethod: data.saveCard
    };

    if (this.paymentDetails.applyTax) {
      order.order[0].taxRate = this.paymentDetails.taxRate;
      order.order[0].taxFee = this.paymentDetails.taxFee;
    }

    if (this.paymentDetails.applyCCFee) {
      order.order[0].ccFeeRate = this.paymentDetails.ccFeeRate;
      order.order[0].ccFee = this.paymentDetails.ccFee;
    }
    return order;
  }

  private loadItem() {
    if (this.itemType === PaymentItemType.TICKET) {
      this.loadTicket(this.itemId[0]);
    } else if (this.itemType === PaymentItemType.INVOICE) {
      this.loadInvoices(this.itemId);
    }
  }

  private loadTicket(ticketId: number) {
    this.api.ticket.findOne(ticketId)
        .subscribe(ticket => {
          this.items = [ticket];
          this.form.get('customerName').setValue(ticket.customer.name);
          this.form.get('address').setValue(ticket.address.addressFormatted);
          this.form.get('invoiceId').setValue(ticket.id);

          this.setEmail(ticket);

          if (ticket.technicians.length > 0) {
            let sum = 0;

            ticket.technicians.forEach(technician => {
              const bill = technician.bill || 0;
              const creditCard = technician.creditCard || 0;
              sum += bill + creditCard;
            });

            this.form.get('netAmount').setValue(sum);
          }

          this.setPaymentDetails(ticket);
        });
  }

  private loadInvoices(invoicesIds: number[]) {

    const invoiceRequests = invoicesIds.map(id => this.api.invoice.getInvoice(id));
    forkJoin(invoiceRequests)
      .forEach((invoices: Invoice[]) => {

        const eligible = this.invoiceService.checkEligibilityForPayment(invoices);

        const nonPaidInvoices = invoices.filter(it => it.status !== 'PAID');

        if (nonPaidInvoices.length === 0) {
          this.notificationService.error('Invoices already paid');
        } else if (eligible) {
          this.notificationService.error('Selections contains invoices of different accounts');
        } else {
          this.items = nonPaidInvoices;
          const sum = nonPaidInvoices.reduce((partialSum, a) => partialSum + a.totalAmount, 0);
          this.api.ticket.findOne(nonPaidInvoices[0].items[0].ticketId)
            .subscribe(ticket => {
              this.form.get('netAmount').setValue(sum);
              this.form.get('invoiceId').setValue(this.itemId);
              this.setEmail(ticket);
              this.setPaymentDetails(ticket);
            });
        }
      });
  }

  private setEmail(ticket: Ticket) {
    if (ticket.ticketPartnerStatus !== TicketPartnerStatus.PRIVATE) {
      if (ticket.mainPartnerContactPerson && ticket.mainPartnerContactPerson.contacts.length > 0) {
        const accountEmailContact = ticket.mainPartnerContactPerson.contacts.find(c => c.type === 'EMAIL');
        if (accountEmailContact) {
          this.form.get('email').setValue(accountEmailContact.value);
        }
      }
    } else {
      if (ticket.mainCustomerContactPerson && ticket.mainCustomerContactPerson.contacts.length > 0) {
        const customerEmailContact = ticket.mainCustomerContactPerson.contacts.find(c => c.type === 'EMAIL');
        if (customerEmailContact) {
          this.form.get('email').setValue(customerEmailContact.value);
        }
      }
    }
  }

  private setPaymentDetails(ticket: Ticket) {
    if (ticket.ticketPartnerStatus === TicketPartnerStatus.REGISTERED) {
      this.paymentMethodIndex = this.PAYMENT_BY_VAULT;
      this.vaultId = ticket.partner?.payment?.transactionId;
    }

    const ccFeeApplyConfig = this.configService.getPrivateAccountDefaultCCFeeApplyConfig();
    if (ticket.ticketPartnerStatus !== TicketPartnerStatus.REGISTERED && ccFeeApplyConfig) {
      this.form.get('applyCCFee').setValue(true);
    }

  }

  backToPayment() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/payment'], {
        queryParams: {itemType: this.itemType.toString().toLowerCase(), itemId: this.itemId[0]},
        queryParamsHandling: 'merge'
      });
    });
  }

  initPayByCreditCard() {
    this.paymentMethodIndex = this.PAYMENT_BY_CARD;
    console.log('change tab to Credit Card');
    if (this.cardFieldsInitialized) {
      // If already initialized, do not reinitialize
      console.log('credit card is already init');
      return;
    }
    console.log('credit card not initialized');
    this.initPaypal();
  }

  initVaultId() {
    this.paymentMethodIndex = this.PAYMENT_BY_VAULT;
    console.log('change tab to Vault id');
  }

  private clearCardFieldContainers() {
    document.getElementById('card-name-field-container').innerHTML = '';
    document.getElementById('card-number-field-container').innerHTML = '';
    document.getElementById('card-cvv-field-container').innerHTML = '';
    document.getElementById('card-expiry-field-container').innerHTML = '';
  }

  back() {
    window.history.back();
  }
}
