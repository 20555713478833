import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Technician} from '../../../../api/model/Technician.model';
import {UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {ApiService} from '../../../../api/service/api.service';
import {NotificationService} from '../../../../core/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Messages} from '../../../../common/messages';

@Component({
  selector: 'tj-technician-edit',
  templateUrl: './technician-edit.component.html',
  styleUrls: ['./technician-edit.component.scss']
})
export class TechnicianEditComponent implements OnInit, OnDestroy {

  @Output('onSave') technicianSave = new EventEmitter<Technician>();

  private destroy$: Subject<boolean> = new Subject<boolean>();

  private _technician: Technician;

  @Input() set technician(technician: Technician) {
    this._technician = technician;
  }

  get technician() {
    return this._technician;
  }

  form: UntypedFormGroup;

  isSaving = false;

  constructor(private api: ApiService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
  }

  onSave() {
    if (this.form.valid) {
      this.isSaving = true;
      const formValues = this.form.value;
      const valueToSave = {...this.technician, ...formValues} as Technician;
      if (this.technician.id) {
        this.update(valueToSave);
      } else {
        this.create(valueToSave);
      }
    } else {
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  private create(newTechnician: Technician): void {
    this.api.technician.create(newTechnician)
      .pipe(takeUntil(this.destroy$))
      .subscribe((technician: Technician) => {
          this.technicianSave.emit(technician);
          this.form.reset();
          this.isSaving = false;
          this.notificationService.success(Messages.TECHNICIAN_CREATED);
        },
        errorMessage => {
          this.isSaving = false;
          this.notificationService.error(errorMessage);
        }, () => {
        });
  }

  private update(updatedTechnician: Technician): void {
    this.api.technician.update(updatedTechnician)
      .pipe(takeUntil(this.destroy$))
      .subscribe((technician: Technician) => {
        this.technicianSave.emit(technician);
        this.isSaving = false;
        this.notificationService.success(Messages.TECHNICIAN_UPDATED);
      }, errorMessage => {
        this.isSaving = false;
        this.notificationService.error(errorMessage);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
