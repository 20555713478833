<form novalidate
      autocomplete="off"
      [formGroup]="filterForm"
      (reset)="onSearchReset()"
      (ngSubmit)="onFilterRoutes()">
  <div class="grid">
    <div class="col-10">
      <tj-area-to-zip-code-list-filter-form [areaToZipCodeFilter]="areaToZipCodeFilter"
                                            [form]="filterForm"
                                            group="routeFilter"></tj-area-to-zip-code-list-filter-form>
    </div>
    <div class="col-2 flex align-items-end">
      <div class="col-12 align-content-end flex justify-content-between">
        <div>
          <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
          <button pButton type="submit" label="Search" icon="pi pi-search"></button>
        </div>
      </div>
    </div>
    <div class="col-12 flex">
      <tj-area-to-zip-code-search-chips [areaToZipCodeFilter]="areaToZipCodeFilter"
                                        (remove)="onRemoveFilter($event)"></tj-area-to-zip-code-search-chips>
    </div>

  </div>
</form>
