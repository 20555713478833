import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Customer} from '../../../../api/model/Customer.model';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {Observable} from 'rxjs';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-customer-create-form',
  templateUrl: './customer-create-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerCreateFormComponent extends BaseFormGroup implements OnInit {
  @Input() customer: Customer;
  @Input() form: UntypedFormGroup;
  @Input() group: string;
  private readonly zipCodeRegExpFiveDigit = new RegExp('^\\d{5}(\\D.*)?$');

  public nameObs: Observable<string>;
  customerAddressZipCode: string;

  constructor(private fb: UntypedFormBuilder,
              public configService: ConfigService) {
    super();
  }

  ngOnInit() {
    const customer = this.customer;
    // add address confirmation
    const formControls = {
      name: this.fb.control(customer.name, Validators.required),
      addressConfirmed: this.fb.control(customer.addressConfirmed || false)
    };
    if (customer.id) {
      formControls['id'] = this.fb.control(customer.id, Validators.required);
    }
    this.buildForm(this.form, formControls, this.group);
    this.defineGetters(formControls);
    this.nameObs = this.form.get(this.group).get('name').valueChanges;
  }

  changeZipCode(foundedZipCode: string): void {
    if (!this.configService.isEnabledTerritory()) {
      return;
    }
    if (foundedZipCode && foundedZipCode.match(this.zipCodeRegExpFiveDigit)) {
      this.customerAddressZipCode = foundedZipCode.slice(0, 5);
      return;
    }
    this.customerAddressZipCode = null;
  }
}
