import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Partner} from '../../../../api/model/Partner.model';
import {UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {ApiService} from '../../../../api/service/api.service';
import {NotificationService} from '../../../../core/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Messages} from '../../../../common/messages';

@Component({
  selector: 'tj-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.scss']
})
export class PartnerEditComponent implements OnInit, OnDestroy {

  @Output('onSave') partnerSave = new EventEmitter<Partner>();

  private destroy$: Subject<boolean> = new Subject<boolean>();

  private _partner: Partner;

  @Input() set partner(partner) {
    this._partner = partner;
  }

  get partner() {
    return this._partner;
  }

  form: UntypedFormGroup;

  isSaving = false;

  constructor(private api: ApiService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
  }

  onSave() {
    if (this.form.valid) {
      this.isSaving = true;
      const formValues = this.form.value;
      const valueToSave = {...this.partner, ...formValues} as Partner;
      if (this.partner.id) {
        this.update(valueToSave);
      } else {
        this.create(valueToSave);
      }
    } else {
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  create(valueToSave): void {
    this.api.partner.create(valueToSave)
      .pipe(takeUntil(this.destroy$))
      .subscribe((partner: Partner) => {
          this.form.reset();
          this.partnerSave.emit(partner);
          this.isSaving = false;
          this.notificationService.success(Messages.PARTNER_CREATED);
        },
        errorMessage => {
          this.isSaving = false;
          this.notificationService.error(errorMessage);
        });
  }

  update(valueToSave): void {
    this.api.partner.update(valueToSave)
      .pipe(takeUntil(this.destroy$))
      .subscribe((partner: Partner) => {
          this.partnerSave.emit(partner);
          this.isSaving = false;
          this.notificationService.success(Messages.PARTNER_UPDATED);
        },
        errorMessage => {
          this.isSaving = false;
          this.notificationService.error(errorMessage);
        });
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
