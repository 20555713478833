import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ZipCode} from '../../../../../../api/model/ZipCode.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../../../../../core/services';

@Component({
  selector: 'tj-zip-code-form',
  templateUrl: './zip-code-form.component.html',
  styleUrls: ['./zip-code-form.component.scss']
})
export class ZipCodeFormComponent implements OnInit {

  @Output('onCreateZipCode') createZipCodeEmitter: EventEmitter<ZipCode> = new EventEmitter<ZipCode>();

  @Output('onUpdateZipCode') updateZipCodeEmitter: EventEmitter<ZipCode> = new EventEmitter<ZipCode>();

  @Output('onCancel') cancelEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() zipCode: ZipCode;

  zipCodeForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, public config: ConfigService) {
  }

  ngOnInit(): void {
    const isUpdating = !!this.zipCode.id;
    this.zipCodeForm = this.fb.group({
      active: this.fb.control(this.zipCode.active),
      zipCode: this.fb.control({value: this.zipCode.zipCode, disabled: isUpdating},
        [Validators.required, Validators.pattern(/^[0-9]{5}$/)]),
      travelFee: this.fb.control(this.zipCode.travelFee)
    });
  }

  getControl(fcName: string): UntypedFormControl {
    return this.zipCodeForm?.get(fcName) as UntypedFormControl;
  }

  onSaveZipCode() {
    const zipCode = this.zipCodeForm.value as ZipCode;
    zipCode.id = this.zipCode.id;
    if (this.zipCode.id) {
      this.updateZipCodeEmitter.emit(zipCode);
    } else {
      this.createZipCodeEmitter.emit(zipCode);
    }
  }

  onCancel() {
    this.cancelEmitter.emit();
  }
}
