<div class="flex flex-wrap">
  <div *ngFor="let filter of routeFilter | keys" class="mr-2 mb-2">
    <ng-container [ngSwitch]="filter.key">
      <p-chip *ngSwitchCase="'date'" [removable]="true" (onRemove)="onRemoveChip(filter.key)"
              label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip *ngSwitchCase="'id'" [removable]="true" (onRemove)="onRemoveChip(filter.key)"
              label="{{filterNames[filter.key]}}: {{filter.value}}"></p-chip>
      <p-chip *ngSwitchCase="'ticketId'" [removable]="true" (onRemove)="onRemoveChip(filter.key)"
              label="{{filterNames[filter.key]}}: {{filter.value}}"></p-chip>
      <p-chip *ngSwitchCase="'technicianId'" [removable]="true" (onRemove)="onRemoveChip(filter.key)"
              label="{{filterNames[filter.key]}}: {{techniciansMap[filter.value].name}}"></p-chip>
      <p-chip *ngSwitchDefault [removable]="true" (onRemove)="onRemoveChip(filter.key)"
              label="{{filterNames[filter.key]}}: {{filter.value}}"></p-chip>
    </ng-container>
  </div>
</div>
