import {AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, Validator} from '@angular/forms';
import {ConfigService} from '../../../../core/services';
import {makeProvider} from '../../utils/abstract-control-value-accessor';

@Component({
  selector: 'tj-percentage-input',
  templateUrl: './percentage-input.component.html',
  styleUrls: ['./percentage-input.component.scss'],
  providers: [makeProvider(PercentageInputComponent),
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PercentageInputComponent),
      multi: true,
    }]
})
export class PercentageInputComponent implements ControlValueAccessor, Validator, AfterViewInit {

  // @Input() placeholder;

  @ViewChild('input') inputRef: ElementRef;

  @Input() disabled = false;

  innerValue: any = '';

  private propagateChange;

  private onTouchedCallback;

  constructor(public config: ConfigService) {
  }

  ngAfterViewInit() {
    this.inputRef.nativeElement.value = this.innerValue;
  }

  onChange(e: Event, value: any) {
    this.changeValue(value);
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  changeValue(value): void {
    const propagateChange = this.propagateChange ? this.propagateChange : () => {
    };
    if (value === null || isNaN(value) || value === '') {
      this.innerValue = 0;
      propagateChange(0);
    } else {
      this.innerValue = value;
      propagateChange(parseFloat(this.innerValue));
    }
  }

  writeValue(value: any): void {
    this.changeValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  validate(c: AbstractControl): { [key: string]: any; } {
    return (!c.valid) ? null : c.errors;
  }

}
