import {ProgressBarService} from '../../../core/services/progressbar.service';

export namespace jsUtils {
  export function downloadFile(url: string, name: string, progressBarService: ProgressBarService) {
    progressBarService.show();
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(resp => resp.blob())
      .then(blob => {
        // tslint:disable-next-line:no-shadowed-variable
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        progressBarService.hide();
      })
      .catch(() => {
        progressBarService.hide();
        alert('oh no!');
      });
  }
}
