import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ConfigService } from './services';
import { ClientSignture } from '../api/model/ticket/client-signature';

@Injectable()
export class ClientSignatureInterceptor implements HttpInterceptor {

  readonly HEADER_NAME = 'X-Client-Signature';

  clientSignature: ClientSignture;

  constructor(private configService: ConfigService) {
    this.configService.generateClientSignature();
    this.clientSignature = this.configService.getClientSignature();
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.clientSignature.refreshTimestamp();
    const reqCopy = httpRequest.clone({
      headers: httpRequest.headers.append(this.HEADER_NAME, this.clientSignature.convertToBase64())
    });
    return next.handle(reqCopy);
  }
}
