import { AddressDetails } from '../../../api/model/Address.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
    transform(address: AddressDetails) {
    const appt = address.apt ? `Appt. ${address.apt}` : null;

    return [
      address.street,
      appt,
      address.city,
      address.state,
      address.postalCode
    ]
      .filter(part => !!part)
      .join(', ');
  }
}
