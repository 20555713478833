import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AuthGuardService} from '../../core/services';
import {InvoiceModule} from '../invoice/invoice.module';
import {RouterModule} from '@angular/router';
import {TicketModule} from '../ticket/ticket.module';
import {
  TechnicianEditComponent,
  TechnicianEditFormComponent,
  TechnicianListComponent,
  TechnicianSearchComponent,
  TechnicianSearchFormComponent,
  TechnicianSearchLabelsComponent
} from './components';
import {TechniciansComponent} from './pages/technicians/technicians.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {WeeklyOffFormComponent} from '../weeklyoff/forms/weekly-off-form.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    InvoiceModule,
    RouterModule.forRoot([
      // {path: 'technicians/create', component: TicketCreateComponent, canActivate: [AuthGuardService]},
      {
        path: 'technicians',
        component: TechniciansComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['TECHNICIAN_VIEW']
        }
      },
    ]),
    TicketModule,
    MultiSelectModule
  ],
  declarations: [
    TechniciansComponent,
    TechnicianEditFormComponent,
    TechnicianEditComponent,
    TechnicianSearchFormComponent,
    TechnicianSearchComponent,
    TechnicianSearchLabelsComponent,
    TechnicianListComponent,
    WeeklyOffFormComponent
  ]
})
export class TechnicianModule {
}
