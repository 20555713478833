<p-dialog header="Edit image"
          [draggable]="false"
          [resizable]="false"
          [blockScroll]="true"
          [dismissableMask]="true"
          [responsive]="true"
          [(visible)]="display"
          [maximizable]="false"
          (onHide)="onHide()" (onShow)="onShow()">


  <div class="col-12" *ngIf="imageFlow == 'CROP'">
    <tj-image-cropper (crop)="crop($event)" [imageUrl]="getImageUrl()" #cropperComponent></tj-image-cropper>
  </div>
  <div class="col-12" *ngIf="imageFlow == 'OVERLAY'">
    <tj-image-overlay [croppedUrl]="getImageCroppedUrl()" #overlayComponent></tj-image-overlay>
  </div>

  <p-footer>
    <div class="lg:col-offset-8 lg:col-4" *ngIf="imageFlow == 'OVERLAY'">
      <button (click)="saveImage()" class="ui-button-info p-button-raised" label="Save" pButton></button>
    </div>
  </p-footer>
</p-dialog>
