import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {AttachmentListResponseModel, GalleryUploadResponseModel} from '../../main/shared/models/Gallery.model';
import {IdResponseModel} from '../model/shared/Response.model';
import {
  AttachmentModel,
  AudioRequestModel,
  DocumentRequestModel,
  ImageRequestModel,
  VideoRequestModel
} from '../../main/shared/models/ImageRequest.model';

@Injectable()
export class GalleryApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'tickets/';

  public getImages(galleryId: number, ticketId: number): Observable<AttachmentListResponseModel<ImageRequestModel>> {
    return this.getAttachments(galleryId, ticketId, 'images');
  }

  public getVideos(galleryId: number, ticketId: number): Observable<AttachmentListResponseModel<VideoRequestModel>> {
    return this.getAttachments(galleryId, ticketId, 'videos');
  }

  public getAudios(galleryId: number, ticketId: number): Observable<AttachmentListResponseModel<AudioRequestModel>> {
    return this.getAttachments(galleryId, ticketId, 'audios');
  }

  public getDocuments(galleryId: number, ticketId: number): Observable<AttachmentListResponseModel<DocumentRequestModel>> {
    return this.getAttachments(galleryId, ticketId, 'documents');
  }

  private getAttachments<T extends AttachmentModel>(galleryId: number, ticketId: number, path: string): Observable<AttachmentListResponseModel<T>> {
    const url = `${this.apiEndpoint}${ticketId}/gallery/${galleryId}/${path}`;
    return this.requestService
      .get(url)
      .pipe(
        map((response: any) => response.response),
        catchError(this.handleError)
      );
  }

  public removeAll(galleryId: number, ids: number[], ticketId: number): Observable<IdResponseModel> {
    const url = `${this.apiEndpoint}${ticketId}/gallery/removeAll`;
    return this.requestService
      .post(url, {
        ids,
        galleryId,
      })
      .pipe(
        map((response: IdResponseModel) => response),
        catchError(this.handleError)
      );
  }


  public uploadFile(file: File[], galleryId: number, ticketId: number): Observable<GalleryUploadResponseModel> {
    const url = `${this.apiEndpoint}${ticketId}/gallery/uploadFile`;
    return this.requestService.upload(url, file, galleryId);
  }

  public edit(ticketId: number, galleryId: number, imageId: number, savedImage: File): Observable<any> {
    const url = `${this.apiEndpoint}${ticketId}/gallery/${galleryId}/images/${imageId}`;
    return this.requestService.upload(url, [savedImage], galleryId);
  }
}
