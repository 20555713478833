import { Customer } from './Customer.model';
import { Partner } from './Partner.model';
import { ContactPerson } from './ContactPerson.model';
import { Type } from 'class-transformer';

export class InvoiceItem {

    externalReferenceCode: string;

    invoiceAmount: number;

    invoiceExpenses: number;

    invoiceTax: number;

    report: string;

  serviceDate: Date;

  serviceTimeEnd: Date;

  serviceTimeStart: Date;

  totalAmount: number;

  ticketId: number;

  ticketPartnerStatus: string;

  @Type(() => Partner)
  partner: Partner;

  @Type(() => Customer)
  customer: Customer;

  mainPartnerContactPerson: ContactPerson;

  mainCustomerContactPerson: ContactPerson;

  technicians: [
    {
      name: string;
      id: number;
      personnelCode: string;
    }
  ];
}
