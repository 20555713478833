import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {Page, PageQuery, SortQuery} from '../model/shared/Page.model';
import {InvoiceList, UserInvoices} from '../model/InvoiceList.model';
import {TicketFilter} from '../model/TicketFilter';
import {Invoice} from '../model/Invoice.model';
import {plainToClass} from 'class-transformer';
import {EntityResponseModel} from '../model/shared/Response.model';
import {InvoiceStats} from '../../main/invoice/models/invoice.stats';

@Injectable()
export class InvoiceApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'invoice';

  findAll(
    pageQuery: PageQuery,
    sortQuery: SortQuery,
    filter: TicketFilter
  ): Observable<Page<InvoiceList>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter
    };

    return this.requestService
      .get(`${this.apiEndpoint}`, {params})
      .pipe(
        map(response => response as Page<InvoiceList>),
        catchError(this.handleError)
      );
  }

  getPartnerInvoices(startDate: string | Date, endDate: string | Date, partnerId: number): Observable<UserInvoices> {
    const url = `${this.apiEndpoint}/partners/${partnerId}`;
    return this.requestService
      .post(url, {
        startDate,
        endDate,
      })
      .pipe(
        map(response => response.response as UserInvoices),
        catchError(this.handleError)
      );
  }

  getCustomerInvoice(startDate: string | Date, endDate: string | Date, customerId: number): Observable<UserInvoices> {
    const url = `${this.apiEndpoint}/customer/${customerId}`;
    return this.requestService
      .post(url, {
        startDate,
        endDate,
      })
      .pipe(
        map(response => response.response as UserInvoices),
        catchError(this.handleError)
      );
  }


  getStats(startDate: string | Date, endDate: string | Date, partnerId: number): Observable<EntityResponseModel<InvoiceStats>> {
    const url = `${this.apiEndpoint}/stats`;
    return this.requestService.post(url, {
      startDate,
      partnerId,
      endDate,
    }).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  invoice(data: number[]): Observable<any> {
    const url = `${this.apiEndpoint}`;
    const body = {
      tickets: data,
    };
    return this.requestService.post(url, body).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  changeStatus(id: number, invoiceType: string): Observable<any> {
    const url = `${this.apiEndpoint}/${id}/changeStatus`;
    const body = {
      invoiceStatus: invoiceType
    };
    return this.requestService.patch(url, body).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  voidStatus(invoiceId: number, reason: string) {
    const url = `${this.apiEndpoint}/${invoiceId}/void`;
    return this.requestService.patch(url, {
      reason
    }).pipe(
      map(response => response),
      catchError(this.handleError));
  }

  prepaidStatus(invoiceId: number, reason: string) {
    const url = `${this.apiEndpoint}/${invoiceId}/prepaid`;
    return this.requestService.patch(url, {
      reason
    }).pipe(
      map(response => response),
      catchError(this.handleError));
  }

  getInvoice(invoiceId: number): Observable<Invoice> {
    const url = `${this.apiEndpoint}/${invoiceId}`;
    return this.requestService.get(url).pipe(
      map((response) => plainToClass(Invoice, response.response) as Invoice),
      catchError(this.handleError));
  }

  markAsPrinted(invoiceId: number) {
    const url = `${this.apiEndpoint}/${invoiceId}/print`;
    return this.requestService.put(url, {});
  }

  download(invoices: number[]): Observable<any> {
    const url = `${this.apiEndpoint}/download`;
    const body = {
      ids: invoices,
    };
    return this.requestService.download(url, body).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  downloadStatement(invoices: number[]): Observable<any> {
    const url = `${this.apiEndpoint}/statement/download`;
    return this.requestService.post(url,
      {
        ids: invoices,
      },
      {
        responseType: 'blob'
      }).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  resend(invoices: number[]): Observable<any> {
    const url = `${this.apiEndpoint}/sendEmail`;
    const body = {
      ids: invoices,
    };
    return this.requestService.post(url, body).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  sendEmail(invoiceId: number): Observable<any> {
    return this.resend([invoiceId]);
  }

  sendToPartner(invoices: number[]) {
    const url = `${this.apiEndpoint}/statement/send/partner`;
    const body = {
      ids: invoices,
    };
    return this.requestService.post(url, body).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  sendToCustomer(invoices: number[]) {
    const url = `${this.apiEndpoint}/statement/send/customer`;
    const body = {
      ids: invoices,
    };
    return this.requestService.post(url, body).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  sendToEmail(email: string, ids: number[]) {
    const url = `${this.apiEndpoint}/statement/send`;
    const body = {
      email,
      ids,
    };
    return this.requestService.post(url, body).pipe(
      map((response) => response),
      catchError(this.handleError));
  }
}
