<p-tabView [scrollable]="true" *ngIf="activeIndex !== undefined" (onChange)="onTabChange($event)"
           [(activeIndex)]="activeIndex" class="stats-tab-padding">
  <p-tabPanel *ngFor="let tab of tabs let i = index">
    <ng-template pTemplate="header">
      <span class="p-tabview-title pb-2">
        {{ tab.label }}
        <p-badge value="{{tab.count}}" [styleClass]="tab.count > 0 ? tab.code.toLowerCase() : 'all'"></p-badge>
      </span>
    </ng-template>
  </p-tabPanel>
</p-tabView>
