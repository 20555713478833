import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Partner} from '../../../../api/model/Partner.model';
import {UntypedFormGroup} from '@angular/forms';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, NotificationService} from '../../../../core/services';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Messages} from '../../../../common/messages';

@Component({
  selector: 'tj-ticket-edit-partner-modal',
  templateUrl: './ticket-edit-partner-modal.component.html',
  styleUrls: ['./ticket-edit-partner-modal.component.scss']
})
export class TicketEditPartnerModalComponent implements OnInit {

  private _visible;

  @Input('visible') set visible(value) {
    this._visible = value;
  }

  get visible() {
    return this._visible;
  }

  @Output() hide = new EventEmitter();

  originalPartner: Partner;

  originalTicketPartnerStatus: TicketPartnerStatus;

  originalMainContactPerson: ContactPerson;

  partner: Partner;

  ticketPartnerStatus: TicketPartnerStatus;

  ticketPartnerStatusEnum = TicketPartnerStatus;

  mainContactPerson: ContactPerson;

  form: UntypedFormGroup;

  subscriptions = [];

  constructor(private api: ApiService,
              private notificationService: NotificationService,
              private configService: ConfigService,
              private context: TicketEditContextService) {
  }

  ngOnInit() {

  }

  onShow() {
    this.form = new UntypedFormGroup({});
    this.subscriptions.push(this.context.partner.subscribe(partner => {
      if (partner) {
        this.originalPartner = partner;
        this.partner = Partner.buildCopy(partner);
      } else {
        this.partner = null;
      }
    }));
    this.subscriptions.push(this.context.ticketPartnerStatus.subscribe(ticketPartnerStatus => {
      this.originalTicketPartnerStatus = ticketPartnerStatus;
      this.ticketPartnerStatus = ticketPartnerStatus;
    }));

    this.subscriptions.push(this.context.mainPartnerContactPerson.subscribe(contactPerson => {
      this.originalMainContactPerson = contactPerson;
      this.mainContactPerson = contactPerson;
    }));
  }

  onHide() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
    this.form = undefined;
    this.hide.emit();
  }

  onSelectPartner(partnerId: number) {
    if (confirm('Are you sure you want to change partner?')) {
      this.changePartner(partnerId);
    }
  }

  onUsePrivatePartner() {
    const receiveNotifications = this.configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT');
    this.partner = Partner.buildMinimalPartner(receiveNotifications);
    this.ticketPartnerStatus = TicketPartnerStatus.PRIVATE_BUSINESS;
  }

  onChangePartner() {
    this.context.detachPartner().subscribe(() => {
    }, (error) => {
      this.notificationService.error(Messages.TICKET_PARTNER_DETACHED_FAILED, error);
    });
  }

  onContactPersonChange(contactPerson) {
    this.changeContactPerson(contactPerson);
  }

  private changeContactPerson(contactPerson) {
    this.mainContactPerson = contactPerson;
  }

  onDetachPartner() {
    this.context.detachPartner().subscribe(() => {
      this.notificationService.success(Messages.TICKET_PARTNER_DETACHED);
      this.onHide();
    }, (error) => {
      this.notificationService.error(Messages.TICKET_PARTNER_DETACHED_FAILED, error);
    });
  }

  onSave() {
    if (this.form.valid) {
      const formValues = this.form.value;
      if (this.ticketPartnerStatus === TicketPartnerStatus.REGISTERED) {
        this.saveRegisteredPartner(formValues);
      } else if (this.ticketPartnerStatus === TicketPartnerStatus.PRIVATE_BUSINESS) {
        this.savePrivatePartner(formValues);
      } else {
        // no partner, private ticket
      }
      this.onHide();
      this.context.partner$.next({...this.context.partner$.getValue(), ...formValues.partner});

      this.context.mainPartnerContactPerson$.next({...this.context.mainPartnerContactPerson$.getValue(), ...formValues.mainPartnerContactPerson});
    } else {
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  private saveRegisteredPartner(formValues) {
    const partner = this.partner;
    const mainContactPerson: ContactPerson = formValues.mainPartnerContactPerson;

    if (mainContactPerson.id === null && mainContactPerson.isMain === true) {
      // backend needs it in the array of contact persons
      partner.contactPersons.push(mainContactPerson);
    }


    this.context.savePartner(partner).subscribe(() => {
      this.successNotification();
    }, error => {
      this.notificationService.error(error);
    });

    this.context.updateTicketPartnerStatus(TicketPartnerStatus.REGISTERED);
    this.context.updateTicketPartnerMainContactPerson(partner.getMainContactPerson());
  }

  private savePrivatePartner(formValues) {
    const partner = Partner.buildBasedOnForm(formValues.partner);
    this.context.savePartner(partner).subscribe(() => {
      this.successNotification();
    });

    this.context.updateTicketPartnerStatus(TicketPartnerStatus.PRIVATE_BUSINESS);
    this.context.updateTicketPartnerMainContactPerson(partner.getMainContactPerson());
  }

  private successNotification() {
    this.notificationService.success(Messages.PARTNER_SAVED);
  }

  private changePartner(partnerId: number) {
    this.api.partner.findOne(partnerId)
      .subscribe(
        partner => {
          this.partner = partner;
          // take first contact person as default
          this.changeContactPerson(partner.contactPersons[0] || null);
          this.ticketPartnerStatus = TicketPartnerStatus.REGISTERED;
        },
        error => this.notificationService.error(error)
      );
  }

}
