<ul class="contact-persons" *ngIf="contactPerson">
  <li>
    <span class="contact-person">{{contactPerson.name}}</span>
    <ul class="list-none mt-1 pl-3" *ngIf="contactPerson.contacts && contactPerson.contacts.length">
      <ng-container *ngFor="let contact of contactPerson.contacts">
        <li class="flex align-items-baseline">
          <span *ngIf="contact.type === contactType.EMAIL" class="label pi pi-envelope"></span>
          <span *ngIf="contact.type === contactType.MOBILE_PHONE" class="label pi pi-mobile"></span>
          <span *ngIf="contact.type === contactType.PHONE" class="label pi pi-mobile"></span>
          <span *ngIf="contact.type === contactType.FAX" class="label pi pi-print"></span>
          <ng-container *ngIf="contact.type === contactType.MOBILE_PHONE || contact.type === contactType.PHONE">
            <a [href]="'tel:' + contact.value" class="value">{{ contact.value }}</a>
          </ng-container>
          <ng-container *ngIf="contact.type === contactType.FAX">
            <span class="value">{{contact.value}}</span>
          </ng-container>
          <ng-container *ngIf="contact.type === contactType.EMAIL">
            <a [href]="'mailto:' + contact.value">{{ contact.value }}</a>
          </ng-container>
          <button pButton
                  pRipple
                  type="button"
                  icon="pi pi-copy"
                  class="p-button-rounded p-button-text"
                  [cdkCopyToClipboard]="contact?.value"
                  (cdkCopyToClipboardCopied)="onCopySuccess($event, contact.type)"></button>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
