import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ListSearchService} from '../../shared/services/list-search.service';
import {PayFormSearchFilter} from '../models/pay-form-search.filter';

@Injectable()
export class PayFormSearchService extends ListSearchService<PayFormSearchFilter> {

  constructor(route: ActivatedRoute,
              router: Router) {
    super(route, router);
  }

  searchByFormValue(formValue) {
    const filter: PayFormSearchFilter = {
      technicianId: formValue.technicianId,
      technicianCompanyName: formValue.technicianCompanyName,
      technicianPersonnelCode: formValue.technicianPersonnelCode,
      status: formValue.status,
      startDate: formValue.startDate,
      endDate: formValue.endDate,
      ticketId: formValue.ticketId,
    } as PayFormSearchFilter;
    this.search(filter);
  }

  getSearchUrl() {
    return ['/payforms'];
  }

  getSearchConfig() {
    return {
      technicianId: {
        searchQueryPrefix: 'technician.id~'
      },
      technicianCompanyName: {
        searchQueryPrefix: 'technician.companyName~'
      },
      technicianPersonnelCode: {
        searchQueryPrefix: 'technician.personnelCode~'
      },
      startDate: {
        searchQueryPrefix: 'startDate:'
      },
      endDate: {
        searchQueryPrefix: 'endDate:'
      },
      ticketId: {
        searchQueryPrefix: 'payFormItems.payFormItemTickets.ticket.id:'
      },
      status: {
        searchQueryPrefix: 'status:'
      },
    };
  }
}
