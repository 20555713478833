<form [formGroup]="actionForm" (ngSubmit)="submit()">
  <div class="grid align-items-center">
    <div class="lg:col-12 sm:col-12">
      <tj-field [control]="getControl('details')">
        <textarea pInputTextarea formControlName="details" rows="10" cols="50"></textarea>
      </tj-field>
    </div>
    <div class="lg:col-12 sm:col-12 text-right">
      <button pButton type="submit" label="Save"></button>
    </div>
  </div>
</form>
