import {Pipe, PipeTransform} from '@angular/core';
import {transformDuration} from "../../../api/model/shared/functions";

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number): string {
    return transformDuration(value);
  }
}
