<div class="grid " *ngIf="page">
  <div class="col-12 card">
    <p-panel header="Filters">
      <tj-ticket-list-filter [ticketFilter]="ticketFilter" [page]="page"></tj-ticket-list-filter>
    </p-panel>
    <p-toolbar styleClass="my-4">
      <ng-template pTemplate="left">
        <p-dropdown [showClear]="true" (onChange)="setBatchAction($event)" [options]="batchActions"
                    placeholder="Select an action"></p-dropdown>
      </ng-template>

      <ng-template pTemplate="right">
        <button pButton pRipple label="Create new ticket" icon="pi pi-plus" class="p-button-success mr-2 mb-2"
                [routerLink]="['/tickets', 'create']"></button>
      </ng-template>
    </p-toolbar>

    <ng-container *tjHasRole="['TICKET_VIEW_MAIN_STATS_COMPLETED', 'TICKET_VIEW_MAIN_STATS_OVERDUE']">
      <tj-tickets-main-stats class="w-full"></tj-tickets-main-stats>
    </ng-container>
    <tj-ticket-list #ticketListComponent
      [tickets]="tickets"
      (onSort)="sort($event)"></tj-ticket-list>

    <p-paginator [rows]="pageQuery.size" [totalRecords]="page.totalElements"
                 [rowsPerPageOptions]="env.extendedRowsPerPage"
                 (onPageChange)="onGoToPage($event)"
                 [first]="pageQuery.number * pageQuery.size"></p-paginator>
  </div>
</div>
