import {Component, Input, OnInit} from '@angular/core';
import {Page} from '../../../../api/model/shared/Page.model';
import {UntypedFormGroup} from '@angular/forms';
import {NotificationService} from '../../../../core/services';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {PayFormSearchService} from '../../services/pay-form-search.service';
import {PayFormSearchFilter} from '../../models/pay-form-search.filter';

@Component({
  selector: 'tj-pay-form-search',
  templateUrl: './pay-form-search.component.html',
  styleUrls: ['./pay-form-search.component.scss']
})
export class PayFormSearchComponent implements OnInit {
  @Input() filter: PayFormSearchFilter;

  @Input() page: Page<any>;

  filterForm: UntypedFormGroup;

  constructor(private notificationService: NotificationService,
              private payFormSearchService: PayFormSearchService) {
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
  }

  onSearchReset(): void {
    this.filterForm.reset();
    this.payFormSearchService.reset();
  }

  onFilter() {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.payFormSearchService.searchByFormValue(formValue.payFormFilter);
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }

  onRemoveFilter(filter): void {
    this.payFormSearchService.search(filter);
  }
}
