<div class="grid" [formGroup]="currentFormGroup">
  <div class="col">
    <div class="grid">
      <div class="col-12">
        <tj-field label="Customer name" [control]="fcName" class="auto cell">
          <input pInputText type="text" placeholder="Customer name *" id="customerName" formControlName="name">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <tj-subtitle>Address</tj-subtitle>
        <tj-address-create-form [form]="currentFormGroup" [address]="customer.address" group="address"
                                (zipCode)="changeZipCode($event)">
          <tj-zip-code-covered-info [zipCode]="customerAddressZipCode" [initialShow]="false"
                                    *ngIf="configService.isEnabledTerritory()"></tj-zip-code-covered-info>
        </tj-address-create-form>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <tj-subtitle>Contact persons</tj-subtitle>
        <tj-contact-persons-form
          [canChangeReceivers]="false"
          [nameObs]="nameObs"
          [form]="currentFormGroup"
          [contactPersons]="customer.contactPersons"
        ></tj-contact-persons-form>
      </div>
    </div>
  </div>
</div>
