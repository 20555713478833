export enum TicketStatus {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  VERIFIED = 'VERIFIED',
  RESCHEDULE = 'RESCHEDULE',
  CUSTOMER_WILL_CALL = 'CUSTOMER_WILL_CALL',
  INVOICED = 'INVOICED',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
}
