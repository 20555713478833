import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {TicketCreateComponent, TicketEditComponent, TicketListComponent, TicketsComponent,} from './pages';
import {RouterModule} from '@angular/router';
import {
  AddressCreateFormComponent,
  AddressEditFormComponent,
  ContactPersonSelectorFormComponent,
  ContactPersonsFormComponent,
  CustomerCreateFormComponent,
  CustomerEditFormComponent,
  FinalReportFormComponent,
  IssueFormComponent,
  PrivatePartnerFormComponent,
  QuickInvoiceFormComponent,
  SingleContactPersonFormComponent,
  TicketFormComponent,
  TicketListFilterFormComponent,
  TjTimeComponent,
} from './forms';
import {
  CustomerDetailsComponent,
  PartnerContactPersonSelectorComponent,
  PartnerTypeSelectorComponent, RelatedTicketsComponent,
  TicketCreatePartnerComponent,
  TicketDetailsComponent,
  TicketEditCustomerModalComponent,
  TicketEditPartnerModalComponent, TicketEditServiceTimeModalComponent,
  TicketListFilterComponent,
  TicketPartnerStatusComponent,
  TicketSearchLabelsComponent,
  TicketsMainStatsComponent,
  TicketTagsComponent,
} from './components';
import { SharedModule } from '../shared/shared.module';
import { AuthGuardService } from '../../core/services';
import { InvoiceModule } from '../invoice/invoice.module';
import { UploaderModule } from '../uploader/uploader.module';
import { TicketCommunicationModule } from '../ticket-communication/ticket-communication.module';
import { TicketPrintComponent } from './pages/ticket-print/ticket-print.component';
import { TicketsPrintComponent } from './pages/tickets-print/tickets-print.component';
import {PendingChangesGuard} from '../../core/guards/pending-change.guard';
import {TicketTechnicianPrintComponent} from './components/ticket-technicians/print/technician-print/ticket-technician-print.component';
import {ConfigurationsModule} from '../configurations/configurations.module';
import {BadgeModule} from 'primeng/badge';
import {AppointmentFormComponent} from './forms/appointment-form/appointment-form.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    TicketCommunicationModule,
    InvoiceModule,
    UploaderModule,
    RouterModule.forChild([
      {
        path: '',
        component: TicketsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['TICKET_VIEW'],
          breadcrumb: 'Tickets'
        },
      },
      {
        path: 'tickets/create',
        component: TicketCreateComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['TICKET_EDIT'],
          breadcrumb: 'New Ticket'
        },
      },
      {
        path: 'tickets/print',
        component: TicketsPrintComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['TICKET_EDIT'],
          breadcrumb: 'Ticket',
          standalonePage: true
        },
      },
      {
        path: 'tickets/:id',
        component: TicketEditComponent,
        canActivate: [AuthGuardService],
        canDeactivate: [PendingChangesGuard],
        data: {
          roles: ['TICKET_EDIT']
        },
      },
      {
        path: 'tickets',
        component: TicketsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['TICKET_VIEW'],
          breadcrumb: 'Tickets'
        },
      },
    ]),
    ConfigurationsModule,
    BadgeModule,
  ],
  declarations: [
    TicketListComponent,
    TicketCreateComponent,
    TicketEditComponent,
    TicketPrintComponent,
    TicketsPrintComponent,
    CustomerCreateFormComponent,
    CustomerEditFormComponent,
    PrivatePartnerFormComponent,
    AddressCreateFormComponent,
    AddressEditFormComponent,
    PartnerContactPersonSelectorComponent,
    PartnerTypeSelectorComponent,
    IssueFormComponent,
    FinalReportFormComponent,
    TjTimeComponent,
    TicketsMainStatsComponent,
    TicketFormComponent,
    ContactPersonsFormComponent,
    TicketDetailsComponent,
    CustomerDetailsComponent,
    TicketListFilterFormComponent,
    TicketListFilterComponent,
    TicketsComponent,
    QuickInvoiceFormComponent,
    TicketSearchLabelsComponent,
    TicketTagsComponent,
    SingleContactPersonFormComponent,
    TicketCreatePartnerComponent,
    ContactPersonSelectorFormComponent,
    TicketPartnerStatusComponent,
    TicketEditPartnerModalComponent,
    RelatedTicketsComponent,
    TicketEditCustomerModalComponent,
    TicketEditServiceTimeModalComponent,
    TicketTechnicianPrintComponent,
    AppointmentFormComponent,
  ],
  exports: [
    PrivatePartnerFormComponent,
    AddressCreateFormComponent,
    AddressEditFormComponent,
    ContactPersonsFormComponent,
    TicketTechnicianPrintComponent,
    TicketsMainStatsComponent,
    TicketEditServiceTimeModalComponent,
    TjTimeComponent,
  ],
  providers: [],
})
export class TicketModule {}
