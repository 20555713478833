import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class TimeZoneHeaderAppenderInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqCopy = httpRequest.clone({
      headers: httpRequest.headers.append('Time-Zone', -(new Date().getTimezoneOffset() / 60) + '')
    });
    return next.handle(reqCopy);
  }
}
