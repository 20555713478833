import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ListSearchService} from '../../shared/services/list-search.service';
import {RouteFilter} from '../../../api/model/route/route.filter';

@Injectable({
  providedIn: 'root',
})
export class RouteSearchService extends ListSearchService<RouteFilter> {

  constructor(route: ActivatedRoute, router: Router) {
    super(route, router);
  }

  searchByFormValue(formValue) {
    const filter: RouteFilter = {
      id: formValue.id,
      date: formValue.date,
      ticketId: formValue.ticketId,
      technicianId: formValue.technicianId,
    };

    this.search(filter);
  }

  getSearchUrl() {
    return ['/routes'];
  }

  getSearchConfig() {
    return {
      id: {
        searchQueryPrefix: 'id:'
      },
      date: {
        searchQueryPrefix: 'date:'
      },
      technicianId: {
        searchQueryPrefix: 'technician.id:'
      },
      ticketId: {
        searchQueryPrefix: 'stops.ticket.id:'
      },
    };
  }
}
