import {Directive, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {AddressDetails} from '../../../api/model/Address.model';

declare var google: any;

@Directive({
  selector: '[appGooglePlacesAutocomplete]'
})
export class GooglePlacesAutocompleteDirective implements OnInit {
  @Output() addressSelected = new EventEmitter<{ address: AddressDetails, valid: boolean }>();

  autocomplete: any;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
      types: ['address'], // Restrict the search to geographical location types
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (place.geometry) {
        const addressDetails = this.formatAddressDetails(place);
        this.addressSelected.emit({address: addressDetails, valid: true});
      } else {
        this.addressSelected.emit({address: null, valid: false});
      }
    });
  }

  // Helper function to extract relevant address information
  formatAddressDetails(place: any): AddressDetails {
    const addressDetails = new AddressDetails();
    addressDetails.addressFormatted = place.formatted_address;
    addressDetails.lat = place.geometry.location.lat();
    addressDetails.lng = place.geometry.location.lng();

    // Extract more address components (country, city, postal code, etc.)
    place.address_components.forEach(component => {
      const types = component.types;
      if (types.includes('country')) {
        addressDetails.country = component.long_name;
      } else if (types.includes('locality')) {
        addressDetails.city = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressDetails.state = component.short_name;
      } else if (types.includes('route')) {
        addressDetails.street = component.long_name;
      } else if (types.includes('street_number')) {
        addressDetails.bld = component.long_name;
      } else if (types.includes('postal_code')) {
        addressDetails.postalCode = component.long_name;
      }
    });

    return addressDetails;
  }
}
