<div *ngIf="page" class="grid flex-column">
      <p-toolbar *tjHasRole="'PAYFORM_EDIT'">
        <div class="p-toolbar-group-left"></div>
        <div class="p-toolbar-group-right flex-wrap">
          <button pButton pRipple (click)="onCreateNewPayForm()" icon="pi pi-plus" class="mb-2 p-button-success"
                  label="New pay form"></button>
        </div>
      </p-toolbar>
  <div class="col-12 no-print">
    <p-panel [toggleable]="true" header="Filters">
      <tj-pay-form-search [filter]="searchFilter"></tj-pay-form-search>
    </p-panel>
  </div>
  <div *ngIf="page" class="col-12 no-print">
    <div class="grid"> 
      <div class="col-12">
        <p-table
          (onRowSelect)="onTableRowSelect($event)"
          (onSort)="sort($event)"
          [autoLayout]="true"
          [selectionMode]="'single'"
          [value]="payForms"
          [customSort]="true"
          [responsive]="true"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="technician">
                Technician
                <p-sortIcon field="technician"></p-sortIcon>
              </th>
              <th pSortableColumn="technicianPersonnelCode">
                Technician Code
                <p-sortIcon field="technicianPersonnelCode"></p-sortIcon>
              </th>
              <th pSortableColumn="technicianCompanyName">
                Company Name
                <p-sortIcon field="technicianCompanyName"></p-sortIcon>
              </th>
              <th pSortableColumn="startDate">
                Start Date
                <p-sortIcon field="startDate"></p-sortIcon>
              </th>
              <th pSortableColumn="endDate">
                End Date
                <p-sortIcon field="endDate"></p-sortIcon>
              </th>
              <th>Pay Term</th>
              <th pSortableColumn="status">
                Status
                <p-sortIcon field="status"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-payForm>
            <tr [pSelectableRow]="payForm">
              <td class="pl-3">
                {{ payForm.technicianName }}
              </td>
              <td>
                {{payForm.technicianPersonnelCode}}
              </td>
              <td>
                {{payForm.technicianCompanyName}}
              </td>
              <td class="pl-3">
                {{ payForm.startDate | date : configService.dateFormat }}
              </td>
              <td class="pl-3">
                {{ payForm.endDate | date : configService.dateFormat }}
              </td>
              <td class="pl-3">
                <span *ngIf="payForm.payType === 'COMMISSION_BASED'">{{payForm.percent}}%</span>
                <span *ngIf="payForm.payType === 'HOUR_BASED'">{{ payForm.unit | currency: 'USD' }}</span>
              </td>
              <td class="pl-3">{{ payForm.status }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12">
        <p-paginator
          (onPageChange)="onGoToPage($event)"
          [rows]="env.rowsPerPage[0]"
          [rowsPerPageOptions]="env.rowsPerPage"
          [first]="pageQuery.number * pageQuery.size"
          [totalRecords]="page.totalElements"
        ></p-paginator>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog appendTo="body" key="confirmDeletion"></p-confirmDialog>
