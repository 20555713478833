import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {PartnerLine} from '../../../../api/model/PartnerLine.model';
import {Page, PageQuery, SortQuery} from '../../../../api/model/shared/Page.model';
import {ConfigService, NotificationService} from '../../../../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {Partner} from '../../../../api/model/Partner.model';
import {catchError, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PartnerSearchService} from '../../services/partner-search.service';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Messages} from '../../../../common/messages';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tj-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  providers: [PartnerSearchService]
})
export class PartnersComponent implements OnInit {

  protected readonly env = environment;

  page: Page<PartnerLine>;

  partners: PartnerLine[];

  selectedPartner: Partner;

  private pageQuery: PageQuery = {
    size: this.env.rowsPerPage[0],
    number: 0
  };

  private sortQuery: SortQuery = {
    property: 'name',
    direction: 'asc'
  };

  searchFilter;

  searchQuery;

  constructor(private api: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private configService: ConfigService,
              private partnerSearchService: PartnerSearchService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.partnerSearchService.listenQueryParams();
    this.partnerSearchService.searchData.subscribe(({filters, searchQuery}) => {
      this.searchFilter = filters;
      this.searchQuery = searchQuery;
    });

    this.route.queryParams.subscribe(params => {
      this.pageQuery.number = params.page || 0;
      this.pageQuery.size = params.size || this.env.rowsPerPage[0];
      this.loadPartners();
    });
  }

  onGoToPage(event) {
    this.router.navigate([], {queryParams: {page: event.page, size: event.rows}, queryParamsHandling: 'merge'});
  }

  onCreateNewPartner() {
    const receiveNotifications = this.configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT');
    this.selectPartner(Partner.buildMinimalPartner(receiveNotifications));
  }

  onSelectPartner(event) {
    if (!event) {
      this.selectedPartner = undefined;
      return;
    }
    // this.router.navigate(['partners', event.data.id]);
    this.loadPartner(event.data.id)
      .subscribe((partner) => {
        this.selectPartner(partner);
      });
  }

  sort($event) {
    this.sortQuery = {
      property: $event.field,
      direction: $event.order === -1 ? 'desc' : 'asc',
    };
    this.router.navigate([], {queryParams: this.sortQuery, queryParamsHandling: 'merge'});
  }

  private selectPartner(partner: Partner) {
    this.selectedPartner = partner;
  }

  private loadPartner(id): Observable<Partner> {
    return this.api.partner.findOne(id).pipe(
      tap((partner: Partner) => {
        return partner;
      }),
      catchError((err) => {
        this.notificationService.error(Messages.PARTNER_GET);
        return throwError(err);
      })
    );
  }

  private loadPartners() {
    this.api.partner.findAll(this.pageQuery, this.sortQuery, this.searchQuery).subscribe(
      (partnersPage: Page<PartnerLine>) => {
        this.page = partnersPage;
        this.partners = partnersPage.response;
      }, (err) => {
        this.notificationService.error(Messages.PARTNER_GET_ALL);
      }
    );
  }

  onSave(partner: Partner): void {
    this.loadPartners();
    this.configService.updatePartnersList(partner);
  }

}
