import {IdRequestModel} from '../../../api/model/IdRequest.model';

export abstract class AttachmentModel extends IdRequestModel {
  public url: string;
  public fileName: string;
  public originalFileName: string;
  public extension: string;
  public selected: boolean;
  public index: number;
  public createdAt?: string | Date;
  public updatedAt?: string | Date;
  public createdBy?: string;
  public modifiedBy?: string;
}

export class ImageRequestModel extends AttachmentModel {
  public cropPath: string;
  public version?: number;

  constructor(public id: number) {
    super(id);
  }
}

export class VideoRequestModel extends AttachmentModel {
  constructor(id: number) {
    super(id);
  }
}

export class DocumentRequestModel extends AttachmentModel {
  constructor(id: number) {
    super(id);
  }
}

export class AudioRequestModel extends AttachmentModel {
  constructor(id: number) {
    super(id);
  }
}
