
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ModalService {

  private modalSource = new Subject<any>();

  modals$ = this.modalSource.asObservable();

  constructor() { }

  open(component, dataContext) {
    this.modalSource.next({component, dataContext});
  }
}
