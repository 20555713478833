import {Component, Input} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {TicketStatus} from '../../../../api/status/ticketStatus';

@Component({
  selector: 'tj-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.scss']
})
export class TicketStatusComponent {

  @Input() status: TicketStatus;

  readonly TicketStatus = TicketStatus;

  constructor(public config: ConfigService) {
  }

  getTooltip() {
    switch (this.status) {
      case TicketStatus.VERIFIED:
        return 'Finalized / Ready to invoice';
      default:
        return '';
    }
  }
}
