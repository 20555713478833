import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {TicketChatsComponent} from './components/ticket-chats/ticket-chats.component';
import {TicketCommentsComponent} from './components/ticket-comments/ticket-comments.component';
import {CommentFormComponent} from './components/comment-form/comment-form.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    TicketChatsComponent,
    TicketCommentsComponent,
    CommentFormComponent,

  ],
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [
    TicketChatsComponent,
  ]
})
export class TicketCommunicationModule { }
