<nav class="navbar fixed-top p-toolbar navbar-light bg-light hide-print">
  <button
    class="mr-2 p-button-info"
    label="Back"
    type="button"
    icon="pi pi-arrow-circle-left"
    pButton
    (click)="back()"
    pRipple>
  </button>
</nav>
<ng-container *ngFor="let id of ids; index as i;">
  <tj-ticket-print
    [ticketId]="id"
    [index]="i"
    [configs]="configs"
    [showTotalAmount]="showTotalAmount"
    [pagebreak]="!(i === ids.length-1)"
  ></tj-ticket-print>
</ng-container>
