import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {Ticket} from '../../../../api/model/Ticket.model';
import {TicketLine} from '../../../../api/model/TicketLine.model';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-ticket-related',
  templateUrl: './related-tickets.component.html',
  styleUrls: ['./related-tickets.component.scss']
})
export class RelatedTicketsComponent implements OnInit {

  @Input() ticket: Ticket;

  tickets: TicketLine[] = [];

  constructor(private api: ApiService,
              public configService: ConfigService) {
  }

  ngOnInit() {
    this.handleChange(0);
  }

  handleChange(index: number) {
    let relatedBy = '';
    switch (index) {
      case 0:
        relatedBy = 'customerName';
        break;
      case 1:
        relatedBy = 'address';
        break;
      case 2:
        relatedBy = 'customerContacts';
        break;
      case 3:
        if (this.ticket.externalReferenceCode) {
          relatedBy = 'workOrder';
        }
        break;
    }
    if (!relatedBy) {
      return;
    }
    this.api.ticket.related(this.ticket.id, relatedBy)
      .subscribe(value => {
        return this.tickets = value;
      });
  }

}
