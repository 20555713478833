import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AreaToZipCodeFilter} from '../../../../../../api/model/areatozipcode/areaToZipCode.filter';
import {AreaToZipCodeSearchService} from '../../services/area-to-zip-code-search.service';
import {Page} from '../../../../../../api/model/shared/Page.model';
import {FormHelpers} from '../../../../../shared/utils/form-helpers';

@Component({
  selector: 'tj-area-to-zip-code-list-filter',
  templateUrl: './area-to-zip-code-list-filter.component.html',
  styleUrls: ['./area-to-zip-code-list-filter.component.scss']
})
export class AreaToZipCodeListFilterComponent implements OnInit {

  @Input() areaToZipCodeFilter: AreaToZipCodeFilter;

  @Input() page: Page<any>;

  filterForm: UntypedFormGroup;

  constructor(private areaToZipCodeSearchService: AreaToZipCodeSearchService) {
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
  }

  onSearchReset(): void {
    this.filterForm.reset();
    this.areaToZipCodeSearchService.reset();
  }

  onRemoveFilter(routeFilter): void {
    this.areaToZipCodeSearchService.search(routeFilter);
  }

  onFilterRoutes(): void {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.areaToZipCodeSearchService.searchByFormValue(formValue.routeFilter);
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }
}
