import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfigService, NotificationService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {Messages} from '../../../../common/messages';
import {Tag} from '../../../../api/model/Tag.model';
import {ConfirmationService} from 'primeng/api';
import {Form} from '@angular/forms';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tj-tag-configurations-component',
  templateUrl: './tag-configurations.component.html',
  styleUrls: ['./tag-configurations.component.scss']
})

export class TagConfigurationsComponent implements OnInit, OnDestroy {

  protected readonly env = environment;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  tagForm: Form;

  tagDialog: boolean;

  tags: Tag[];

  tag: Tag;

  constructor(private api: ApiService,
              private configService: ConfigService,
              private confirmationService: ConfirmationService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.api.tag.findList()
      .subscribe((tags) => {
          this.tags = tags;
        },
        errorMessage => {
          this.notificationService.error(errorMessage);
        });
  }

  onEditTag(tag: Tag) {
    this.tag = {...tag};
    this.tagDialog = true;
  }

  onHideDialog() {
    this.tagDialog = false;
  }

  onDeleteTag(tagToRemove: Tag) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the tag?',
      header: 'Tag deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletion',
      accept: () => {
        this.tag = {...tagToRemove};
        this.api.tag.delete(tagToRemove.id)
          .subscribe(() => {
            const savedTagIndex = this.tags.findIndex(tag => tag.id === this.tag.id);
            if (savedTagIndex !== -1) {
              this.tags.splice(savedTagIndex, 1);
            }
            this.configService.reloadTagsList();
          });
      }
    });
  }

  onNewTag() {
    this.tag = {label: '', color: '#3b82f6', builtin: false};
    this.tagDialog = true;
  }

  onSaveTag() {
    if (this.tag.id) {
      const savedTagIndex = this.tags.findIndex(tag => tag.id === this.tag.id);
      if (savedTagIndex !== -1) {
        this.tags[savedTagIndex] = this.tag;
        this.api.tag.update(this.tag)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
              this.configService.reloadTagsList();
              this.notificationService.success(Messages.TAG_UPDATED);
            },
            errorMessage => {
              this.notificationService.error(errorMessage);
            });
      }
    } else {
      this.api.tag.create(this.tag)
        .pipe(takeUntil(this.destroy$))
        .subscribe((newTag) => {
            this.configService.reloadTagsList();
            this.tags.unshift({...this.tag, ...newTag});
            this.notificationService.success(Messages.TAG_CREATED);
          },
          errorMessage => {
            this.notificationService.error(errorMessage);
          });
    }

    this.tags = [...this.tags];
    this.tagDialog = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
