<p-toolbar styleClass="mb-4 gap-2">
  <ng-template pTemplate="left">
    <button pButton pRipple
            label="Export all zip codes"
            icon="pi pi-file-export"
            class="p-button-info mr-2"
            [disabled]="isSaving"
            (click)="onExportAllZipCodes()"></button>
    <p-fileUpload *tjHasRole="'ZIP_CODE_EDIT'"
                  #importZipcodes
                  (onSelect)="onImportZipCodes($event, importZipcodes)"
                  accept="text/csv,text/plain"
                  [auto]="true"
                  [mode]="'basic'"
                  [disabled]="isSaving"
                  maxFileSize="5242880"
                  chooseLabel="Import"
    >
    </p-fileUpload>
    <button *tjHasRole="'ZIP_CODE_EDIT'" pButton pRipple class="p-button-info mr-2 ml-2"
            (click)="onImportInfo()"><i class="pi pi-info-circle" style="font-size: 1.3rem"></i></button>
  </ng-template>
  <ng-template pTemplate="right">
    <button *tjHasRole="'ZIP_CODE_EDIT'" pButton pRipple label="Insert/Copy/Paste" icon="pi pi pi-pencil"
            class="p-button mr-2 ml-2"
            (click)="onNewInsertZipCode($event)"
            [disabled]="isSaving"></button>
    <button *tjHasRole="'ZIP_CODE_EDIT'" [disabled]="isSaving" pButton pRipple label="New" icon="pi pi-plus"
            class="p-button-success mr-2" (click)="onNewZipCode()"></button>
  </ng-template>
</p-toolbar>
