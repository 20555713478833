import {Component, Input} from '@angular/core';
import {RevisionModel} from '../../../../api/model/revision/revision.model';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss']
})
export class RevisionComponent {

  @Input() revision: RevisionModel;

  constructor(public configService: ConfigService) {
  }

}
