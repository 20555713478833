import {Customer} from './Customer.model';
import {Partner} from './Partner.model';
import {TicketPartnerStatus} from '../status/ticket-partner.status';
import {ContactPerson} from './ContactPerson.model';
import {Expose, plainToClassFromExist, Type} from 'class-transformer';
import {TicketTechnician} from './TicketTechnicians.model';
import {TicketStatus} from '../status/ticketStatus';
import {Tag} from './Tag.model';
import {AddressDetails} from './Address.model';
import {RevisionModel} from './revision/revision.model';
import {GalleryItemsCounts} from './GalleryItemsCounts.model';
import {RelatedTicketCounts} from './RelatedTicketCounts.model';
import {TicketRouteDetailsModel} from './ticket/ticket.route.details.model';

export class Ticket {
  id: number;

  @Type(() => Partner)
  partner: Partner = null;

  status: string | TicketStatus;

  externalReferenceCode: string;

  @Type(() => Customer)
  customer: Customer = Customer.buildMinimalCustomer();

  deliveryDate: Date = null;

  @Type(() => ContactPerson)
  mainPartnerContactPerson: ContactPerson;

  @Type(() => ContactPerson)
  mainCustomerContactPerson: ContactPerson;

  warrantyTerms: string;

  additionalNotes: string;

  finalReport: string;

  ticketPartnerStatus: string;

  createdAt: number;

  updatedAt: Date;

  galleryId: number;
  invoiceId?: number;
  routes?: TicketRouteDetailsModel;
  invoiceCreatedAt?: Date;
  invoiceTotalAmount: number;
  estimateId?: number;
  estimateCreatedAt?: Date;
  estimateTotalAmount?: number;
  invoiceAmount?: number;
  invoiceExpenses?: number;
  invoiceNetTerm?: number;
  invoiceTax?: number;
  invoiceStatus?: string;
  timeNeeded?: number;
  additionalInfo?: string;
  appointmentDetails?: string;

  serviceDate?: Date;
  serviceTimeStart?: Date;

  serviceTimeEnd?: Date;
  address: AddressDetails;
  issue?: string;
  category: string;
  report?: string;

  internalInfo?: string;

  technicians?: TicketTechnician[] = [];

  hasAttachment: boolean;

  tags: Tag[];

  active: boolean;

  revision: RevisionModel;

  notifyCustomer: boolean;
  notifyRouteChange?: boolean;
  checkReceived: boolean;
  pickUpDelivery: boolean;
  recallGoingBack: boolean;
  galleryItemsCounts: GalleryItemsCounts;
  relatedTicketCounts: RelatedTicketCounts;

  get isSentToPayForm() {
    return this.technicians.some(tech => tech.payFormId !== null);
  }

  static buildBasedOnForm(oldTicket, obj) {
    const ticket: Ticket = plainToClassFromExist(oldTicket, obj) as Ticket;
    ticket.mainCustomerContactPerson = ticket.mainCustomerContactPerson || ticket.customer.getMainContactPerson();
    if (ticket.partner) {
      let hasMainPartnerContactPersonOnContacts = false;
      if (!ticket.mainPartnerContactPerson) {
        ticket.mainPartnerContactPerson = ticket.partner.getMainContactPerson();
        hasMainPartnerContactPersonOnContacts = true;
      }
      // if adding new partner contact person when creating a ticket
      if (!hasMainPartnerContactPersonOnContacts && ticket.mainPartnerContactPerson.id === null && ticket.mainPartnerContactPerson.isMain === true) {
        // backend needs it in the array of contact persons
        ticket.partner.contactPersons.push(ticket.mainPartnerContactPerson);
      }
    }
    ticket.detectTicketPartnerStatus();
    return ticket;
  }

  @Expose()
  detectTicketPartnerStatus(): TicketPartnerStatus {
    if (this.partner && this.partner.id) {
      return TicketPartnerStatus.REGISTERED;
    } else if (this.partner && this.partner.name && !this.partner.id) {
      return TicketPartnerStatus.PRIVATE_BUSINESS;
    }
    return TicketPartnerStatus.PRIVATE;
  }

  @Expose()
  isPrivate(): boolean {
    return this.ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE] ||
      this.ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE_BUSINESS];
  }

  @Expose()
  getTotalAmount(): string {
    let totalAmount = 0;
    if (!this.technicians) {
      return totalAmount.toFixed(2);
    }
    this.technicians.forEach((technician: TicketTechnician) => {
      totalAmount += technician.bill + technician.check + technician.creditCard + technician.incomeCash;
    });
    return totalAmount.toFixed(2);
  }
}
