import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TechnicianSearchFilterModel} from '../../../models/technician-search-filter.model';

@Component({
  selector: 'tj-technician-search-form',
  templateUrl: './technician-search-form.component.html',
  styleUrls: ['./technician-search-form.component.scss']
})
export class TechnicianSearchFormComponent implements OnInit, OnChanges {

  @Input() filter: TechnicianSearchFilterModel;

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    const filter = this.filter || {} as TechnicianSearchFilterModel;
    this.form.addControl(this.group, this.fb.group({
      name: this.fb.control(filter.name || ''),
      personnelCode: this.fb.control(filter.personnelCode || ''),
      companyName: this.fb.control(filter.companyName || ''),
      phone: this.fb.control(filter.phone || ''),
      email: this.fb.control(filter.email || ''),
      deleted: this.fb.control(filter.deleted),
      visibleInsideTicket: this.fb.control(filter.visibleInsideTicket),
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.currentValue === null) {
      this.filter = {} as TechnicianSearchFilterModel;
    }
  }

  getControl(fcName: string): UntypedFormControl {
    return <UntypedFormControl>this.form.get(fcName);
  }
}
