import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {RouteListModel} from '../../../../api/model/route/route.line.model';

@Component({
  selector: 'tj-route-list',
  templateUrl: './routes-list.component.html',
  styleUrls: ['./routes-list.component.scss']
})
export class RoutesListComponent implements OnInit {

  @Input() routes: RouteListModel[];

  @Output('onSort') onSortEmitter = new EventEmitter();

  @Output('onView') onViewEmitter = new EventEmitter();

  selectedRoutes: RouteListModel[] = [];

  constructor(public config: ConfigService) {
  }

  ngOnInit() {

  }

  public getSelectedRoutes(): RouteListModel[] {
    return this.selectedRoutes;
  }


  sort($event: any) {
    this.onSortEmitter.emit($event);
  }

  view(routeId: number) {
    this.onViewEmitter.emit(routeId);
  }
}
