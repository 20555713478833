import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'tj-zip-code-import-example',
  templateUrl: './zip-code-import-example.component.html',
  styleUrls: ['./zip-code-import-example.component.scss']
})

export class ZipCodeImportExampleComponent implements OnInit, OnDestroy {

  importInfoDialog: boolean;

  title = 'The structure of valid import CSV file';

  constructor() {
  }

  ngOnInit() {
  }

  show() {
    this.importInfoDialog = true;
  }

  hide() {
    this.importInfoDialog = false;
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
