import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Partner} from '../../../../api/model/Partner.model';
import {PartnerLine} from '../../../../api/model/PartnerLine.model';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';

@Component({
  selector: 'tj-partner-type-selector',
  templateUrl: './partner-type-selector.component.html',
  styleUrls: ['./partner-type-selector.component.scss']
})
export class PartnerTypeSelectorComponent implements OnInit {

  @Input() partner: Partner;

  @Input() ticketPartnerStatus: TicketPartnerStatus;

  @Output() usePrivatePartner = new EventEmitter();

  @Output() partnerChange = new EventEmitter();

  @Output() resetPartner = new EventEmitter();

  ticketPartnerStatuses = TicketPartnerStatus;

  partners: PartnerLine[] | any;

  constructor(private notificationService: NotificationService,
              private configService: ConfigService,
              private keycloakService: TjKeycloakService) {
  }

  ngOnInit() {
    this.configService.partners$.subscribe(value => {
      this.partners = value;
    });
  }

  onPartnerChange(partnerId: number, message = null) {
    if (message && confirm(message)) {
      if (partnerId === -1) {
        this.onUsePrivatePartner();
      } else {
        this.emitPartnerChange(partnerId);
      }
    } else {
      if (partnerId === -1) {
        this.onUsePrivatePartner();
      } else {
        this.emitPartnerChange(partnerId);
      }
    }
  }

  onUsePrivatePartner() {
    this.usePrivatePartner.emit();
  }

  private emitPartnerChange(partnerId: number) {
    if (!partnerId) {
      return;
    }
    this.partnerChange.emit(partnerId);
  }

  public isAutoAssignedPartner(): boolean {
    return this.keycloakService.hasRole('TICKET_CREATE_AUTO_ASSIGNED_PARTNER');
  }
}
