import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {DeactivationConfirmationService} from '../../../shared/services';
import {NotificationService} from '../../../../core/services';
import {Messages} from '../../../../common/messages';

@Component({
  selector: 'tj-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
  providers: [DeactivationConfirmationService]
})
export class PartnerListComponent implements OnInit {

  @Input() partners;

  @Output() selectPartner = new EventEmitter();

  @Output('onSort') onSortEmitter = new EventEmitter();

  constructor(private api: ApiService,
              private deactivationConfirmationService: DeactivationConfirmationService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
  }

  onSelect($event): void {
    this.selectPartner.emit($event);
  }

  onDeactivatePartner(partnerId: number): void {
    this.deactivationConfirmationService.confirm(() => {
      this.api.partner.deactivate(partnerId).subscribe(() => {
        this.partners = this.partners.filter(partner => partner.id !== partnerId);
        this.partners = [...this.partners];
        this.onSelect(null);
        this.notificationService.success(Messages.PARTNER_DEACTIVATED);
      });
    });
  }

  onRecoverPartner(partnerId: number) {
    this.deactivationConfirmationService.confirm(() => {
      this.api.partner.recover(partnerId).subscribe(() => {
        this.partners = this.partners.filter(partner => partner.id !== partnerId);
        this.partners = [...this.partners];
        this.notificationService.success(Messages.PARTNER_RECOVERED);
        this.onSelect(null);
      });
    }, true);
  }

  sort($event: any) {
    this.onSortEmitter.emit($event);
  }
}
