import {ContactPerson} from './ContactPerson.model';
import {AddressDetails} from './Address.model';
import {plainToClass, Type} from 'class-transformer';
import {PaymentModel} from './Payment.model';
import {ContactType} from '../../main/shared/enums/contact.type';
import {PartnerType} from '../../main/shared/enums/partner.type';
import {ContactInfoList} from './ContactValueList.model';

export class Partner {

  id: number = null;

  name: string;

  email: string;

  password: string;

  username: string;

  @Type(() => AddressDetails)
  address: AddressDetails;

  paymentTerm: string;

  @Type(() => ContactPerson)
  contactPersons: ContactPerson[];

  isPrivate: boolean;

  partnerType: string;

  combinedInvoice: boolean;

  copyByFax: boolean;

  dateOfServiceNotification: boolean;

  printAllContactPersons: boolean;

  hasOverdueInvoices: boolean;

  @Type(() => PaymentModel)
  payment: PaymentModel;

  notes: string;
  netTerm: number;
  internalNote: string;

  constructor() {
  }

  static buildMinimalPartner(receiveNotifications: boolean): Partner {
    const partner = new Partner();
    partner.contactPersons = plainToClass(ContactPerson, [{
      name: null,
      isMain: true,
      receiveNotificationsRequestedRep: receiveNotifications,
      receiveInvoiceRequestedRep: receiveNotifications,
      receivesCommentsIfRep: receiveNotifications,
      pdfReceiver: receiveNotifications,
      contacts: [
        {type: ContactType.EMAIL, value: ''},
        {type: ContactType.MOBILE_PHONE, value: ''}
      ]
    }]);
    partner.email = null;
    partner.partnerType = PartnerType.DESIGNER;
    partner.address = new AddressDetails();
    partner.combinedInvoice = false;
    partner.printAllContactPersons = false;
    partner.copyByFax = false;
    partner.dateOfServiceNotification = false;
    partner.payment = {
      netTerm: null,
      netTermType: 'NET_TERM',
      approvalRequired: false,
      transactionId: null,
      money: null,
    };
    return partner;
  }

  static buildCopy(originalPartner: Partner): Partner {
    return plainToClass(Partner, {...originalPartner});
  }

  static buildBasedOnForm(obj: any): Partner {
    return plainToClass(Partner, obj as Object);
  }

  getMainContactPerson() {
    const contactPerson = this.contactPersons.find(c => c.isMain === true);
    if (contactPerson) {
      return contactPerson;
    }
    return null;
  }

  getContactPersonsData(partnerContactPerson: ContactPerson): ContactInfoList[] {
    const contactPersons = [partnerContactPerson];
    const contactTypeMapping = {
      [ContactType.EMAIL]: 'email',
      [ContactType.PHONE]: 'phone',
      [ContactType.MOBILE_PHONE]: 'mobilePhone',
      [ContactType.FAX]: 'fax',
    };

    return contactPersons.map(cp => {
      const contactInfoList = new ContactInfoList();
      contactInfoList.personName = cp.name;
      contactInfoList.id = cp.id;

      cp.contacts.forEach(contact => {
        const contactType = contactTypeMapping[contact.type];
        if (contactType) {
          contactInfoList[contactType].push(contact.value);
        }
      });

      return contactInfoList;
    });
  }
}
