import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class BreadcrumbService {

    private itemsSource = new Subject<MenuItem>();

    private showCopyToClipboardSource = new Subject<boolean>();

    private textSource = new Subject<string>();

    private textClassSource = new Subject<string>();

    itemsHandler = this.itemsSource.asObservable();

    textSourceHandler = this.textSource.asObservable();

    textClassSourceHandler = this.textClassSource.asObservable();

    showCopyToClipboardHandler = this.showCopyToClipboardSource.asObservable();


    addBreadcrumb(item: MenuItem) {
        this.itemsSource.next(item);
    }

    enableCopyToClipboard() {
        this.showCopyToClipboardSource.next(true);
    }

    addText(text: string) {
        this.textSource.next(text);
    }

    addTextClass(textClass: string) {
      this.textClassSource.next(textClass);
    }

    disableCopyToClipboard() {
        this.showCopyToClipboardSource.next(false);
    }
}
