import { Partner } from './Partner.model';
import { Type } from 'class-transformer';
import { InvoiceItem } from './InvoiceItem.model';


export class Invoice {
  id: number;

  status: string;

  @Type(() => Partner)
  partner: Partner;

  netTerm: number;

  totalAmount: number;

  ticketPartnerStatus: string;

  createdAt: Date;

  @Type(() => InvoiceItem)
  items: InvoiceItem[];
}
