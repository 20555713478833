<form [formGroup]="insetZipCodeForm" (submit)="onSaveZipCode()" *ngIf="insetZipCodeForm">
  <div class="field">
    <div class="col-12">
      <div class="mt-2">
        <p-chips id="zipCodes p-fluid"
                 (onAdd)="onInsertZipCodes($event)"
                 [allowDuplicate]="false"
                 (onClear)="clearZipCodes()"
                 [showClear]="true"
                 formControlName="zipCodes"
                 separator=","
                 placeholder="Zip codes"></p-chips>
      </div>
    </div>
    <div class="flex flex-grow-1 justify-content-end">
      <div>
        <button pButton pRipple type="button"
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                (click)="onCancel()"></button>
      </div>
      <div>
        <button pButton pRipple type="submit" label="Save" icon="pi pi-check" class="p-button-text"
                [disabled]="!insetZipCodeForm.valid"></button>
      </div>
    </div>
  </div>
</form>
