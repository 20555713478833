import { TicketFilter } from "../../../../api/model/TicketFilter";
import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Page} from '../../../../api/model/shared/Page.model';
import {TicketSearchService} from '../../services/ticket-search.service';

@Component({
  selector: 'tj-ticket-list-filter',
  templateUrl: './ticket-list-filter.component.html',
  styleUrls: ['./ticket-list-filter.component.scss']
})
export class TicketListFilterComponent implements OnInit {

  @Input() ticketFilter: TicketFilter;

  @Input() page: Page<any>;

  filterForm: UntypedFormGroup;

  constructor(private ticketSearchService: TicketSearchService) {
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
  }

  onSearchReset(): void {
    this.filterForm.reset();
    this.ticketSearchService.reset();
  }

  onRemoveFilter(ticketFilter): void {
    this.ticketSearchService.search(ticketFilter);
  }

  onFilterTickets(): void {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.ticketSearchService.searchByFormValue(formValue.ticketFilter);
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }
}
