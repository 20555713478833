import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PayFormInfo} from '../../../models';
import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotificationService, TjKeycloakService} from '../../../../../core/services';

@Component({
  selector: 'tj-pay-form-info',
  templateUrl: './pay-form-info.component.html',
  styleUrls: ['./pay-form-info.component.scss']
})
export class PayFormInfoComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @Output() onEnterPress: EventEmitter<any> = new EventEmitter<any>();

  @Input() form: UntypedFormGroup;

  private _info: PayFormInfo;

  get info(): PayFormInfo {
    return this._info;
  }

  @Input() set info(value: PayFormInfo) {
    this._info = value;
    this.init();
  }

  constructor(private fb: UntypedFormBuilder,
              private keycloakService: TjKeycloakService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.checkPermission();
  }

  private checkPermission() {
    if (!this.keycloakService.hasRole('PAYFORM_EDIT')) {
      this.form.disable({onlySelf: false, emitEvent: false});
    }
  }

  private addPayBalanceListener(infoFormGroup: UntypedFormGroup) {
    const nextWeekPayFormGroup = infoFormGroup.get('nextPayFormTransferDetails') as UntypedFormGroup;
    const nextWeekPayFormTransferredAmount = nextWeekPayFormGroup.get('transfer') as UntypedFormControl;

    const payBalanceFormControl: UntypedFormControl = this.form.get('payBalance') as UntypedFormControl;
    const balanceFormControl: UntypedFormControl = this.form.get('balance') as UntypedFormControl;
    const transferFormControl: UntypedFormControl = infoFormGroup.get('transfer') as UntypedFormControl;
    const paidAmountFormControl: UntypedFormControl = infoFormGroup.get('paidAmount') as UntypedFormControl;
    const bonusFormControl: UntypedFormControl = infoFormGroup.get('bonus') as UntypedFormControl;
    const bonus2FormControl: UntypedFormControl = infoFormGroup.get('bonus2') as UntypedFormControl;
    const deductFormControl: UntypedFormControl = infoFormGroup.get('deduct') as UntypedFormControl;
    const deduct2FormControl: UntypedFormControl = infoFormGroup.get('deduct2') as UntypedFormControl;
    const formArray = this.form.controls['payFormItems'] as UntypedFormArray;
    const cashTotalFormControl: UntypedFormControl = this.form.get('cashTotal') as UntypedFormControl;
    const inComeFormControl: UntypedFormControl = this.form.get('inCome') as UntypedFormControl;
    const percentFormControl: UntypedFormControl = this.form.get('percent') as UntypedFormControl;
    merge(infoFormGroup.valueChanges, formArray.valueChanges)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(value => {
        const bonus = +bonusFormControl.value;
        const bonus2 = +bonus2FormControl.value;
        const balance = +balanceFormControl.value;
        const deduct = +deductFormControl.value;
        const deduct2 = +deduct2FormControl.value;
        const transfer = +transferFormControl.value;
        const paidAmount = +paidAmountFormControl.value;
        const percent = +percentFormControl.value;
        const nextWeekTransferredAmount = +nextWeekPayFormTransferredAmount.value;
        let payOff = 0;
        let totalIncomeCash = 0;
        let income = 0;
        formArray.controls.forEach((formGroup: UntypedFormGroup) => {
          payOff += +formGroup.controls['payOff'].value;
          totalIncomeCash += +formGroup.controls['totalIncomeCash'].value;
          income += +formGroup.controls['incomeDayTotal'].value;
        });
        payBalanceFormControl.setValue((payOff + bonus + bonus2 - deduct2 - deduct + transfer - paidAmount - nextWeekTransferredAmount).toFixed(2));
        cashTotalFormControl.setValue((totalIncomeCash).toFixed(2));
        inComeFormControl.setValue((income * percent / 100).toFixed(2));
      });
    deduct2FormControl.setValue(deduct2FormControl.value);
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  private init() {
    const nextWeekPayFormGroup = this.fb.group({
      transfer: this.fb.control(this.info.nextPayFormTransferDetails?.transfer || 0),
      transferredDate: this.fb.control(this.info.nextPayFormTransferDetails?.transferredDate)
    });
    const infoFormGroup: UntypedFormGroup = this.fb.group({
      bonus: this.fb.control(this.info.bonus),
      bonus2: this.fb.control(this.info.bonus2),
      bonusDescription: this.fb.control(this.info.bonusDescription),
      bonusDescription2: this.fb.control(this.info.bonusDescription2),
      deduct: this.fb.control(this.info.deduct),
      deduct2: this.fb.control(this.info.deduct2),
      deductDescription: this.fb.control(this.info.deductDescription),
      deductDescription2: this.fb.control(this.info.deductDescription2),
      transfer: this.fb.control(this.info.transfer),
      transferred: this.fb.control(this.info.transferred),
      transferDate: this.fb.control(this.info.transferDate),
      paidAmount: this.fb.control(this.info.paidAmount),
      paidDate: this.fb.control(this.info.paidDate),
    });
    infoFormGroup.addControl('nextPayFormTransferDetails', nextWeekPayFormGroup);
    this.form.addControl('info', infoFormGroup);
    this.addPayBalanceListener(infoFormGroup);
  }

  onEnter() {
    this.onEnterPress.emit();
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.info('Amount is copied');
    } else {
      this.notificationService.error('Failed to copy.');
    }
  }

  getCopyValue(valueName: string) {
    return this.form.get('info')?.get(valueName)?.value;
  }
}
