import {Tag} from '../../../../api/model/Tag.model';
import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-tag-combobox',
  templateUrl: './tag-combobox.component.html',
  styleUrls: ['./tag-combobox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagComboboxComponent),
      multi: true
    }
  ]
})
export class TagComboboxComponent implements OnInit, ControlValueAccessor {

  @Input() disabled = false;

  @Output() onAdd = new EventEmitter();

  @Output() onRemove = new EventEmitter();

  @Input() blockBuiltin = false;

  allTags: Tag[] = [];

  filteredTags;


  private onTouchedCallback: Function;

  private onChangeCallback: Function;

  private innerValue = null;

  constructor(private configService: ConfigService) {
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    this.innerValue = v;
    this.onChangeCallback(v);
  }

  ngOnInit() {
    this.configService.tags$.subscribe(value1 => {
      this.allTags = this.blockBuiltin ? value1.filter(it => !it.builtin) : value1;
    });
  }

  onTagAdd(tag: Tag) {
    this.onAdd.emit(tag);
  }

  onTagRemove(tag: Tag) {
    if (this.blockBuiltin && tag.builtin) {
      this.innerValue = [tag, ...this.innerValue];
    } else {
      this.onRemove.emit(tag);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any): void {
    this.configService.tags$.subscribe(value1 => {
      this.allTags = value1;
      const arr = [];
      if (value) {
        value.forEach((item) => {
          if (this.allTags.length) {
            const itemTag = this.allTags.find(x => x.id === parseFloat(item.id ? item.id : item));
            arr.push(itemTag);
          }
        });
      }
      this.innerValue = arr;
    });
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onFilterSuggestions(event) {
    const query = event.query;
    const values = this.allTags;
    const filtered: any[] = [];
    for (const value of values) {
      if (value.label && value.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        if (!(this.blockBuiltin && value.builtin)) {
          filtered.push(value);
        }
      }
    }
    this.filteredTags = filtered;
  }
}
