import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable()
export class GalleryService {

  imgproxyEndpoint: string = environment.imgproxyEndpoint;

  public getImageUrl(imagePath: string, {width = 0, height = 0, quality = 80, resized = false}): string {
    const resize = resized ? '-400' : '';
    return this.imgproxyEndpoint + `/w:${width}/h:${height}/q:${quality}/plain/s3://tj-attachments/${imagePath}${resize}`;
  }

}
