<div class="card">

  <div class="grid flex-column">



    <div class="mb-3">
      <p-tabMenu [model]="items"></p-tabMenu>
    </div>
    <div>
      <router-outlet></router-outlet>
    </div>


    <!-- <p-tabView>
      <p-tabPanel header="Configs">
        <tj-configurations-component></tj-configurations-component>
      </p-tabPanel>
      <p-tabPanel header="Tags">
        <ng-template pTemplate="content">
          <tj-tag-configurations-component></tj-tag-configurations-component>
        </ng-template>
      </p-tabPanel>
    </p-tabView> -->
  </div>
</div>
