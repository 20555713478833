import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SimpleChanges } from '@angular/core';

declare var google: any; // new added line

const DEFAULT_ZOOM = 12;

@Component({
  selector: 'tj-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  @Input() lat: number;
  @Input() lng: number;
  @Input() mapHeight: number;

  options: { center: { lat: number; lng: number } };

  overlays: [any];

  map: google.maps.Map;

  constructor() {}

  setMap(event) {
    this.map = event.map;
  }

  ngOnInit() {
    this.reflectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reflectChanges();
  }

  reflectChanges() {
    if (this.map) {
      this.options = {
        center: { lat: this.lat, lng: this.lng },
        zoom: DEFAULT_ZOOM,
      };
      this.map.setOptions(this.options);
      this.overlays = [
        new google.maps.Marker({ position: { lat: this.lat, lng: this.lng } }),
      ];
    }
  }
}
