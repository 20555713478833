import {Area} from './Area.model';

export class AddressDetails {

  id: number;

  country: string;

  city: string;

  state: string;

  street: string;

  bld: string;

  apt: string;

  postalCode: string;

  addressFormatted: string;

  additionalAddress: string;

  lat: number;

  lng: number;

  tag: string;

  areas: Area[];

  toString?() {
    return `${this.bld || ''} ${this.street || ''} ${this.apt || ''} ${this.city || ''} ${this.state || ''} ${this.postalCode || ''}`;
  }

  getFormattedAddress?(): string {
    return this.addressFormatted ? this.addressFormatted : this.toString();
  }
}
