import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'tj-action-details',
  templateUrl: 'action-details.component.html',
})
export class ActionDetailsComponent implements OnInit {

  private readonly MAX_LENGTH = 500;

  actionForm: UntypedFormGroup;

  constructor(public ref: DynamicDialogRef, private fb: UntypedFormBuilder) {
    this.actionForm = this.fb.group({
      details: ['', [Validators.required, Validators.maxLength(this.MAX_LENGTH)]],
    });
  }

  ngOnInit() {
  }

  submit() {
    this.ref.close({
      value: this.actionForm.get('details').getRawValue()
    });
  }

  getControl(controlName: string) {
    return this.actionForm.get(controlName) as UntypedFormControl;
  }
}
