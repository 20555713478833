import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {map, scan} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private requestUpdates = new Subject<number>();

  private activeRequests = this.requestUpdates.pipe(
    scan((acc, value) => Math.max(0, acc + value), 0),
    map(count => count > 0)
  );

  isVisible$ = this.activeRequests;

  constructor() {
  }

  // Method to show the progress bar
  show() {
    this.requestUpdates.next(1);
  }

  // Method to hide the progress bar
  hide() {
    this.requestUpdates.next(-1);
  }
}
