import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Ticket} from '../../../../../api/model/Ticket.model';
import {SignatureModel} from '../../../models/Signature.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TjKeycloakService} from '../../../../../core/services';

@Component({
  selector: 'tj-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss']
})
export class SignatureDialogComponent implements OnInit, OnChanges {

  signature: SignatureModel;

  @Input() ticketForm: UntypedFormGroup;

  @Input() galleryId: number;

  @Input() ticket: Ticket;

  @Output() onSaveReport = new EventEmitter();

  reportFc: UntypedFormControl;

  constructor(private fb: UntypedFormBuilder,
              private keycloakService: TjKeycloakService) {
  }

  ngOnInit() {
    this.reportFc = this.fb.control(this.ticket.report);
    if (!this.keycloakService.hasRole('TICKET_EDIT_REPORT') && !this.loggedInUserIsHeadTech()) {
      this.reportFc.disable();
    }
  }

  saveReport() {
    this.ticketForm.get('report').setValue(this.reportFc.getRawValue());
    this.onSaveReport.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticket && changes.ticket.currentValue !== changes.ticket.previousValue) {
      this.ngOnInit();
    }
  }

  public loggedInUserIsHeadTech(): boolean {
    return this.ticket.technicians.some(technician => {
      return technician.username === this.keycloakService.user.username && technician.head;
    });
  }

}
