export class PartnerLine {
  id: number;
  name: string;
  netTermType: string;
  partnerType: string;
  active: boolean;
  isPrivate: boolean;
}

export class PartnerMinimalListItem {
  id: number;
  name: string;
  isPrivate: boolean;
}
