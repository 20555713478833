import {Component, OnInit} from '@angular/core';

import {ConfigService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {RouteListModel} from '../../../../api/model/route/route.line.model';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'tj-route-view',
  templateUrl: './route-view.component.html',
  styleUrls: ['./route-view.component.scss']
})
export class RouteViewComponent implements OnInit {

  routeModel: RouteListModel;


  constructor(public config: ConfigService,
              private route: ActivatedRoute,
              private api: ApiService) {
  }

  ngOnInit() {
    const routeId = this.route.snapshot.params['id'];
    this.loadRoute(routeId);
  }

  private loadRoute(routeId: number) {
    this.api.routeApi.getRoute(routeId)
      .subscribe(route => {
        this.routeModel = route;
      });
  }

}
