import {Observable, of as observableOf} from 'rxjs';

import {Injectable} from '@angular/core';
import {ApiService} from '../../api/service/api.service';
import {User} from '../../api/model/User.model';
import {NotificationService} from './notification.service';
import {TjKeycloakService} from './tj-keycloak.service';

@Injectable()
export class UserService {

  private user: User;

  constructor(private api: ApiService,
              private keycloakService: TjKeycloakService) {}

  fetchCurrentUser(): Observable<User> {
    const localUser = this.fetchCachedUser();
    if (localUser) {
      return observableOf(localUser);
    } else {
      return this.keycloakService.getUser();
    }
  }

  getCurrentUser() {
    return this.fetchCachedUser();
  }

  private fetchCachedUser(): User {
    const localStorageUser = localStorage.getItem('user');
    if (this.user) {
      return this.user;
    } else if (localStorageUser) {
      try {
        this.user = JSON.parse(localStorageUser);
        return this.user;
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }
}
