import {Injectable} from '@angular/core';
import {Page, PageQuery, SearchQuery, SortQuery} from '../model/shared/Page.model';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {RouteRequestModel} from '../model/route/route.request.model';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {RouteListModel} from '../model/route/route.line.model';
import {RouteFilter} from '../model/route/route.filter';
import {IdModel} from '../model/shared/Response.model';

@Injectable()
export class RouteApi extends CommunicationFilterableService implements SearchQuery {

  protected apiEndpoint = this.env.apiEndpoint + 'routes';

  getRoutes(pageQuery: PageQuery, sortQuery: SortQuery, filter: RouteFilter): Observable<Page<RouteListModel>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter
    };
    const url = this.apiEndpoint;
    return this.requestService.get(url, {params})
      .pipe(
        map(response => response as Page<RouteListModel>),
        catchError(this.handleError));
  }

  getRouteByDate(day: string): Observable<RouteListModel> {
    const url = this.apiEndpoint + `/technician?date=${day}`;
    return this.requestService.get(url)
      .pipe(
        map(response => response.response),
        catchError(this.handleError));
  }

  getRoute(routeId: number): Observable<RouteListModel> {
    const url = `${this.apiEndpoint}/${routeId}`;
    return this.requestService.get(url)
      .pipe(
        map(response => response.response as RouteListModel),
        catchError(this.handleError));
  }

  create(routeRequestModel: RouteRequestModel, notify: boolean): Observable<IdModel> {
    const url = `${this.apiEndpoint}?notify=${notify}`;
    return this.requestService.post(url, routeRequestModel)
      .pipe(
        map(response => response.response),
        catchError(this.handleError)
      );
  }

  update(routeRequestModel: RouteRequestModel, notify: boolean): Observable<IdModel> {
    const url = `${this.apiEndpoint}?notify=${notify}`;
    return this.requestService.put(url, routeRequestModel)
      .pipe(
        catchError(this.handleError)
      );
  }

  sendRoute(routeId: number, email: string): Observable<any> {
    const url = `${this.apiEndpoint}/${routeId}/send`;
    return this.requestService.post(url, {
      email
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(ids: number[]) {
    const url = this.apiEndpoint + '/delete';
    return this.requestService.post(url, {
      ids
    })
      .pipe(
        catchError(this.handleError)
      );
  }

}
