<div [formGroup]="form" class="grid align-items-center">
  <div [formGroupName]="group" class="col-12">
    <div class="grid justify-content-start">
      <div class=" lg:col-3 md:col-4 col-12">
        <tj-field label="Name" [control]="getControl('name')">
          <input pInputText type="text"
                 placeholder="Name"
                 id="name"
                 formControlName="name"/>
        </tj-field>
      </div>
      <div class="lg:col-3 md:col-3 col-12">
        <tj-field label="PersonnelCode" [control]="getControl('personnelCode')">
          <input pInputText type="text"
                 placeholder="PersonnelCode"
                 id="personnelCode"
                 formControlName="personnelCode"/>
        </tj-field>
      </div>
      <div class="lg:col-3 md:col-3 col-12">
        <tj-field label="Company" [control]="getControl('companyName')">
          <input pInputText type="text"
                 placeholder="Company Name"
                 id="companyName"
                 formControlName="companyName"/>
        </tj-field>
      </div>
      <div class="lg:col-3 md:col-4 col-12">
        <tj-field label="Email" [control]="getControl('email')">
          <input pInputText type="text"
                 placeholder="Email"
                 id="email"
                 formControlName="email"/>
        </tj-field>
      </div>
      <div class="lg:col-3 md:col-3 col-12">
        <tj-field label="Phone" [control]="getControl('phone')">
          <input pInputText type="text"
                 placeholder="Phone"
                 id="phone"
                 formControlName="phone"/>
        </tj-field>
      </div>
      <div class="md:col-1 sm:col-6 private-checkbox-container mt-4 flex justify-content-start align-items-center">
        <p-checkbox binary="true" formControlName="deleted" label="Deleted"></p-checkbox>
      </div>
      <div class="md:col-2 sm:col-6 private-checkbox-container mt-4 flex justify-content-start align-items-center">
        <p-checkbox binary="true" formControlName="visibleInsideTicket" label="Hiding on ticket"></p-checkbox>
      </div>
    </div>
  </div>
</div>
