<div *ngIf="legs && legs.length">
  <h3>Stop Details</h3>
  <div *ngFor="let leg of legs" class="leg">
    <p><strong>Start Address:</strong> {{ leg.start_address }}</p>
    <p><strong>End Address:</strong> {{ leg.end_address }}</p>
    <p><strong>Distance:</strong> {{ leg.distance.value | distance:'mi' }}</p>
    <p><strong>Duration:</strong> {{ leg.duration.value | duration}}</p>
    <ul>
      <li *ngFor="let step of leg.steps" [innerHTML]="step.instructions + '(' + (step.distance.value | distance: 'mi') + ')'"></li>
    </ul>
  </div>
</div>
