import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {PartnerSearchFilterModel} from '../../models/partner-search-filter.model';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'tj-partner-search-labels',
  templateUrl: './partner-search-labels.component.html',
  styleUrls: ['./partner-search-labels.component.scss']
})
export class PartnerSearchLabelsComponent implements OnChanges, OnInit {

  private readonly paymentTermMap: Map<string, string> = new Map<string, string>();

  @Input() partnerFilter: PartnerSearchFilterModel;
  @Input() filterForm: UntypedFormGroup;
  @Output() remove = new EventEmitter();

  filterNames = {
    id: 'Id',
    name: 'Name',
    username: 'Username',
    netTermType: 'Payment term',
    email: 'Email',
    address: 'Address',
    deleted: 'Deleted'
  };

  constructor(public config: ConfigService) {
  }

  ngOnInit() {
    this.config.paymentTermMap.forEach(it => {
      this.paymentTermMap.set(it.value as string, it.label);
    });
  }

  ngOnChanges() {
  }

  onRemoveFilter(filterName: string) {
    const updatedFilter = {...this.partnerFilter};
    if (updatedFilter[filterName]) {
      updatedFilter[filterName] = null;
      if (this.filterForm && this.filterForm.get(`${filterName}`)) {
        this.filterForm.get(`${filterName}`).setValue(null);
      }
    }
    this.remove.emit(updatedFilter);
  }

  getTermValue(status: TicketStatus): string {
    return this.paymentTermMap.get(status);
  }

}
