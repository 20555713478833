import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {InvoiceList} from '../../../../api/model/InvoiceList.model';
import {Messages} from '../../../../common/messages';
import {Dropdown} from 'primeng/dropdown';
import {Router} from '@angular/router';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {ConfirmationService} from 'primeng/api';
import {ActionDetailsComponent} from '../../../shared/components';
import {filter, map} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'tj-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {

  @Input() invoices: InvoiceList[];

  selectedInvoices: InvoiceList[] = [];

  mediaDialogRef: DynamicDialogRef;

  @Output('onSort') onSortEmitter = new EventEmitter();

  constructor(private api: ApiService,
              public config: ConfigService,
              private confirmationService: ConfirmationService,
              private notificationService: NotificationService,
              private router: Router,
              public dialogService: DialogService,
              private keycloakServices: TjKeycloakService) {
  }

  ngOnInit() {
  }

  onChangeStatus(status, invoiceId, invoiceStatus: Dropdown) {
    if (status === 'VOID') {
      return this.voidStatus(invoiceId, invoiceStatus);
    } else if (status === 'PREPAID') {
      return this.prepaidStatus(invoiceId, invoiceStatus);
    }

    this.changeStatus(status, invoiceId, invoiceStatus);
  }

  changeStatus(status, invoiceId, invoiceStatus: Dropdown): void {
    this.api.invoice.changeStatus(invoiceId, status)
      .subscribe(() => {
        const findInvoice = this.invoices.find(invoice => invoice.id === invoiceId);
        findInvoice.status = status;
        if (status === 'PAID') {
          findInvoice.printed = true;
        }
        this.notificationService.success(Messages.STATUS_UPDATED);
      }, error => {
        invoiceStatus.selectedOption = this.invoices.find(invoice => invoice.id === invoiceId).status;
        this.notificationService.error(Messages.STATUS_NOT_UPDATED, error);
      });
  }

  voidStatus(invoiceId: number, invoiceStatus: Dropdown) {
    this.showVoidReasonDialog()
      .subscribe((reason: string) => {
        if (reason) {
          this.api.invoice.voidStatus(invoiceId, reason)
            .subscribe(() => {
              const findInvoice = this.invoices.find(invoice => invoice.id === invoiceId);
              findInvoice.status = 'VOID';
              this.notificationService.success(Messages.STATUS_UPDATED);
            }, error => {
              invoiceStatus.selectedOption = this.invoices.find(invoice => invoice.id === invoiceId).status;
              this.notificationService.error(Messages.STATUS_NOT_UPDATED, error);
            });
        }
      });
  }

  prepaidStatus(invoiceId: number, invoiceStatus: Dropdown) {
    this.showPrepaidReasonDialog()
      .subscribe((reason: string) => {
        if (reason) {
          this.api.invoice.prepaidStatus(invoiceId, reason)
            .subscribe(() => {
              const findInvoice = this.invoices.find(invoice => invoice.id === invoiceId);
              findInvoice.status = 'PREPAID';
              this.notificationService.success(Messages.STATUS_UPDATED);
            }, error => {
              invoiceStatus.selectedOption = this.invoices.find(invoice => invoice.id === invoiceId).status;
              this.notificationService.error(Messages.STATUS_NOT_UPDATED, error);
            });
        }
      });
  }

  sort($event: any) {
    this.onSortEmitter.emit($event);
  }

  public getSelectedInvoices(): InvoiceList[] {
    return this.selectedInvoices;
  }

  selectUnprinted() {
    this.selectedInvoices = [];
    this.invoices
      .filter(it => !it.printed)
      .forEach(invoice => this.selectedInvoices.push(invoice));
  }

  canEditStatus(): boolean {
    return this.keycloakServices.hasRole('INVOICE_EDIT');
  }

  toPaymentPage(invoiceId: number, approvalRequired: boolean) {
    const toPaymentPage = () =>
      this.router.navigate(['/payment'], {
        queryParams: {itemType: 'invoice', itemId: invoiceId},
        queryParamsHandling: 'merge'
      });

    if (approvalRequired) {
      this.confirmationService.confirm({
        message: 'This account requires approval prior of charging',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        key: 'confirmation',
        accept: () => {
          toPaymentPage();
        },
      });
    } else {
      toPaymentPage();
    }
  }

  isPrivatePartner(ticketPartnerStatus): boolean {
    return ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE] ||
      ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE_BUSINESS];
  }

  showVoidReasonDialog() {
    this.mediaDialogRef = this.dialogService.open(ActionDetailsComponent, {
      header: `Invoice void reason`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        reason: 'reason',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => {
        return data.value;
      }));
  }

  showPrepaidReasonDialog() {
    this.mediaDialogRef = this.dialogService.open(ActionDetailsComponent, {
      header: `Invoice prepaid reason`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        reason: 'reason',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => {
        return data.value;
      }));
  }
}
