<ng-container [formGroup]="form">
  <tj-field [control]="contactPersonControl" label="Business Contact/Representative">
    <p-dropdown
      id="contactPersons"
      [formControlName]="fieldName"
      [options]="getContactPeople()"
      dataKey="id"
      optionLabel="name"
      placeholder="Select existing contact person"
      formControlName="fieldName"
      [autoWidth]="false"
    ></p-dropdown>
  </tj-field>
</ng-container>
