export enum PartnerType {
  ANONYMOUS = 'ANONYMOUS',
  WARRANTY = 'WARRANTY',
  WAREHOUSE_DELIVERY = 'WAREHOUSE_DELIVERY',
  HOSPITALITY = 'HOSPITALITY',
  PROTECTION = 'PROTECTION',
  MOVING = 'MOVING',
  RETAIL = 'RETAIL',
  MANUFACTURE = 'MANUFACTURE',
  DESIGNER = 'DESIGNER',
}
