<p-table
  (onRowSelect)="onSelect($event)"
  (onSort)="sort($event)"
  [autoLayout]="true"
  [selectionMode]="'single'"
  [value]="technicians"
  [responsive]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <!-- <th pSortableColumn="id">ID
        <p-sortIcon field="id"></p-sortIcon>
      </th>-->
      <th pSortableColumn="firstName">
        Name
        <p-sortIcon field="firstName"></p-sortIcon>
      </th>
      <th pSortableColumn="personnelCode" style="width: fit-content">
        Code
        <p-sortIcon field="personnelCode"></p-sortIcon>
      </th>
      <th pSortableColumn="companyName" style="width: fit-content">
        Company Name
        <p-sortIcon field="companyName"></p-sortIcon>
      </th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-technician>
    <tr [pSelectableRow]="technician">
      <!--      <td>{{technician.id}}</td>-->
      <td class="flex-column flex justify-content-center" style="min-height: 65px;">
        <span>{{ technician.name }}</span>
        <span *ngIf="technician?.speciality"
              class="ml-3 text-sm"
              [pTooltip]="technician.speciality.length > 30 ? technician.speciality : ''"
              tooltipPosition="right"
              tooltipStyleClass="custom-tooltip-wide">
          <ng-container *ngIf="technician.speciality.length > 30">
            {{technician.speciality.substring(0, 30)}} ...
          </ng-container>
          <ng-container *ngIf="technician.speciality.length <= 30">
            {{technician.speciality}}
          </ng-container>
        </span>
      </td>
      <td>
        <span>{{ technician.personnelCode }}</span>
      </td>
      <td>
        <span>{{ technician.companyName }}</span>
      </td>
      <td style="width: 58px; min-height: 65px;" class="centered-column">
        <ng-container *tjHasRole="'TECHNICIAN_DELETE'">
          <button *ngIf="technician.active" class="p-button-danger" icon="pi pi-trash" pButton
                  (click)="onDeactivateTechnician(technician.id)"
          ></button>
          <button
            *ngIf="!technician.active" class="p-button-info" icon="pi pi-undo" pButton
            (click)="onRecoverTechnician(technician.id)"
          ></button>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog appendTo="body" key="confirmDeactivation"></p-confirmDialog>
