import {Filter} from './shared/Filter.model';

export class ZipCodeSearch extends Filter {
  id?: number;
  zipCode: string;
  travelFee: number;
}

export class ZipCode extends Filter {
  id?: number;
  zipCode: string;
  tag: string;
  travelFee: number;
  active: boolean;
  areaCode: string;
}
