import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../core/services';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'payment',
        component: PaymentDetailsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['PAYMENT_PROCESS'],
        }
      }
    ])
  ],
  declarations: [
    PaymentDetailsComponent
  ],
  exports: [
    PaymentDetailsComponent
  ]
})
export class PaymentModule { }
