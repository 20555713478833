<form [formGroup]="areaForm" (submit)="onSaveArea()" *ngIf="areaForm">
  <div class="field">
    <div class="col-12">
      <tj-field label="Name" [control]="getControl('name')">
        <input type="text" pInputText formControlName="name"/>
      </tj-field>
    </div>

    <div class="col-12">
      <tj-field label="Code" [control]="getControl('code')">
        <input type="text" pInputText formControlName="code"/>
      </tj-field>
    </div>
  </div>
  <div class="flex flex-grow-1 justify-content-end">
    <div>
      <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" class="p-button-text"
              (click)="onCancel()"></button>
    </div>
    <div>
      <button pButton pRipple [disabled]="!areaForm.valid" type="submit" label="Save" icon="pi pi-check"
              class="p-button-text"></button>
    </div>
  </div>
</form>

