import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Area} from '../../../../../api/model/Area.model';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'tj-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.scss']
})
export class AreaListComponent implements OnInit {

  protected readonly env = environment;

  @Input('areaDialog') areaDialog: boolean;

  @Input('zipCodesDialog') zipCodesDialog: boolean;

  @Input('areas') areas: Area[];

  @Output('onEditZipCodes') editZipCodesEmitter: EventEmitter<Area> = new EventEmitter<any>();

  @Output('onUpdate') updateEmitter: EventEmitter<Area> = new EventEmitter<any>();

  @Output('onDelete') deleteEmitter: EventEmitter<Area> = new EventEmitter<any>();

  area: Area;

  ngOnInit(): void {
  }

  onEditZipCodes(area: Area) {
    this.area = area;
    this.zipCodesDialog = true;
    this.editZipCodesEmitter.emit(area);
  }

  onEditArea(area: Area) {
    this.area = {...area};
    this.areaDialog = true;
    this.updateEmitter.emit(area);
  }

  onDeleteArea(areaToRemove: Area) {
    this.deleteEmitter.emit(areaToRemove);
  }

}
