import {Component, Input, OnInit} from '@angular/core';
import {Page} from '../../../../api/model/shared/Page.model';
import {UntypedFormGroup} from '@angular/forms';
import {NotificationService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {InvoiceSearchFilterModel} from '../../models/invoice-search-filter.model';
import {InvoiceSearchService} from '../../service/invoice-search.service';
import {DropdownModel} from '../../../../api/model/shared/dropdown.model';

@Component({
  selector: 'tj-invoice-search',
  templateUrl: './invoice-search.component.html',
  styleUrls: ['./invoice-search.component.scss']
})
export class InvoiceSearchComponent implements OnInit {

  @Input() filter: InvoiceSearchFilterModel;

  @Input() page: Page<any>;

  filterForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private invoiceSearchService: InvoiceSearchService,
    private api: ApiService) {
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
  }

  onSearchReset(): void {
    this.filterForm.reset();
    this.invoiceSearchService.reset();
  }

  onRemoveFilter(filter): void {
    this.invoiceSearchService.search(filter);
  }

  onFilter() {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.invoiceSearchService.searchByFormValue(formValue.invoiceFilter);
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }

}
