<div class="grid" [formGroup]="currentFormGroup">
  <div class="lg:col-4 md:col-6 col-12">
    <tj-field label="Amount" [control]="fcInvoiceAmount">
      <tj-money-input formControlName="invoiceAmount" placeholder="100"></tj-money-input>
    </tj-field>
  </div>
  <div class="lg:col-4 md:col-6 col-12">
    <tj-field label="Expense" [control]="fcInvoiceExpenses">
      <tj-money-input formControlName="invoiceExpenses" placeholder="0"></tj-money-input>
    </tj-field>
  </div>
  <div class="lg:col-4 md:col-12 col-12">
    <tj-field label="Tax" [control]="fcInvoiceTax">
      <tj-percentage-input formControlName="invoiceTax" placeholder="0"></tj-percentage-input>
    </tj-field>
  </div>
  <div class="lg:col-12 mt-2 mb-2" *ngIf="((
                ticketInvoice.ticket.status === TicketStatus.VERIFIED ||
                ticketInvoice.ticket.status === TicketStatus.COMPLETED) &&
                ticketInvoice.ticket.invoiceId === null) || ticketInvoice.ticket.invoiceId !== null">
    <div class="flex align-items-center justify-content-start">

      <ng-container *ngIf="ticketInvoice.ticket.invoiceId">
        <span *ngIf="ticketInvoice.ticket.ticketPartnerStatus === TicketPartnerStatus.REGISTERED">
          <ng-container *ngIf="ticketInvoice.netTerm === 0">
            <strong class="mr-3">{{ PaymentType.DUE_ON_RECEIPT }}</strong>
          </ng-container>
          <ng-container *ngIf="ticketInvoice.netTerm !== 0">
            {{ PaymentType.NET_TERM }} : <strong> {{ this.ticketInvoice.netTerm }} Days</strong>
          </ng-container>
        </span>

        <ng-container *ngIf="ticketInvoice.ticket.ticketPartnerStatus !== TicketPartnerStatus.REGISTERED">
          <tj-field label="Net term" [control]="fcInvoiceNetTerm" class="col-4 pl-0 pr-2">
            <p-inputNumber mode="decimal" formControlName="invoiceNetTerm" [useGrouping]="false"></p-inputNumber>
          </tj-field>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!ticketInvoice.ticket.invoiceId">
         <span *ngIf="ticketInvoice.ticket.ticketPartnerStatus === TicketPartnerStatus.REGISTERED">
          <ng-container *ngIf="invoiceNetTerm === 0">
            <strong class="mr-3">{{ PaymentType.DUE_ON_RECEIPT }}</strong>
          </ng-container>
          <ng-container *ngIf="invoiceNetTerm !== 0">
            {{ PaymentType.NET_TERM }} : <strong> {{ invoiceNetTerm }} Days</strong>
          </ng-container>
        </span>
        <ng-container *ngIf="ticketInvoice.ticket.ticketPartnerStatus !== TicketPartnerStatus.REGISTERED">
          <tj-field label="Net term" [control]="fcInvoiceNetTerm" class="col-4 pl-0 pr-2">
            <p-inputNumber mode="decimal" formControlName="invoiceNetTerm" [useGrouping]="false"></p-inputNumber>
          </tj-field>
        </ng-container>
      </ng-container>

      <tj-field [control]="fcIsInvoicePaid" class="ml-2">
        <p-checkbox [disabled]="ticketInvoice.ticket.invoiceStatus === 'PAID'" (onChange)="confirmPaid($event)"
                    formControlName="isInvoicePaid" label="Mark Invoice as Paid" binary="true"></p-checkbox>
      </tj-field>

    </div>
  </div>

</div>
<p-confirmDialog appendTo="body" key="confirmChangeInvoiceStatusToPaid"></p-confirmDialog>
