<form [formGroup]="editForm">
  <div class="flex flex-wrap">
    <div class="col-12">
      <tj-field label="Start time" [control]="getControl('serviceTimeStart')">
        <tj-time placeholder="Select" formControlName="serviceTimeStart" [currentDate]="getControl('serviceTimeStart').value"></tj-time>
      </tj-field>
    </div>

    <div class="col-12">
      <tj-field label="End time" [control]="getControl('serviceTimeEnd')">
        <tj-time placeholder="Select" formControlName="serviceTimeEnd" [currentDate]="getControl('serviceTimeEnd').value"></tj-time>
      </tj-field>
      <span class="text-red-500" *ngIf="this.editForm?.errors?.timeRangeError">End time must be after start time.</span>
    </div>

    <div class="col-12">
      <tj-field label="Time needed" [control]="getControl('timeNeeded')">
        <p-dropdown [options]="ticketEstimations" placeholder="Estimate" formControlName="timeNeeded" appendTo="body">
          <ng-template let-item pTemplate="selectedItem">
            <span class="font-bold">{{ item.label }}{{ item.label === 1 ? "hour" : "hours" }}</span>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <span>{{ item.label }}{{ item.label === 1 ? "hour" : "hours" }}</span>
          </ng-template>
        </p-dropdown>
      </tj-field>
    </div>
    <div class="lg:col-12 sm:col-12 text-right">
      <button pButton type="button" label="Save" (click)="save(false)"></button>
      <button pButton type="button" label="Save and notify customer" class="ml-2" (click)="save(true)"></button>
    </div>
  </div>
</form>
