import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { Client } from '@stomp/stompjs';

@Injectable({
  providedIn: 'root',
})
export class TjRxStompService extends RxStomp {
  constructor() {
    super();
  }
}
