<form novalidate
      autocomplete="off"
      [formGroup]="filterForm"
      (reset)="onSearchReset()"
      (ngSubmit)="onFilter()">
  <div class="grid">
    <div class="col-12">
      <tj-pay-form-search-form [filter]="filter"
                               [form]="filterForm"
                               group="payFormFilter"></tj-pay-form-search-form>
    </div>
    <div class="col-12 py-0 flex">
      <tj-pay-form-search-labels (remove)="onRemoveFilter($event)" [filterForm]="filterForm"
                                 [payFormSearchFilter]="filter"></tj-pay-form-search-labels>
    </div>
    <div class="col-12 align-content-end flex justify-content-between">
      <div class="flex align-items-end">
        <span *ngIf="page">Total: {{page.totalElements}}</span>
      </div>
      <div>
        <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
        <button pButton type="submit" label="Search" icon="pi pi-search"></button>
      </div>
    </div>
  </div>
</form>
