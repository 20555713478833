<div class="grid" [formGroup]="form">
  <div class="col-12" formGroupName="info">
    <div class="grid">
      <div class="col-3">Tech received / transfer from
        <ng-container *ngIf="info.transferredFromId; else noTransferredLastWeekId">
          <a [routerLink]="['/payforms' , this.info.transferredFromId]" class="font-bold my-2 text-blue-700">last
            week</a>
        </ng-container>
        <ng-template #noTransferredLastWeekId>
          last week
        </ng-template>
      </div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="transfer" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <tj-datepicker [onlyFuture]="false" formControlName="transferDate"
                         placeholder="Transfer date"></tj-datepicker>
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">Added</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="bonus" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="bonusDescription" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">Added</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="bonus2" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="bonusDescription2" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">Deducted</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="deduct" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="deductDescription" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">Deducted</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="deduct2" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="deductDescription2" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 no-print">
        <hr>
      </div>
    </div>
    <div class="grid" formGroupName="nextPayFormTransferDetails"
         *ngIf="this.info.nextPayFormTransferDetails?.payFormId">
      <div class="col-3">Amount transferred to
        <a [routerLink]="['/payforms' , this.info.nextPayFormTransferDetails.payFormId]"
           class="font-bold my-2 text-blue-700">next week</a>
      </div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="transfer" [disabled]="true"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-datepicker [onlyFuture]="false" [disabled]="true" formControlName="transferredDate"
                       placeholder="Next week transferred date"></tj-datepicker>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">
        <span>Tech paid amount</span>
      </div>
      <div class="col-4 flex">
        <div class="col-11 p-0">
          <tj-field>
            <tj-money-input formControlName="paidAmount" (onEnter)="onEnter()"></tj-money-input>
          </tj-field>
        </div>
        <button pButton pRipple type="button" icon="pi pi-copy" class=" col-1 ml-2 p-button-rounded p-button-text"
                [cdkCopyToClipboard]="getCopyValue('paidAmount')"
                (cdkCopyToClipboardCopied)="onCopySuccess($event)"></button>
      </div>
      <div class="col-5">
        <tj-field>
          <tj-datepicker [onlyFuture]="false" formControlName="paidDate" placeholder="Paid date"></tj-datepicker>
        </tj-field>
      </div>
    </div>
  </div>
</div>
