import { AddressDetails } from '../../../../api/model/Address.model';



export class AddressedMarker {
  id: number;
  address: AddressDetails;
  serviceTimeStart: Date;
  serviceTimeEnd: Date;
  timeNeeded = 0;
  ticketId: number;
  customer: string;
  marker: google.maps.Marker;

  constructor(address, marker) {
    this.address = address;
    this.marker = marker;
  }
}
