import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {Ticket} from '../../../../api/model/Ticket.model';
import {ConfigService, TjKeycloakService} from '../../../../core/services';

@Component({
  selector: 'tj-issue-form',
  templateUrl: './issue-form.component.html',
  styleUrls: ['./issue-form.component.scss']
})
export class IssueFormComponent extends BaseFormGroup implements OnChanges {
  private readonly CATEGORY_MAX_LENGTH: number = 40;

  @Input() ticket: Ticket;
  @Input() form: UntypedFormGroup;
  @Input() group: string;

  constructor(public configService: ConfigService,
              private keycloakService: TjKeycloakService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ticket.firstChange || this.ticket !== changes.ticket.previousValue) {
      const ticket = this.ticket;
      let formControls = {
        externalReferenceCode: new UntypedFormControl(ticket.externalReferenceCode),
        issue: new UntypedFormControl(ticket.issue, Validators.required),
        additionalNotes: new UntypedFormControl(ticket.additionalNotes),
      };
      if (this.canEditCategory()) {
        formControls = {
          ...formControls,
          category: new UntypedFormControl(ticket.category, Validators.maxLength(this.CATEGORY_MAX_LENGTH)),
        };
      }
      if (this.configService.isEnabledWarranty()) {
        formControls = {
          ...formControls,
          deliveryDate: new UntypedFormControl(this.ticket.deliveryDate),
          warrantyTerms: new UntypedFormControl(ticket.warrantyTerms)
        };
      }
      this.buildForm(this.form, formControls, this.group);
      this.defineGetters(formControls);
    }
  }

  canEditCategory(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_CATEGORY');
  }

}
