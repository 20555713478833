<div [formGroup]="currentFormGroup" class="grid align-items-center">
  <div class="col-12">
    <div class="grid justify-content-start">
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Invoice #" [control]="fcId">
          <input pInputText type="text"
                 placeholder="Invoice #"
                 id="id"
                 formControlName="id"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Ticket #" [control]="fcTicketId">
          <input pInputText type="text"
                 placeholder="Ticket #"
                 id="ticketId"
                 formControlName="ticketId"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="WO/PO/Sales" [control]="fcExternalReferenceCode">
          <input pInputText type="text"
                 placeholder="WO/PO/Sales"
                 id="externalReferenceCode"
                 formControlName="externalReferenceCode"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Contact" [control]="fcContact">
          <input pInputText type="text"
                 placeholder="Contact"
                 id="contact"
                 formControlName="contact"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Service date" [control]="fcServiceDate">
          <tj-datepicker [onlyFuture]="false"
                         [placeholder]="'Service date'"
                         id="serviceDate"
                         formControlName="serviceDate"
                         [ngClass]="fcServiceDate.value ? 'selected-item-bold' : ''"></tj-datepicker>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Comment" [control]="fcComment">
          <input pInputText type="text"
                 placeholder="Comment"
                 id="comment"
                 formControlName="comment"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Address" [control]="fcAddress">
          <input pInputText type="text"
                 placeholder="Address"
                 id="address"
                 formControlName="address"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Invoice date or range" [control]="fcIssueDate">
          <tj-date-range-picker formControlName="issueDate" dataType="string"
                                placeholder="Invoice date or range"></tj-date-range-picker>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Technician" [control]="fcTechnician">
          <tj-technician-combobox formControlName="technician"></tj-technician-combobox>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="Customer" [control]="fcCustomer">
          <input pInputText type="text"
                 placeholder="Customer"
                 id="customer"
                 formControlName="customer"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field label="3rd Party - None Account" [control]="fcPartner">
          <input pInputText type="text"
                 placeholder="3rd Party - None Account"
                 formControlName="partner"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12 flex flex-column relative">
        <label for="statusMultiSelect" class="fixed-label">Status</label>
        <p-multiSelect
          id="statusMultiSelect"
          [options]="configService.invoiceStatuses$ | async"
          formControlName="status"
          optionLabel="label"
          optionValue="value"
          [filter]="true"
          showClear="true"
          placeholder="Status"
          [style]="{ width: '250px', maxWidth: '100%' }"
          display="chip"
        >
        </p-multiSelect>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-4 col-12">
        <tj-field label="Industry / type" [control]="fcPartnerType">
          <tj-partner-type-combobox formControlName="partnerType"></tj-partner-type-combobox>
        </tj-field>
      </div>
      <div class="lg:col-6 md:col-6 sm:col-8 col-12" *tjHasRole="'PARTNER_VIEW'">
        <tj-field label="Account" [control]="fcPartnerId">
          <tj-partner-combobox formControlName="partnerId" [initPrivatePartner]="false"></tj-partner-combobox>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-4 col-12">
        <tj-field label="Payment Term">
          <p-dropdown [options]="nettermsTypes$ | async" placeholder="Payment Term"
                      formControlName="netTermType"></p-dropdown>
        </tj-field>
      </div>
    </div>
  </div>
</div>
