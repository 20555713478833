import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, Validator } from '@angular/forms';
import { ConfigService } from '../../../../core/services';
import { makeProvider } from '../../utils/abstract-control-value-accessor';

@Component({
  selector: 'tj-money-input',
  templateUrl: './money-input.component.html',
  styleUrls: ['./money-input.component.scss'],
  providers: [makeProvider(MoneyInputComponent),
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MoneyInputComponent),
      multi: true,
    }]
})
export class MoneyInputComponent implements ControlValueAccessor, Validator, AfterViewInit {

  @ViewChild('input') inputRef: ElementRef;

  @Input() disabled: boolean;

  @Output() onEnter = new EventEmitter();

  innerValue: any = '';

  private propagateChange;

  private onTouchedCallback;

  constructor(public config: ConfigService) {}

  ngAfterViewInit() {
    this.inputRef.nativeElement.value = this.innerValue;
  }

  onChange(e: Event, value: any) {
    this.innerValue = value;
    this.propagateChange(this.innerValue);
  }

  selectText() {
    this.inputRef.nativeElement.select();
  }

  onBlur() {
    this.onTouchedCallback();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(c: AbstractControl): { [key: string]: any; } {
    return (!c.valid) ? null : c.errors;
  }

  onEnterPress() {
    this.onEnter.emit();
  }
}
