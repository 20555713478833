import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {IdResponseModel} from '../model/shared/Response.model';
import {SignatureModel} from '../../main/shared/models/Signature.model';

@Injectable()
export class SignatureApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'tickets';

  getSignature(ticketId: number): Observable<SignatureModel> {
    const url = `${this.apiEndpoint}/${ticketId}/gallery/signature`;
    return this.requestService
      .get(url)
      .pipe(
        map((response: any) => response.response),
        catchError(this.handleError)
      );
  }

  public remove(galleryId: number, signatureId: number, ticketId: number): Observable<IdResponseModel> {
    const url = `${this.apiEndpoint}/${ticketId}/gallery/${galleryId}/signature/${signatureId}`;
    return this.requestService
      .remove(url)
      .pipe(
        map((response: IdResponseModel) => response),
        catchError(this.handleError)
      );
  }

  public uploadFile(file: File[], galleryId: number, ticketId: number): Observable<SignatureModel> {
    const url = `${this.apiEndpoint}/${ticketId}/gallery/signature/upload`;
    return this.requestService.upload(url, file, galleryId);
  }

  public updateSignature(ticketId: number, galleryId: number, signatureId: number, savedImage: File): Observable<SignatureModel> {
    const url = `${this.apiEndpoint}/${ticketId}/gallery/${galleryId}/signature/${signatureId}`;
    return this.requestService.upload(url, [savedImage], galleryId);
  }

}
