import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../api/service/api.service';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {ConfigService} from '../../../../core/services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'tj-invoices-print',
  templateUrl: './invoices-print.component.html',
  styleUrls: ['./invoices-print.component.scss']
})
export class InvoicesPrintComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  configs: { [key: string]: string };

  ids = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private api: ApiService,
              private configService: ConfigService,
              public layoutService: LayoutService) {

    this.layoutService.config.scale = 16;
    document.documentElement.style.fontSize = 16 + 'px';
    document.body.style.height = '100vh';
    document.body.style.margin = '0 !important';
    document.body.style.padding = '0 !important';
  }

  ngOnInit() {
    this.loadConfigs();
    this.route.queryParams.subscribe(params => {
      const idsParam = params.id;
      if (Array.isArray(idsParam)) {
        this.ids = idsParam.map(id => +id);
      } else if (idsParam) {
        this.ids = [+idsParam];
      } else {
        this.ids = [];
      }
    });
  }

  private loadConfigs() {
    this.configService.globalConfigs$.pipe(takeUntil(this.destroy$))
      .subscribe(globalConfigs =>
        this.configs = globalConfigs.reduce((acc, config) => {
          acc[config.code] = config.value;
          return acc;
        }, {})
      );
  }

  back() {
    window.history.back();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
