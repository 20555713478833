import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Customer} from '../../../../api/model/Customer.model';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {NotificationService} from '../../../../core/services';
import {Messages} from '../../../../common/messages';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'tj-ticket-edit-customer-modal',
  templateUrl: './ticket-edit-customer-modal.component.html',
  styleUrls: ['./ticket-edit-customer-modal.component.scss']
})
export class TicketEditCustomerModalComponent implements OnInit, OnDestroy {

  @Input('visible') visible: boolean;

  @Output() hide = new EventEmitter();

  @Output('onSave') onSaveEmitter = new EventEmitter();

  private destroy$ = new Subject<void>();

  form: UntypedFormGroup;

  originalCustomer: Customer;

  customer: Customer;

  originalMainContactPerson: ContactPerson;

  mainContactPerson: ContactPerson;

  constructor(private context: TicketEditContextService,
              private fb: UntypedFormBuilder,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
  }

  onShow(): void {
    this.form = this.fb.group({});
    this.context.customer
      .pipe(takeUntil(this.destroy$))
      .subscribe(customer => {
        if (customer) {
          this.originalCustomer = customer;
          this.customer = Customer.buildCopy(customer);
        }
      });

    this.context.mainCustomerContactPerson
      .pipe(takeUntil(this.destroy$))
      .subscribe(contactPerson => {
        this.originalMainContactPerson = contactPerson;
        this.mainContactPerson = contactPerson;
      });
  }

  onHide(): void {
    this.form = undefined;
    this.hide.emit();
  }

  save(): void {
    const formValue = this.form.value;
    if (this.form.valid) {
      const customer: Customer = formValue.customer;
      customer.id = this.customer.id;
      this.context.saveCustomer(customer)
        .subscribe(() => {
          this.onHide();
          this.notificationService.success(Messages.CUSTOMER_SAVED);
          this.onSaveEmitter.emit();
        });
    } else {
      FormHelpers.validateAllFormFields(formValue.customer);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
