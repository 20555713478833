import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {PartnerSearchFilterModel} from '../../../models/partner-search-filter.model';
import {BehaviorSubject} from 'rxjs';
import {DropdownModel} from '../../../../../api/model/shared/dropdown.model';
import {NettermTypeModel} from '../../../../../api/model/Netterm.model';
import {ConfigService} from '../../../../../core/services';

@Component({
  selector: 'tj-partner-search-form',
  templateUrl: './partner-search-form.component.html',
  styleUrls: ['./partner-search-form.component.scss']
})
export class PartnerSearchFormComponent implements OnInit, OnChanges {

  @Input() filter: PartnerSearchFilterModel;

  @Input() form: UntypedFormGroup;

  public readonly nettermsTypes$: BehaviorSubject<DropdownModel[]> = new BehaviorSubject<DropdownModel[]>([]);

  constructor(private fb: UntypedFormBuilder,
              private configService: ConfigService) {
  }

  ngOnInit() {
    this.getNettermTypes();
    const filter = this.filter || {} as PartnerSearchFilterModel;
    this.form.addControl('id', this.fb.control(filter.id || ''));
    this.form.addControl('name', this.fb.control(filter.name || ''));
    this.form.addControl('username', this.fb.control(filter.username || ''));
    this.form.addControl('email', this.fb.control(filter.email || ''));
    this.form.addControl('address', this.fb.control(filter.address || ''));
    this.form.addControl('deleted', this.fb.control(filter.deleted));
    this.form.addControl('netTermType', this.fb.control(filter.netTermType));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.currentValue === null) {
      this.filter = {} as PartnerSearchFilterModel;
    }
  }


  private getNettermTypes(): void {
    this.configService.nettermsTypes$.subscribe((nettermModels: NettermTypeModel[]) => {
      const nettermsTypes: DropdownModel[] = [];
      nettermsTypes.push({value: undefined, label: 'Not set'});
      nettermModels.forEach((nettermModel: NettermTypeModel) => {
        nettermsTypes.push({value: nettermModel.netTermType, label: nettermModel.name});
      });
      this.nettermsTypes$.next(nettermsTypes);
    });
  }


}
