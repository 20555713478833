<p-toolbar styleClass="mb-4" *ngIf="!forPrint">
  <ng-template pTemplate="left">
    <div class="my-2">
      <button
        *tjHasRole="'INVOICE_PRINT'"
        pButton
        type="button"
        label="Send email"
        (click)="sendEmail()"
        icon="pi pi-envelope"
        class="text-right mr-2"
      ></button>
      <button
        *tjHasRole="'PAYMENT_PROCESS'"
        pButton
        type="button"
        label="Pay"
        (click)="toPaymentPage()"
        icon="pi pi-credit-card"
        class="text-right mr-2"
        [ngStyle]="{'background-color': invoice.partner?.payment?.approvalRequired ? 'red' : 'var(--primary-color)'}"
      ></button>
      <button
        *tjHasRole="'INVOICE_PRINT'"
        pButton
        type="button"
        label="PDF"
        (click)="downloadPDF()"
        icon="pi pi-file-pdf"
        class="text-right mr-2"
      ></button>
      <button
        *tjHasRole="'INVOICE_PRINT'"
        pButton
        type="button"
        label="Print"
        (click)="print()"
        icon="pi pi-print"
        class="text-right"
      ></button>
    </div>
  </ng-template>

  <ng-template pTemplate="right" *ngIf="invoice.status !== 'ESTIMATE' && canEditStatus()">
    <span>Status:&nbsp;</span>
    <p-dropdown
      #invoiceStatus
      [options]="configService.invoiceStatuses$ | async"
      [ngModel]="invoice.status"
      placeholder="Select an status"
      (ngModelChange)="changeStatus($event, invoiceStatus)"
    ></p-dropdown>
  </ng-template>
</p-toolbar>

<div class="card py-8 px-6 md:px-8 overflow-auto">
  <div
    class="flex flex-column align-items-start md:flex-row md:align-items-center md:justify-content-between border-bottom-1 surface-border pb-5 min-w-max"
  >
    <div class="flex flex-column">
      <div class="my-3 text-4xl font-bold text-900">
        {{ config("COMPANY_NAME") }}
      </div>
      <span class="mb-2">{{ config("COMPANY_ADDRESS") }}</span>
      <span class="mb-2">Tel.: {{ config("COMPANY_PHONE") }}</span>
      <span class="mb-2">Fax: {{ config("COMPANY_FAX") }}</span>
      <span class="mb-2" *ngIf="config('COMPANY_WEBSITE')">Web:
        <a href="{{ config('COMPANY_WEBSITE') }}" target="_blank">{{
          config("COMPANY_WEBSITE")
        }}</a></span
      >
      <span class="mb-2"
        >Email:
        <a href="mailto:{{ config('COMPANY_EMAIL') }}">{{
          config("COMPANY_EMAIL")
        }}</a></span
      >
    </div>
    <div class="flex flex-column mt-5 md:mt-0">
      <div class="text-2xl font-semibold text-left md:text-right mb-3">
        {{ invoice.status !== 'ESTIMATE' ? 'INVOICE' : 'ESTIMATE'}}
      </div>
      <div class="flex flex-column">
        <div class="flex justify-content-between align-items-center mb-2">
          <span class="font-semibold mr-6">DATE</span>
          <span>{{ invoice.createdAt | date : configService.dateFormat }}</span>
        </div>
        <div class="flex justify-content-between align-items-center mb-2">
          <span class="font-semibold mr-6">INVOICE #</span>
          <span>{{ invoice.id }}</span>
        </div>
        <div class="flex justify-content-between align-items-center mb-2">
          <span class="font-semibold mr-6">TOTAL AMOUNT</span>
          <span>{{invoice.totalAmount | currency : 'USD'}}</span>
        </div>

        <div class="flex justify-content-between align-items-center mb-2">
          <span class="font-semibold mr-6">Payment term: </span>
          <span>{{ invoiceService.getPaymentTerm(invoice) }}</span>
        </div>

        <div class="flex justify-content-between align-items-center mb-2" *ngIf="invoice.netTerm">
          <span class="font-semibold mr-6">Net Term</span>
          <span>{{invoice.netTerm}} Day(s)</span>
        </div>

        <div class="flex justify-content-between align-items-center" *ngIf="invoice.status !== 'ESTIMATE'">
          <span class="font-semibold mr-6">STATUS</span>
          <span>{{ invoice.status }}</span>
        </div>

        <div
          class="flex justify-content-between align-items-center"
          *ngIf="invoice.partner && invoice.partner.paymentTerm"
        >
          <span class="font-semibold mr-6">PAYMENT METHOD</span>
          <span>{{ invoice.partner.paymentTerm }}</span>
        </div>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="
      invoice.partner && invoice.partner.name && invoice.partner.name != ''
    "
  >
    <div class="mt-5 mb-5 flex flex-column">
      <div class="mb-3 text-2xl font-medium">BILL TO</div>
      <span class="mb-2 font-medium">{{ invoice.partner.name }}</span>
      <span class="mb-2">Address: {{ invoice.partner.address }}</span>

      <ng-container *ngIf="partnerInvoiceReceiverContacts && partnerInvoiceReceiverContacts.length">
        <ng-container *ngFor="let contact of partnerInvoiceReceiverContacts">
          <div class="mb-2">
            <span class="mb-2">Contact Name: <strong>{{ contact.personName }}</strong></span>
            <div class="ml-3">
              <div *ngFor="let email of contact.email">
                <span class="mb-2">Email: {{ email }}</span>
              </div>
              <div *ngFor="let phone of contact.phone">
                <span class="mb-2">Phone: {{ phone }}</span>
              </div>
              <div *ngFor="let mobilePhone of contact.mobilePhone">
                <span class="mb-2">Mobile: {{ mobilePhone }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </ng-container>

  <div>
    <div class="border-round surface-border border-1">
      <ul class="list-none p-0 m-0">
        <li
          *ngFor="let item of invoice.items; let i = index"
          class="p-3 surface-border flex flex-column align-items-start sm:align-items-center"
          [ngClass]="{ 'border-bottom-2': i !== invoice.items.length - 1 }"
        >
          <div class="flex w-full word-break">
            <div class="w-4 border-right-1 surface-border">
              <div class="flex mb-3">
                <div class="w-4 font-medium">Ticket:</div>
                <div class="w-8 font-semibold">
                  <a [routerLink]="['/tickets', item.ticketId]">
                    {{ item.ticketId }}
                  </a>
                  <span *ngIf="item.externalReferenceCode"> / {{ item.externalReferenceCode }}</span>
                </div>
              </div>

              <div class="flex mb-3">
                <div class="w-4 font-medium">Customer:</div>
                <div class="w-8">
                  {{ item.mainCustomerContactPerson.name }}<br />{{
                    item.customer.address
                  }}
                </div>
              </div>
              <div class="flex mb-3">
                <div class="w-4 font-medium">Issued by:</div>
                <div class="w-8" *ngIf="item.mainPartnerContactPerson">
                  {{ item.mainPartnerContactPerson.name }}
                  <ul class="pl-0">
                    <li
                      class="flex align-items-center flex-wrap"
                      *ngFor="
                        let contact of item.mainPartnerContactPerson.contacts
                      "
                    >
                      <div class="w-8">{{ contact.value }}</div>
                    </li>
                  </ul>
                </div>

                <div class="w-8" *ngIf="!item.mainPartnerContactPerson && item.mainCustomerContactPerson">
                  {{ item.mainCustomerContactPerson.name }}
                  <ul class="pl-0">
                    <li
                      class="flex align-items-center flex-wrap"
                      *ngFor="
                        let contact of item.mainCustomerContactPerson.contacts
                      "
                    >
                      <div class="w-8">{{ contact.value }}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="flex flex-1 px-3 border-right-1 surface-border align-self-stretch flex-column"
            >
              <div class="font-medium mb-3">Service report</div>
              <span style="white-space: pre-line">
                 {{ item.report }}
                </span>
            </div>
            <div class="align-self-center mx-3">{{ item.totalAmount | currency : 'USD'}}</div>
          </div>
        </li>

        <li
          class="p-3 surface-border flex flex-column align-items-start sm:align-items-center"
        >
          <div class="flex w-full">

            <div class="border-top-1 border-top-3 font-bold py-3 surface-border text-lg text-right w-full">
              Total amount: {{ invoice.totalAmount | currency : 'USD'}}
            </div>
          </div>

      </ul>
    </div>
  </div>
</div>
<p-confirmDialog appendTo="body" key="confirm"></p-confirmDialog>
