import {Component, OnInit, ViewChild} from '@angular/core';
import {DropdownModel} from '../../../../api/model/shared/dropdown.model';
import {RoutesListComponent} from '../../components';
import {RouteListModel} from '../../../../api/model/route/route.line.model';
import {ApiService} from '../../../../api/service/api.service';
import {Page, PageQuery, SortQuery} from '../../../../api/model/shared/Page.model';
import {RouteFilter} from '../../../../api/model/route/route.filter';
import {NotificationService} from '../../../../core/services';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RouteSearchService} from '../../services/route-search.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tj-routes-page',
  templateUrl: './routes-page.component.html',
  styleUrls: ['./routes-page.component.scss']
})
export class RoutesPageComponent implements OnInit {

  protected readonly env = environment;

  @ViewChild(RoutesListComponent) routesListComponent: RoutesListComponent;

  routes: RouteListModel[] = [];

  page: Page<RouteListModel>;

  routeFilter: RouteFilter;

  routeId: number;

  private pageQuery: PageQuery = {
    size: 20,
    number: 0
  };

  private sortQuery: SortQuery = {
    property: 'id',
    direction: 'desc'
  };

  batchActions: DropdownModel[] = [
    {label: 'Delete', value: 'DELETE'},
  ];

  private searchQuery;

  constructor(private api: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private routeSearchService: RouteSearchService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.routeSearchService.listenQueryParams();
    this.routeSearchService.searchData
      .subscribe(({filters, searchQuery}) => {
        this.routeFilter = filters;
        this.searchQuery = searchQuery;
      });
    this.route.queryParams.subscribe((params: Params) => {
      this.pageQuery.number = params.page || 0;
      this.pageQuery.size = params.size || this.env.rowsPerPage[0];
      this.loadRoutes();
    });
    this.loadRoutes();
  }

  onGoToPage(event) {
    this.router.navigate([], {queryParams: {page: event.page, size: event.rows}, queryParamsHandling: 'merge'});
  }

  setBatchAction(event: any): void {
    const selectedRoutes = this.routesListComponent.getSelectedRoutes();
    switch (event.value) {
      case 'DELETE':
        const ids: number[] = selectedRoutes.map(it => it.id);
        this.deleteRoutes(ids);
        break;
    }
  }

  private loadRoutes() {
    this.api.routeApi.getRoutes(this.pageQuery, this.sortQuery, this.searchQuery)
      .subscribe((routesPage) => {
        this.page = routesPage;
        this.routes = routesPage.response;
      });
  }

  private deleteRoutes(ids: number[]) {
    this.api.routeApi.delete(ids)
      .subscribe(_ => {
        this.notificationService.success('Routes have been deleted');
        this.loadRoutes();
      });
  }

  sort($event: any) {
    this.sortQuery = {
      property: $event.field,
      direction: $event.order === -1 ? 'desc' : 'asc',
    };
    this.router.navigate([], {queryParams: this.sortQuery, queryParamsHandling: 'merge'});
  }

  showRoute(routeId: number) {
    this.routeId = routeId;
    this.router.navigate(['/routes', routeId]);
  }
}
