import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {ConfigService, TjKeycloakService} from '../../../../core/services';
import {TicketLine} from '../../../../api/model/TicketLine.model';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GalleryPreviewComponent} from '../../../shared/components';
import { LayoutService } from '../../../../layout/service/app.layout.service';
import {KeycloakService} from 'keycloak-angular';
import {Ticket} from '../../../../api/model/Ticket.model';


@Component({
  selector: 'tj-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  @Input() tickets: TicketLine[];

  selectedTickets: TicketLine[] = [];

  @Output('onSort') onSortEmitter = new EventEmitter();

  isMediaDialogActive = false;

  activeMediaDialogGalleryId = null;

  mediaDialogRef: DynamicDialogRef;

  isMobile = false;

  constructor(public configService: ConfigService,
              public dialogService: DialogService,
              private keycloakService: TjKeycloakService,
              private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.isMobile = this.layoutService.isMobile();
  }

  isPrivate(ticket): boolean {
    return ticket.ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE] ||
      ticket.ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE_BUSINESS];
  }

  sort($event: any) {
    this.onSortEmitter.emit($event);
  }

  openMediaDialog(ticketId: number, galleryId: number) {
    this.activeMediaDialogGalleryId = galleryId;
    this.isMediaDialogActive = true;

    this.mediaDialogRef = this.dialogService.open(GalleryPreviewComponent, {
      data: {
        id: this.activeMediaDialogGalleryId,
        ticketId,
      },
      header: `Ticket #${ticketId} gallery`
    });
  }

  public getSelectedTickets(): TicketLine[] {
    return this.selectedTickets;
  }

  public selectAllTickets() {
    this.selectedTickets = this.tickets;
    this.tickets.forEach(ticket => this.selectedTickets.push(ticket));
  }

  selectUnprinted(tickets: TicketLine[]) {
    this.selectedTickets = [];
    let filter = (it: TicketLine) => !it.printedByAdmin;
    if (this.keycloakService.hasRole('TICKET_PRINT_TECHNICIAN')) {
      filter = (it: TicketLine) => !it.printedByTechnician;
    } else if (this.keycloakService.hasRole('TICKET_PRINT_PARTNER')) {
      filter = (it: TicketLine) => !it.printedByPartner;
    }
    tickets
      .filter(filter)
      .forEach(ticket => this.selectedTickets.push(ticket));
  }

}
