import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {InvoiceService} from './service/invoice.service';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {AuthGuardService} from '../../core/services';
import {
  InvoiceComponent,
  InvoiceGridComponent,
  InvoiceListComponent,
  InvoicePrintComponent,
  InvoicesComponent,
  InvoiceSearchComponent,
  InvoiceSearchFormComponent,
  InvoiceSearchLabelsComponent,
  InvoicesPrintComponent,
  InvoiceStatsComponent,
  PartnerInvoiceListComponent
} from './components';
import {TicketTechniciansComponent} from '../ticket/components';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ClipboardModule} from '@angular/cdk/clipboard';


@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    AutoCompleteModule,
    ClipboardModule,
    RouterModule.forRoot([
      {
        path: 'invoice/:id',
        component: InvoiceComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['INVOICE_VIEW']
        }
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['INVOICE_VIEW'],
          breadcrumb: 'Invoices'
        }
      },
      {
        path: 'invoices/stats',
        component: InvoiceStatsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['INVOICE_STATS_VIEW_OWNED', 'INVOICE_STATS_VIEW'],
          breadcrumb: 'Invoice stats'
        }
      },
      {
        path: 'invoices/print',
        component: InvoicesPrintComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['INVOICE_EDIT', 'INVOICE_VIEW_OWNED'],
          standalonePage: true
        }
      }
    ])
  ],
  declarations: [
    TicketTechniciansComponent,
    InvoicesComponent,
    InvoiceListComponent,
    InvoiceSearchComponent,
    InvoiceSearchFormComponent,
    InvoiceComponent,
    InvoicePrintComponent,
    InvoicesPrintComponent,
    InvoiceStatsComponent,
    PartnerInvoiceListComponent,
    InvoiceSearchLabelsComponent,
    InvoiceGridComponent,
  ],
  providers: [
    InvoiceService
  ],
  exports: [
    TicketTechniciansComponent,
  ]
})
export class InvoiceModule {
}
