<div [formGroup]="form" class="grid align-items-center">
  <div class="col-12">
    <div class="grid justify-content-start">
      <div class="md:col-3 sm:col-12 col-12">
        <tj-field label="Account">
          <tj-partner-combobox formControlName="id" [initPrivatePartner]="false"></tj-partner-combobox>
        </tj-field>
      </div>
      <div class="md:col-3 sm:col-12 col-12">
        <tj-field label="Username">
          <input formControlName="username" pInputText placeholder="Username" type="text"/>
        </tj-field>
      </div>
      <div class="md:col-3 sm:col-12 col-12">
        <tj-field label="Email">
          <input formControlName="email" pInputText placeholder="Email" type="text"/>
        </tj-field>
      </div>
      <div class="md:col-2 sm:col-12 col-12">
        <tj-field label="Address">
          <input formControlName="address" pInputText placeholder="Address" type="text"/>
        </tj-field>
      </div>
      <div class="md:col-2 sm:col-12 col-12">
        <tj-field label="Payment Term">
          <p-dropdown [options]="nettermsTypes$ | async" placeholder="Payment Term"
                      formControlName="netTermType"></p-dropdown>
        </tj-field>
      </div>
      <div class="md:col-1 sm:col-6 private-checkbox-container mt-4 flex justify-content-start align-items-center">
        <p-checkbox binary="true" formControlName="deleted" label="Deleted"></p-checkbox>
      </div>
    </div>
  </div>
</div>
