import {TicketPartnerStatus} from '../../../api/status/ticket-partner.status';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ListSearchService} from '../../shared/services/list-search.service';
import {TicketFilter} from '../../../api/model/TicketFilter';

@Injectable()
export class TicketSearchService extends ListSearchService<TicketFilter> {

  constructor(route: ActivatedRoute,
              router: Router) {
    super(route, router);
  }

  searchByFormValue(formValue) {
    const filter: TicketFilter = {
      id: formValue.id,
      externalReferenceCode: formValue.externalReferenceCode,
      customer: formValue.customer,
      address: formValue.address,
      area: formValue.area,
      content: formValue.content,
      contact: formValue.contact,
      comment: formValue.comment,
      status: formValue.status,
      isPrivate: formValue.isPrivate,
      privateRegistered: formValue.privateRegistered,
      deleted: formValue.deleted,
      invoiceId: formValue.invoiceId,
      invoiceAmount: formValue.invoiceAmount,
      partnerId: formValue.partnerId,
      technicianId: formValue.technicianId,
      tags: formValue.tags && formValue.tags.map(v => v.id).join(','),
      serviceDate: formValue.serviceDate,
      createDate: formValue.createDate,
      contactDate: formValue.contactDate,
      category: formValue.category,
      notPrintedByAdmin: formValue.notPrintedByAdmin,
      notPrintedByTechnician: formValue.notPrintedByTechnician,
      notPrintedByPartner: formValue.notPrintedByPartner,
    };

    this.search(filter);
  }

  getSearchUrl() {
    return ['/tickets'];
  }

  getSearchConfig() {
    const t = this;
    return {
      id: {
        searchQueryPrefix: 'id:'
      },
      externalReferenceCode: {
        searchQueryPrefix: 'externalReferenceCode~'
      },
      customer: {
        searchQueryPrefix: 'customer.name~'
      },
      contact: {
        searchQueryPrefix: 'customer.contactPersons.contacts.value~'
      },
      address: {
        searchQueryPrefix: 'customer.address.addressFormatted~'
      },
      area: {
        searchQueryPrefix: 'customer.address.tag~'
      },
      serviceDate: {
        searchQueryPrefix: 'serviceDate..'
      },
      serviceDateBefore: {
        searchQueryPrefix: 'serviceDate<='
      },
      createDate: {
        searchQueryPrefix: 'createdAt:'
      },
      contactDate: {
        searchQueryPrefix: 'contactDate:'
      },
      partnerId: {
        searchQueryPrefix: 'partner.id:'
      },
      comment: {
        searchQueryPrefix: 'chats.comments.message~'
      },
      technicianId: {
        searchQueryPrefix: 'ticketToTechnicians.technician.id:'
      },
      invoiceAmount: {
        searchQueryPrefix: 'invoiceAmount:'
      },
      invoiceId: {
        searchQueryPrefix: 'invoices.invoice.id:'
      },
      content: {
        searchQueryPrefix: 'issue~'
      },
      status: {
        searchQueryPrefix: 'status:'
      },
      tag: {
        searchQueryPrefix: 'tag:'
      },
      category: {
        searchQueryPrefix: 'category~'
      },
      notPrintedByAdmin: {
        searchQueryPrefix: 'printedByAdmin!='
      },
      notPrintedByTechnician: {
        searchQueryPrefix: 'printedByTechnician!='
      },
      notPrintedByPartner: {
        searchQueryPrefix: 'printedByPartner!='
      },
      deleted: {
        searchQueryPrefix: 'active!='
      },
      isPrivate: {
        searchQueryPrefix: 'ticketPartnerStatus:',
        transform: (key, value) => {
          return value === 'true';
        },
        transformSearchQuery: (searchQueryPrefix, value) => {
          if (value === 'true') {
            return `${searchQueryPrefix}${TicketPartnerStatus[TicketPartnerStatus.PRIVATE]}`;
          }
        }
      },
      privateRegistered: {
        searchQueryPrefix: 'ticketPartnerStatus:',
        transform: (key, value) => {
          return value === 'true';
        },
        transformSearchQuery: (searchQueryPrefix, value) => {
          if (value === 'true') {
            return `${searchQueryPrefix}${TicketPartnerStatus[TicketPartnerStatus.PRIVATE_BUSINESS]}`;
          }
        }
      },
      tags: {
        type: 'array',
        searchQueryPrefix: 'ticketToTags.tag.id:',
        transform: (key, value) => {
          // t.configService.tags$.subscribe(value1 => {
          //   return value1.find(tag => tag.id === parseInt(value, 10));
          // });
          return value;
        }
      }
    };
  }
}
