import {
  Component,
  ElementRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { UserService } from '../core/services/user.service';
import { TjKeycloakService } from '../core/services/tj-keycloak.service';
import { User } from '../api/model/User.model';
import {TawkService} from '../main/shared/services';
import {ConfigService, NotificationService} from '../core/services';

@Component({
  selector: 'tj-topbar',
  templateUrl: './app.topbar.component.html',
  providers: [TawkService]
})
export class AppTopbarComponent implements OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  user: User;
  companyInfo: { code: string, value: string }[] = [];
  hideCompanyInfo = false;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private userService: UserService,
    private keycloakService: TjKeycloakService,
    private tawkService: TawkService,
    private serviceConfig: ConfigService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.userService.fetchCurrentUser().subscribe((user: User) => {
      this.user = user;
      this.tawkService.updateUser(user);
    });
    this.companyInfo = this.serviceConfig.getHeaderInfo();
    if (this.layoutService.isMobile()) {
      this.hideCompanyInfo = true;
    }
  }

  getValueByCode(code: string): string {
    const info = this.companyInfo.find(item => item.code === code);
    return info ? info.value : '';
  }

  get displayName() {
    const user = this.user;
    if (user.name) {
      return user.name;
    } else if (this.user.attributes?.['companyName']) {
      return this.user.attributes['companyName'];
    }
    return this.user.username;
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightMenu();
  }

  onSearchClick() {
    this.layoutService.toggleSearchBar();
  }

  logout() {
    this.keycloakService.logout();
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.info('Successfully copied.');
    } else {
      this.notificationService.error('Failed to copy.');
    }
  }

  back() {
    window.history.back();
  }
}
