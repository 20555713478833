import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ProgressBarService} from '../services/progressbar.service';
import {environment} from '../../../environments/environment';
import {HEADER_NAMES, HEADER_VALUES} from '../../api/endpoints/tj-request-headers';

@Injectable()
export class LoaderHttpInterceptor implements HttpInterceptor {

  constructor(private progressBarService: ProgressBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.get(HEADER_NAMES.REQUEST_TYPE) === HEADER_VALUES.REQUEST_TYPE.BACKGROUND) {
      return next.handle(req);
    }

    this.progressBarService.show();

    return next.handle(req)
      .pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.progressBarService.hide();
        }
      }, (error) => {
        this.progressBarService.hide();
      }));
  }
}
