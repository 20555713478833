import { Injectable } from '@angular/core';
import {Message, MessageService} from 'primeng/api';

export interface Notification {
  message: string;
  type: string;
}

@Injectable()
export class NotificationService {

  constructor(private messageService: MessageService) { }

  success(message: string, overrides: Message = null) {
    this.messageService.add({severity: 'success', summary: 'Success', life: 350, detail: message, ...overrides});
  }

  error(message: string, err: string = '', overrides: Message = null) {
    const finalMessage = message + ' ' + err;
    this.messageService.add({severity: 'error', summary: 'Error', detail: finalMessage, ...overrides});
  }

  warning(message: string, overrides: Message = null) {
    this.messageService.add({severity: 'warn', summary: 'Warning', detail: message, ...overrides});
  }

  warningCenter(message: string, overrides: Message = null) {
    this.messageService.add({severity: 'warn', summary: 'Warning', detail: message, key: 'center', ...overrides});
  }

  info(message: string, overrides: Message = null) {
    this.messageService.add({severity: 'info', summary: 'Info', detail: message, ...overrides});
  }

  copied() {
    this.messageService.add({severity: 'info', detail: 'Successfully copied'});
  }

  copyFailed() {
    this.messageService.add({severity: 'error', detail: 'Failed to copy'});
  }


}
