import {EventEmitter, Injectable} from '@angular/core';
import {TicketPartnerStatus} from '../../../api/status/ticket-partner.status';
import {ApiService} from '../../../api/service/api.service';
import {ConfigService, NotificationService} from '../../../core/services';
import {Ticket} from '../../../api/model/Ticket.model';
import {Partner} from '../../../api/model/Partner.model';
import {ContactPerson} from '../../../api/model/ContactPerson.model';
import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs/index';
import {Customer} from '../../../api/model/Customer.model';
import {catchError, map, tap} from 'rxjs/operators';
import {Tag} from '../../../api/model/Tag.model';

@Injectable()
export class TicketEditContextService {

  private ticket$: BehaviorSubject<Ticket> = new BehaviorSubject(null);

  public partner$: BehaviorSubject<Partner> = new BehaviorSubject(null);

  public customer$: BehaviorSubject<Customer> = new BehaviorSubject(null);

  private ticketPartnerStatus$: BehaviorSubject<TicketPartnerStatus> = new BehaviorSubject(null);

  public mainPartnerContactPerson$: BehaviorSubject<ContactPerson> = new BehaviorSubject(null);

  public mainCustomerContactPerson$: BehaviorSubject<ContactPerson> = new BehaviorSubject(null);

  public readonly ticket: Observable<Ticket> = this.ticket$.asObservable();

  public readonly partner: Observable<Partner> = this.partner$.asObservable();

  public readonly customer: Observable<Customer> = this.customer$.asObservable();

  public readonly ticketPartnerStatus: Observable<TicketPartnerStatus> = this.ticketPartnerStatus$.asObservable();

  public readonly mainPartnerContactPerson: Observable<ContactPerson> = this.mainPartnerContactPerson$.asObservable();

  public readonly mainCustomerContactPerson: Observable<ContactPerson> = this.mainCustomerContactPerson$.asObservable();

  public ticketGalleryEmmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ApiService,
              private notificationService: NotificationService,
              private configService: ConfigService) {
  }

  loadTicket(id): void {
    this.api.ticket
      .findOne(id)
      .subscribe((ticket: Ticket) => {
        this.propagateTicket(ticket);
      });
  }

  saveTicketAsPrivate() {
    return this.saveTicketNewPartnerStatus(TicketPartnerStatus.PRIVATE);
  }

  saveTicketAsRegisteredPartner() {
    return this.saveTicketNewPartnerStatus(TicketPartnerStatus.REGISTERED);
  }

  updateTicketPartnerStatus(partnerStatus: TicketPartnerStatus) {
    this.ticketPartnerStatus$.next(partnerStatus);
    const latestTicket = this.ticket$.getValue();
    const updateTicket = {
      ...latestTicket,
      ticketPartnerStatus: partnerStatus
    };
    this.ticket$.next(updateTicket as Ticket);
  }

  updateTicketPartnerMainContactPerson(mainContactPerson: ContactPerson) {
    const latestTicket = this.ticket$.getValue();
    const updateTicket = {
      ...latestTicket,
      mainPartnerContactPerson: mainContactPerson
    };
    this.ticket$.next(updateTicket as Ticket);
  }

  savePartner(partner: Partner): Observable<Partner> {
    const latestTicket = this.ticket$.getValue();
    partner.isPrivate = partner.id === undefined;
    return this.api.ticket.updatePartner(latestTicket.id, partner)
      .pipe(
        map((updatedPartner: Partner) => {
          this.partner$.next(updatedPartner);
          this.ticketPartnerStatus$.next(updatedPartner.isPrivate ? TicketPartnerStatus.PRIVATE_BUSINESS : TicketPartnerStatus.REGISTERED);
          return updatedPartner;
        }),
        catchError(errorMessage => {
          return observableThrowError(errorMessage);
        })
      );
  }

  saveCustomer(customer: Customer): Observable<Customer> {
    const latestTicket = this.ticket$.getValue();
    return this.api.ticket.updateCustomer(latestTicket.id, customer)
      .pipe(
        map((updatedCustomer: Customer) => {
          this.customer$.next(updatedCustomer);
          if (latestTicket.mainCustomerContactPerson.id) {
            const updatedContact = updatedCustomer.contactPersons.find(cp =>
              cp.id === latestTicket.mainCustomerContactPerson.id
            );
            if (updatedContact) {
              this.mainCustomerContactPerson$.next(updatedContact);
            }
          }
          return updatedCustomer;
        }),
        catchError(errorMessage => {
          return observableThrowError(errorMessage);
        })
      );
  }

  confirmCustomerAddress(ticketId, customerAddressConfirm: boolean): Observable<void> {
    return this.api.ticket.confirmCustomerAddress(ticketId, customerAddressConfirm)
      .pipe(
        tap(() => {
          this.ticket$.getValue().customer.addressConfirmed = customerAddressConfirm;
          this.notificationService.success('Customer address confirm successfully saved.');
        }),
        catchError(errorMessage => {
          return observableThrowError(errorMessage);
        })
      );
  }

  detachPartner(): Observable<void> {
    const latestTicket = this.ticket$.getValue();
    return this.api.ticket.detachPartner(latestTicket.id)
      .pipe(
        tap(() => {
          this.partner$.next(null);
          this.ticketPartnerStatus$.next(TicketPartnerStatus.PRIVATE);
          this.mainPartnerContactPerson$.next(null);
        }),
        catchError(errorMessage => {
          return observableThrowError(errorMessage);
        })
      );
  }

  changeStatus(status) {
    const ticket = this.ticket$.getValue();
    const statuses = this.configService.ticketStatuses$.getValue();
    const nextStatus = statuses.find(obj => obj.value === status);

    return this.api.ticket.changeStatus(ticket.id, nextStatus.value as string);
  }

  reschedule(reason: string, notifyRouteChange: boolean) {
    const ticket = this.ticket$.getValue();
    return this.api.ticket.reschedule(ticket.id, reason, notifyRouteChange);
  }

  cancel(reason: string, notifyRouteChange: boolean) {
    const ticket = this.ticket$.getValue();
    return this.api.ticket.cancel(ticket.id, reason, notifyRouteChange);
  }

  addTag(tag: Tag) {
    const ticket = this.ticket$.getValue();
    return this.api.ticket.addTag(ticket.id, tag.id)
      .pipe(
        tap(() => {
          this.loadTicket(ticket.id);
        }),
        catchError(errorMessage => {
          return observableThrowError(errorMessage);
      })
    );
  }

  removeTag(tag: Tag) {
    const ticket = this.ticket$.getValue();
    return this.api.ticket.removeTag(ticket.id, tag.id)
    .pipe(
      tap(() => {
        this.loadTicket(ticket.id);
      }),
      catchError(errorMessage => {
        return observableThrowError(errorMessage);
      })
    );
  }

  private propagateTicket(ticket) {
    this.ticket$.next(ticket);
    this.partner$.next(ticket.partner);
    this.customer$.next(ticket.customer);
    this.ticketPartnerStatus$.next(ticket.ticketPartnerStatus as TicketPartnerStatus);
    this.mainPartnerContactPerson$.next(ticket.mainPartnerContactPerson);
    this.mainCustomerContactPerson$.next(ticket.mainCustomerContactPerson);
  }

  private saveTicketNewPartnerStatus(partnerStatus) {
    const latestTicket = this.ticket$.getValue();
    const updateTicket = {
      ...latestTicket,
      ticketPartnerStatus: partnerStatus
    };
    return this.processTicketUpdate(updateTicket).pipe(
      tap((ticket) => {
        this.propagateTicket(ticket);
      }),
      catchError(error => {
        this.notificationService.error(error);
        return observableThrowError(error);
      })
    );
  }

  private processTicketUpdate(updatedTicket): Observable<Ticket> {
    return this.api.ticket.update(updatedTicket, this.ticket$.getValue().id);
  }
}
