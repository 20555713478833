import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Partner} from '../../../../../api/model/Partner.model';
import {ConfigService, TjKeycloakService} from '../../../../../core/services';
import {TicketPartnerStatus} from '../../../../../api/status/ticket-partner.status';

@Component({
  selector: 'tj-partner-edit-form',
  templateUrl: './partner-edit-form.component.html',
  styleUrls: ['./partner-edit-form.component.scss']
})
export class PartnerEditFormComponent implements OnInit, OnChanges {

  protected readonly TicketPartnerStatus = TicketPartnerStatus;

  @Input() partner: Partner;

  @Input() form: UntypedFormGroup;

  constructor(public configService: ConfigService,
              public fb: UntypedFormBuilder,
              private keycloakService: TjKeycloakService) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.updateForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.reset(this.partner);
    if (changes.partner) {
      this.updateForm();
    }
  }

  getControl(fcName: string): UntypedFormControl {
    return this.form.get(fcName) as UntypedFormControl;
  }

  private updateForm() {
    const usernameFc = this.getControl('username');
    if (!usernameFc) {
      return;
    }
    if (this.partner.id) {
      usernameFc.disable();
    } else {
      usernameFc.enable();
    }
  }

  private initializeForm(): void {
    const registeredPartnerDefaultNetTerm = this.configService.getRegisteredAccountDefaultNetTerm();
    this.form.addControl('name', this.fb.control(this.partner.name, [Validators.required]));
    this.form.addControl('email', this.fb.control(this.partner.email, [Validators.required]));
    this.form.addControl('username', this.fb.control(this.partner.username, [Validators.required]));
    this.form.addControl('password', this.fb.control(this.partner.password));
    this.form.addControl('combinedInvoice', this.fb.control(this.partner.combinedInvoice));
    this.form.addControl('printAllContactPersons', this.fb.control(this.partner.printAllContactPersons));
    this.form.addControl('copyByFax', this.fb.control(this.partner.copyByFax));
    this.form.addControl('dateOfServiceNotification', this.fb.control(this.partner.dateOfServiceNotification));
    this.form.addControl('notes', this.fb.control(this.partner.notes));
    this.form.addControl('netTerm', this.fb.control(this.partner.netTerm || registeredPartnerDefaultNetTerm || 15, [Validators.required]));
    if (this.keycloakService.hasRole('PARTNER_EDIT_INTERNAL_NOTE')) {
      this.form.addControl('internalNote', this.fb.control(this.partner.internalNote));
    }
    this.form.addControl('partnerType', this.fb.control(this.partner.partnerType, [Validators.required]));
  }

  canViewEditInternalNote() {
    return this.keycloakService.hasRole('PARTNER_VIEW_INTERNAL_NOTE') && this.keycloakService.hasRole('PARTNER_EDIT_INTERNAL_NOTE');
  }

  canViewInternalNote() {
    return this.keycloakService.hasRole('PARTNER_VIEW_INTERNAL_NOTE');
  }

}
