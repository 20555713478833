import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RouterModule} from '@angular/router';
import {AuthGuardService} from '../../core/services';
import {ConfigurationsComponent} from './components/configurations-component/configurations.component';
import {ConfigurationsPageComponent} from './pages/configurations-page/configurations-page.component';
import {TagConfigurationsComponent} from './components/tag-configurations-component/tag-configurations.component';
import {EditorModule} from 'primeng/editor';
import {ZipCodesConfigurationsComponent} from './components/zip-codes-configurations-component/zip-codes-configurations.component';
import {AreaConfigurationsComponent} from './components/area-configurations-component/area-configurations.component';
import {
  ZipCodeImportExampleComponent
} from './components/zip-codes-configurations-component/zip-code/zip-code-import-example/zip-code-import-example.component';
import {ZipCodeFormComponent} from './components/zip-codes-configurations-component/zip-code/zip-code-form/zip-code-form.component';
import {ZipCodeListComponent} from './components/zip-codes-configurations-component/zip-code/zip-code-list/zip-code-list.component';
import {AreaFormComponent} from './components/area-configurations-component/area-form/area-form.component';
import {AreaListComponent} from './components/area-configurations-component/area-list/area-list.component';
import {ChipsModule} from 'primeng/chips';
import {
  AreaToZipCodeListComponent
} from './components/area-to-zip-code configuration-component/area-to-zip-code/area-to-zip-code-list/area-to-zip-code-list.component';
import {
  ZipCodeChipsFormComponent
} from './components/zip-codes-configurations-component/zip-code/zip-code-chips-form/zip-code-chips-form.component';
import {
  ZipCodeInsertExampleComponent
} from './components/zip-codes-configurations-component/zip-code/zip-code-insert-example/zip-code-insert-example.component';
import {ZipCodesMenuComponent} from './components/zip-codes-configurations-component/zip-code/zip-code-menu/zip-codes-menu.component';
import {KeyFilterModule} from 'primeng/keyfilter';
import {
  ZipCodeCoveredInfoComponent
} from './components/zip-codes-configurations-component/zip-code/zip-code-covered-info/zip-code-covered-info.component';
import {AreaToZipCodePageComponent} from './components/area-to-zip-code configuration-component/page/area-to-zip-code-page.component';
import {
  AreaToZipCodeListFilterComponent
} from './components/area-to-zip-code configuration-component/area-to-zip-code/area-to-zip-code-list-filter/area-to-zip-code-list-filter.component';
import {AreaToZipCodeListFilterFormComponent} from './components/area-to-zip-code configuration-component/forms';
import {
  AreaToZipCodeSearchChipsComponent
} from './components/area-to-zip-code configuration-component/area-to-zip-code/area-to-zip-code-search-chips/area-to-zip-code-search-chips.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    EditorModule,
    SharedModule,
    AutoCompleteModule,
    RouterModule.forRoot([
      {
        path: 'configurations',
        component: ConfigurationsPageComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['GLOBAL_CONFIG_VIEW_MENU']
        },
        children: [
          {
            path: 'main',
            component: ConfigurationsComponent
          },
          {
            path: 'tags',
            component: TagConfigurationsComponent
          },
          {
            path: 'areas',
            component: AreaConfigurationsComponent
          },
          {
            path: 'area/zipCodes',
            component: AreaToZipCodePageComponent
          },
          // {
          //   path: 'zipcodes',
          //   component: ZipCodesConfigurationsComponent
          // }
        ]
      },
    ]),
    ChipsModule,
    KeyFilterModule,
  ],
  declarations: [
    AreaToZipCodeListComponent,
    AreaToZipCodeListFilterComponent,
    AreaToZipCodeListFilterFormComponent,
    AreaToZipCodeSearchChipsComponent,
    ZipCodeListComponent,
    AreaListComponent,
    ZipCodesMenuComponent,
    ConfigurationsComponent,
    TagConfigurationsComponent,
    AreaConfigurationsComponent,
    AreaToZipCodePageComponent,
    ZipCodeImportExampleComponent,
    ZipCodeCoveredInfoComponent,
    ZipCodeInsertExampleComponent,
    ZipCodesConfigurationsComponent,
    ConfigurationsPageComponent,
    AreaFormComponent,
    ZipCodeChipsFormComponent,
    ZipCodeFormComponent],
  exports: [
    ZipCodeCoveredInfoComponent
  ]
})
export class ConfigurationsModule {
}
