<ng-container>
  <ng-container *ngIf="useRegisteredContactPerson">
    <div class="grid lg:justify-content-center col-12">
      <div class="lg:col-5 col-12 flex justify-content-center">
        <div class="lg:col-8 col-12">
          <tj-contact-person-selector-form [form]="form"
                                           fieldName="mainPartnerContactPerson"
                                           [contactPerson]="mainContactPerson"
                                           [partner]="partner"
                                           (contactPersonChange)="onContactPersonChange($event)"
          ></tj-contact-person-selector-form>
        </div>
      </div>
      <div class="w-full lg:w-2">
        <p-divider layout="vertical" styleClass="lg:hidden lg:flex">
          <b>OR</b>
        </p-divider>
      </div>
      <div class="lg:col-5 col-12 flex justify-content-center">
        <div class="align-items-center flex">
          <button (click)="onUseNewContactPerson($event)" class="p-button-success" icon="pi pi-plus"
                  label="New Contact / Representative" pButton></button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!useRegisteredContactPerson">
    <div class="grid align-items-center">
      <div class="col-6">
        <button pButton (click)="onUseRegisteredContactPerson($event)" label="Use registered contact person">
        </button>
      </div>
    </div>
    <div class="grid align-items-center">
      <div class="col">
        <tj-single-contact-person-form [form]="form"
                                       [pdfReceiver]="configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')"
                                       [receiveNotificationsRequestedRep]="configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')"
                                       [receiveInvoiceRequestedRep]="configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')"
                                       [receivesCommentsIfRep]="configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')"
                                       [contactPerson]="mainContactPerson"
                                       group="mainPartnerContactPerson"></tj-single-contact-person-form>
      </div>
    </div>
  </ng-container>
</ng-container>
