<p-table *ngIf="vacations?.length > 0"
         (onSort)="onSort($event)"
         [value]="vacations"
         [autoLayout]="true"
         [customSort]="true"
         [responsive]="true"
         scrollable="scrollable"
         scrollHeight="400px"
         styleClass="p-datatable-gridlines p-datatable-striped">

  <ng-template pTemplate="header">
    <tr>
      <th class="text-center" pSortableColumn="startDate">Start date
        <p-sortIcon field="startDate"></p-sortIcon>
      </th>
      <th class="text-center" pSortableColumn="endDate">End date
        <p-sortIcon field="endDate"></p-sortIcon>
      </th>
      <th class="text-center">Deleted</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-vacation let-i="rowIndex">
    <tr>
      <td class="text-center">{{vacation.startDate | date:env.dateFormat}}</td>
      <td class="text-center">{{vacation.endDate | date:env.dateFormat}}</td>
      <td class="text-center">
        <button type="button" pButton pRipple icon="pi pi-trash" class="p-button-danger"
                (click)="onDeleteZipCode(i)"></button>
      </td>
    </tr>
  </ng-template>

</p-table>
