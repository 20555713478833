import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'tj-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {

  @Input() size = 12;
  @Input() label;
  @Input() control: UntypedFormControl;
  @Input() mode: string;

  @HostBinding('class') get classes () { return`small-${this.size} cell`; }

  constructor() { }

  ngOnInit() {

  }

  getMaxLengthMessageText(errors) {
    if (!errors || !errors.maxlength) {
      return '';
    }
    const maxLength = errors.maxlength.requiredLength;
    const actualLength = errors.maxlength.actualLength;
    return `Max length limit of ${maxLength} characters exceeded. Current length: ${actualLength}`;
  }
}
