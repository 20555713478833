<div class="grid justify-content-around flex-column">
  <ng-container *ngIf="!showAddressConfirm()">
    <div class="sm:col-12 mb-1">
      <p-checkbox label="Address confirmed?" (onChange)="onCustomerAddressConfirm($event)"
                  [(ngModel)]="customerAddressConfirm" binary="true" [disabled]="disabled"></p-checkbox>
    </div>
  </ng-container>
  <hr class="sm:col-12 m-0 mt-2 p-0 w-full">
  <div class="sm:col-12 py-2">
    <span class="font-bold" style="font-size: 16px;">Name: {{customer?.name}}</span>
  </div>
  <hr class="sm:col-12 m-0 p-0 w-full">
  <div class="sm:col-12" *ngIf="mainContactPerson">
    <tj-contact-person-details [contactPerson]="mainContactPerson"></tj-contact-person-details>
    <div *ngIf="secondaryContactPersons.length > 0">
      <button *ngIf="!showSecondaryContactPersons" pButton type="button" (click)="onShowSecondaryContactPersons()"
              icon="pi pi-angle-down" label="More contact persons available"
              class="p-button-link pl-0 p-button-sm"></button>
      <button *ngIf="showSecondaryContactPersons" pButton type="button" (click)="onShowSecondaryContactPersons()"
              icon="pi pi-angle-up" label="Hide additional contact persons"
              class="p-button-link pl-0 p-button-sm"></button>
    </div>
    <div class="mb-4" *ngIf="showSecondaryContactPersons && secondaryContactPersons.length > 0">
      <div *ngFor="let person of secondaryContactPersons" class="mt-2">
        <tj-contact-person-details *ngIf="!person.isMain" [contactPerson]="person"></tj-contact-person-details>
      </div>
    </div>

    <div class="mt-2 flex-row">
      <span class="font-bold" style="font-size: 16px;">Address: </span>
      <div class="ml-3">
        <tj-address-link [address]="customer.address"></tj-address-link>
      </div>
    </div>
    <div class="mt-2 flex-row" *ngIf="customer.address && customer.address.additionalAddress">
      <span class="font-bold" style="font-size: 16px;">Additional address / Notes:</span>
      <div class="ml-3">{{customer.address.additionalAddress}}</div>
    </div>

    <div class="mb-3" *ngIf="configService.isEnabledTerritory()">
      <tj-zip-code-covered-info [zipCode]="customerAddressZipCode"></tj-zip-code-covered-info>
    </div>
  </div>
  <div class="sm:col-12"
       *ngIf="configService.isEnabledTerritory() && coveredAreaCodesName != null && coveredAreaCodesName.length > 0">
    <div class="mt-2">
      <strong>Area codes / scheduler:
        <a [routerLink]="['/scheduler/calendar']"
           [queryParams]="ticketServiceDate ? { area: coveredAreaCodesId, ticketId: ticketId} : { area: coveredAreaCodesId}">
          {{coveredAreaCodesName}}
        </a>
      </strong>
    </div>
  </div>
</div>

