<div class="grid">
  <div class="col-12">
    <image-cropper
      [imageBase64]="image64"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      [outputType]="outputType"
      [imageQuality]="imageQuality"
      [format]="format"
      (imageCropped)="imageCropped($event)"

    ></image-cropper>
  </div>
  <div class="col-12">
    <div class="grid justify-content-center">
        <button class="mx-2" pButton label="Rotate Left" (click)="rotateLeft()"></button>
        <button class="mx-2" pButton label="Rotate Right" (click)="rotateRight()"></button>
        <button class="mx-2" pButton label="Crop"(click)="cropImage()" ></button>
    </div>
  </div>
</div>
