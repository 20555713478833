import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, NotificationService} from '../../../../core/services';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {PayFormEditModel} from '../../models';
import {Router} from '@angular/router';
import {Messages} from '../../../../common/messages';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import {IdResponseModel} from '../../../../api/model/shared/Response.model';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

dayjs.extend(utc);


@Component({
  selector: 'tj-pay-form-edit',
  templateUrl: './pay-form-edit.component.html',
  styleUrls: ['./pay-form-edit.component.scss']
})
export class PayFormEditComponent implements OnInit {

  @Input() private payFormId: number;

  _payForm: PayFormEditModel;

  form: UntypedFormGroup;

  isSaving = false;

  @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Input() set payForm(payForm: PayFormEditModel) {
    this._payForm = payForm;
  }

  get payForm() {
    return this._payForm;
  }

  constructor(private api: ApiService,
              private configService: ConfigService,
              private fb: UntypedFormBuilder,
              private router: Router,
              private notificationService: NotificationService) {
    this.form = this.fb.group({});
  }

  ngOnInit() {
  }

  save() {
    if (this.form.valid) {
      this.createPayForm().subscribe();
    } else {
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  private preparePayFormForSave() {
    const formValue = this.form.value;
    formValue.id = this.payFormId;
    return formValue as PayFormEditModel;
  }


  private createPayForm(isUpdate?: boolean): Observable<any> {
    this.isSaving = true;
    const payFormCreateModel: PayFormEditModel = this.preparePayFormForSave();
    payFormCreateModel.startDate = dayjs(payFormCreateModel.startDate).utc().format();
    payFormCreateModel.endDate = dayjs(payFormCreateModel.endDate).utc().format();
    return this.api.payForm.save(payFormCreateModel, this.payFormId).pipe(
      tap(value => {
        if (value.success) {
          this.isSaving = false;
          if (!isUpdate) {
            this.onUpdate.emit(value.response.id);
          }
          this.notificationService.success(Messages.PAY_FORM_CREATED);
        } else {
          this.isSaving = false;
          this.notificationService.error(Messages.PAY_FORM_NOT_CREATED);
        }
      })
    );
  }

  onTransfer($event: any) {
    const id = $event.payFormId;
    const payBalance = $event.payBalance;
    this.createPayForm(true).subscribe(() => {
        this.api.payForm.transfer(id, payBalance)
          .subscribe((response: IdResponseModel) => {
            if (response.success) {
              window.open('/payforms/' + response.response.id, '_parent');
              this.notificationService.success(Messages.PAY_FORM_TRANSFERRED);
            } else {
              this.notificationService.error(Messages.PAY_FORM_NOT_CREATED);
            }
          });
      }
    );
  }

  onPayInFull($event: any) {
    this.save();
  }

}
