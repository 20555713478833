<div class="layout-sidebar">
    <a [routerLink]="['/tickets']" class="logo">
        <div class="logo-image">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="70px" height="23px">
           <image  x="0px" y="0px" width="70px" height="23px"  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAAXCAQAAAAc9fFdAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfnBwYTAhhv5OWrAAACdklEQVRIx8WUTUhUURiG32EMTSGZpFqUGFJUhG0yF0kQURaUQRAG/QxhiBC1CaIWYe0SKjNbJEWLoUURBCX2SwoJFaaFQkUQFbbQLGlRaprZ08LLnfs3M2dGoe9s5p73fT+ee843VyjtlUcrtfbTogw6JFiZhK4B8JIqsjnGL2LM+l8wO4B39ALQx1S9pTxJ4jQvyJ05mAgV1q85ABQgjvMTZ50ITK6nC4COmYNpB3qoYw1twHZrt4BzTDhw2ljsSuVzyaFenBmYQ64TOOnSlhBzaMPstZVqBgH4a6vV04dZ6UJpCXCUccfhuEqYIh7az7+JWlcFq6cHE7KHFGCSnAS+LTyxXR9d0/QBEaIVgKHkg5wK5qbrXK4k9e6mB381W+qZ1IOcHOWgp3Fxyks9wGtPpsrWogDUW1d7ND2YVZ62dw2GXTx2Zf5Q4NDWMgCUIb4CJeYwWfR7YEoNUA57Ms89+jw6gU5rtoxh7nnadhmgzPdNzKkAV4utNprB1PnabjWAeeRLrQv0HbH18tQwm31N+w1QqnypN2Ql8FYyBsAAofhuCDkqrAWaVLHalSNJGtRZSbkq0Q3dUvKarW/KkyT9UIeeqU/d6tN4Qv9y3dYySTHtt/cctIW8Z9T1wbps9P+ZWtftVMwwkct9ACr91xTmk++Yo8YoGxyppjReoQGAPC/MA//Hk4XGTb84Us1pwIhaoNsNUx+A0mvcsMmR+k5NWjBiE3AhDrOPoDpv2KzC8r+igW1E0kQRopRRdk7B7AoAmWCEjUaN5vKUFmpYkQFEfBXSyNIQYe1RREOa0KSkEQ1rRGMa07j6ZVL5ytdnI2fyylbRP3YRSgdAaJOFAAAAAElFTkSuQmCC" />
           </svg>



        </div>
        <span class="app-name">Service Jan</span>
    </a>
    <div class="layout-menu-container">
        <tj-menu></tj-menu>
    </div>
</div>
