import {Component, Input, OnInit} from '@angular/core';
import {Tag} from '../../../../api/model/Tag.model';

@Component({
  selector: 'tj-ticket-tags',
  templateUrl: './ticket-tags.component.html',
  styleUrls: ['./ticket-tags.component.scss']
})
export class TicketTagsComponent implements OnInit {

  @Input() tags: Tag[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}
