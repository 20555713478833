import {Observable} from 'rxjs';

import {catchError, map, shareReplay} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CommunicationService} from '../service/communication.service';
import {Page, PageQuery, SortQuery} from '../model/shared/Page.model';
import {TechnicianMinimalListItem} from '../model/TechnicianMinimalListItem';
import {plainToClass} from 'class-transformer';
import {Technician} from '../model/Technician.model';
import {TechnicianTicketsResponse} from '../model/technician/technician.tickets.response';
import {AddressDetails} from '../model/Address.model';
import {TechnicianWorkLimits} from '../model/technician/technician.work.limits';

@Injectable()
export class TechnicianApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'technicians';

  findAll(
    pageQuery: PageQuery,
    sortQuery: SortQuery,
    filter?
  ): Observable<Page<TechnicianMinimalListItem>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter
    };
    const url = this.apiEndpoint;
    return this.requestService
      .get(url, {params})
      .pipe(
        map(response => response as Page<TechnicianMinimalListItem>),
        shareReplay(),
        catchError(this.handleError)
      );
  }

  findList(): Observable<TechnicianMinimalListItem[]> {
    const url = `${this.apiEndpoint}/list?sort=firstName&order=ASC`;
    return this.requestService.get(url).pipe(
      map(response => response as Page<TechnicianMinimalListItem>),
      map(response => response.response as TechnicianMinimalListItem[]),
      catchError(this.handleError));
  }

  findOne(id: number): Observable<Technician> {
    const url = `${this.apiEndpoint}/${id}`;
    return this.requestService.get(url).pipe(
      map(response => plainToClass(Technician, response.response as Object) as Technician),
      catchError(this.handleError));
  }

  getTechnicianTickets(technicianId: number, date: any): Observable<TechnicianTicketsResponse> {
    const url = `${this.apiEndpoint}/${technicianId}/tickets`;
    const params = {
      date: date,
    };
    return this.requestService.get(url, {params}).pipe(
      map(response => response.response as TechnicianTicketsResponse),
      catchError(this.handleError));
  }

  getTechnicianWorkLimits(ticketId: number | null, technicianIds: number[], date: any): Observable<TechnicianWorkLimits[]> {
    const url = `${this.apiEndpoint}/worklimits`;
    const params: Record<string, any> = {
      date: date,
      ids: technicianIds
    };

    if (ticketId) {
      params.ticketId = ticketId;
    }
    return this.requestService.get(url, {params}).pipe(
      map(response => response.response?.workLimitsList as TechnicianWorkLimits[]),
      catchError(this.handleError));
  }

  getAddress(id: number): Observable<AddressDetails> {
    const url = `${this.apiEndpoint}/${id}/address`;
    return this.requestService.get(url).pipe(
      map(response => plainToClass(AddressDetails, response.response as Object) as AddressDetails),
      catchError(this.handleError));
  }

  create(technician: Technician): Observable<Technician> {
    const url = this.apiEndpoint;
    return this.requestService.post(url, technician)
      .pipe(
        map(response => response.response as Technician),
        catchError(this.handleError)
      );
  }

  update(technician: Technician): Observable<Technician> {
    const url = this.apiEndpoint;
    return this.requestService.put(url, technician)
      .pipe(
        map(response => response.response as Technician),
        catchError(this.handleError)
      );
  }

  deactivate(id: number): Observable<void> {
    const url = `${this.apiEndpoint}/${id}`;
    return this.requestService.remove(url)
      .pipe(
        map(() => {
        }),
        catchError(this.handleError)
      );
  }

  recover(technicianId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${technicianId}/recover`;
    return this.requestService.put(url, {})
      .pipe(
        map(() => {
        }),
        catchError(this.handleError)
      );
  }

  hasOverdueTickets() {
    const url = this.apiEndpoint + '/me/configs';
    return this.requestService.get(url).pipe(
      map(response => response.response.hasOverdueTickets as boolean),
      catchError(this.handleError)
    );
  }
}
