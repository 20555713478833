import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'tj-route-directions',
  templateUrl: './route-directions.component.html',
  styleUrls: ['./route-directions.component.scss']
})
export class RouteDirectionsComponent implements OnInit {

  @Input() legs: google.maps.DirectionsLeg[];

  constructor() { }

  ngOnInit(): void {
  }

  // Convert duration in seconds to a more readable format
  formatDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hours ${minutes} minutes`;
  }

}
