import {PartnerMinimalListItem} from '../../../../api/model/PartnerLine.model';
import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-partner-combobox',
  templateUrl: './partner-combobox.component.html',
  styleUrls: ['./partner-combobox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PartnerComboboxComponent),
      multi: true
    }
  ]
})
export class PartnerComboboxComponent implements OnInit, ControlValueAccessor {
  @Output() partnerChange = new EventEmitter();
  @Input() initPrivatePartner = true;
  @Input() disabled = false;

  allPartners: PartnerMinimalListItem[] = [];

  filteredPartners;

  private onTouchedCallback: Function;

  private onChangeCallback: Function;

  innerValue = null;

  constructor(private configService: ConfigService) {
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    this.innerValue = v;
    const finalValue = v && v.id;
    if (this.onChangeCallback) {
      this.onChangeCallback(finalValue);
    }

    if (this.onPartnerChange) {
      this.onPartnerChange(finalValue);
    }
  }

  ngOnInit() {
    this.configService.partners$.subscribe(value => {
      this.allPartners = value;
    });
    if (this.disabled && this.allPartners && this.allPartners.length > 0) {
      this.value = this.allPartners[0];
      return;
    }
    if (this.initPrivatePartner) {
      const partners = this.allPartners;
      if (partners && partners.findIndex((value: PartnerMinimalListItem) => value.id === -1) === -1) {
        const newCP: PartnerMinimalListItem = new PartnerMinimalListItem();
        newCP.name = 'Private - 3rd Party/None Acct';
        newCP.id = -1;
        partners.unshift(newCP);
      }
    }

  }

  onPartnerChange(partnerId: number): void {
    this.partnerChange.emit(partnerId);
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(id: any): void {
    this.innerValue = this.allPartners.find(p => p.id === id);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onFilterSuggestions(event) {
    const query = event.query;
    const values = this.allPartners;
    const filtered: any[] = [];
    for (const value of values) {
      if (value.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(value);
      }
    }
    this.filteredPartners = filtered;
  }
}
