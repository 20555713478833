import {Contact} from './Contact.model';
import {Type} from 'class-transformer';

export class ContactPerson {

  id?: number;

  name?: string;

  notificationReceiver: boolean;

  invoiceReceiver: boolean;

  pdfReceiver: boolean;

  receiveNotificationsRequestedRep: boolean;

  receiveInvoiceRequestedRep: boolean;

  receivesComments: boolean;

  receivesCommentsIfRep: boolean;

  @Type(() => Contact)
  contacts: Contact[];

  notes: string;

  isMain: boolean;

}
