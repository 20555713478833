<div class="grid">
  <div class="col-12 text-center">
    <canvas width="400" height="400" id="canvas" class="mx-auto" style="border: 1px solid black"></canvas>
  </div>
  <div class="col-12">
    <div class="grid mb-4">
      <div class="lg:col-8 col-7">
        <input pInputText type="text" placeholder="Tag text" #input>
      </div>
      <div class="lg:col-4 col-3">
        <p-button (click)="addText(input.value)" class="p-button-secondary p-button-raised" label="Add text"></p-button>
      </div>
      <div class="lg:col-2 col-2">
        <p-colorPicker appendTo="body" [(ngModel)]="color"></p-colorPicker>
      </div>
    </div>
    <div class="grid ">
      <div class="col-12 flex">
        <div class="mr-2">
          <button (click)="addArrow()" class="p-button-secondary p-button-raised" label="Add arrow" pButton></button>
        </div>
        <div class="mr-2">
          <button (click)="addBefore()" class="p-button-secondary p-button-raised" label="Add before" pButton></button>
        </div>
        <div class="mr-2">
          <button (click)="addAfter()" class="p-button-secondary p-button-raised" label="Add after" pButton></button>
        </div>
        <div class="mr-2">
          <p-button (click)="removeSelectedObject()" class="ui-button-info p-button-raised"
                    label="Remove tag"></p-button>
        </div>
      </div>
      <div class="col-12">
        <p-button (click)="rotateImage(90)" class="p-button-warning p-button-raised" label="Rotate"
                  icon="pi pi-sync"></p-button>
      </div>

    </div>

  </div>
</div>
