import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseFormGroup } from '../../../shared/utils/base-form-group';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactPerson } from '../../../../api/model/ContactPerson.model';
import { Partner } from '../../../../api/model/Partner.model';

@Component({
  selector: 'tj-contact-person-selector-form',
  templateUrl: './contact-person-selector-form.component.html',
  styleUrls: ['./contact-person-selector-form.component.scss']
})
export class ContactPersonSelectorFormComponent extends BaseFormGroup implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;

  @Input() contactPerson: ContactPerson;

  @Input() partner: Partner;

  @Input() fieldName: string;

  @Output() contactPersonChange = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.form.addControl(this.fieldName, this.fb.control(this.contactPerson || null, Validators.required));
    this.form.get(this.fieldName).valueChanges.subscribe((value) => {
      this.contactPersonChange.emit(value);
    });
  }

  ngOnDestroy() {
    this.form.removeControl(this.fieldName);
  }

  get contactPersonControl() {
    return this.form.get(this.fieldName);
  }

  getContactPeople() {
    const contactPeople = this.partner?.contactPersons;
    if (!contactPeople) {
      return [];
    }
    contactPeople.sort((a, b) => a.name.localeCompare(b.name));
    return contactPeople;
  }
}
