import { catchError, map } from 'rxjs/operators';
import { Page, PageQuery, SortQuery } from '../model/shared/Page.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { CommunicationService } from '../service/communication.service';
import { PayFormEditModel, SentToPayformModel } from '../../main/payform/models';
import { IdResponseModel } from '../model/shared/Response.model';
import { PayFormList } from '../../main/payform/models/pay-form-list.model';
import { TicketFilter } from '../model/TicketFilter';

@Injectable()
export class PayFormApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint + 'payForm';

  findAll(
    pageQuery: PageQuery,
    sortQuery: SortQuery,
    filter: TicketFilter
  ): Observable<Page<PayFormList>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      // order: sortQuery.direction,
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter,
    };
    return this.requestService.get(`${this.apiEndpoint}`, { params }).pipe(
      map((response) => {
        return response as Page<PayFormList>;
      }),
      catchError(this.handleError)
    );
  }

  findOne(id: number): Observable<PayFormEditModel> {
    return this.requestService.get(this.apiEndpoint + '/' + id).pipe(
      map(
        (response) =>
          plainToClass(
            PayFormEditModel,
            response.response as Object
          ) as PayFormEditModel
      ),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<void> {
    return this.requestService.remove(this.apiEndpoint + '/' + id).pipe(
      map(
        (response) => {}
      ),
      catchError(this.handleError)
    );
  }

  save(
    payFormCreateUpdateModel: PayFormEditModel,
    payFormId: number
  ): Observable<IdResponseModel> {
    if (payFormId) {
      return this.update(payFormCreateUpdateModel);
    }
    return this.create(payFormCreateUpdateModel);
  }

  create(
    payFormCreateUpdateModel: PayFormEditModel
  ): Observable<IdResponseModel> {
    const url = this.apiEndpoint;
    return this.requestService.post(url, payFormCreateUpdateModel).pipe(
      map((response: IdResponseModel) => response),
      catchError(this.handleError)
    );
  }

  createNext(payFormId: number): Observable<IdResponseModel> {
    const url = `${this.apiEndpoint}/${payFormId}/next`;
    return this.requestService.post(url).pipe(
      map((response: IdResponseModel) => response),
      catchError(this.handleError)
    );
  }

  update(
    payFormCreateUpdateModel: PayFormEditModel
  ): Observable<IdResponseModel> {
    const url = this.apiEndpoint;
    return this.requestService.put(url, payFormCreateUpdateModel).pipe(
      map((response: IdResponseModel) => response),
      catchError(this.handleError)
    );
  }

  createForTicket(ticketId: number): Observable<SentToPayformModel[]> {
    const url = `${this.apiEndpoint}/ticket/${ticketId}`;
    return this.requestService.post(url).pipe(
      map((response) => {
        const result = [];
        if (Array.isArray(response.response)) {
          response.response.forEach((sentToPayformData) => {
            result.push(sentToPayformData as SentToPayformModel);
          });
        }
        return result;
      }),
      catchError(this.handleError)
    );
  }

  transfer(payFormId: number, transfer: number): Observable<any> {
    const url = `${this.apiEndpoint}/${payFormId}/transfer`;
    const body = {
      transfer,
    };
    return this.requestService.patch(url, body).pipe(
      map((response: IdResponseModel) => response),
      catchError(this.handleError)
    );
  }
}
