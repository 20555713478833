<ng-container *ngIf="invoice && configs">
  <div class="col-fixed page">
    <div class="grid">
      <div class="col-12 text-center mt-5">

        <ng-container *ngIf="configs['COMPANY_NAME']">
          <span class="font-bold">{{ configs["COMPANY_NAME"] }}</span> <br/>
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_ADDRESS']">
          <strong>Address: </strong> {{ configs["COMPANY_ADDRESS"] }} <br/>
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_PHONE']">
          <strong>Tel: </strong>{{ configs["COMPANY_PHONE"] }} &nbsp;
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_FAX']">
          <strong>Fax: </strong>{{ configs["COMPANY_FAX"] }} &nbsp; <br/>
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_WEBSITE']">
          <strong>Web: </strong>{{ configs["COMPANY_WEBSITE"] }} &nbsp;
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_EMAIL']">
          <strong>E-Mail: </strong>{{ configs["COMPANY_EMAIL"] }}
        </ng-container>

      </div>
      <hr/>
      <div class="w-full">
        <div class="flex align-items-end flex-column mt-5 md:mt-0">
          <div class="text-2xl font-semibold text-left md:text-right mb-3">
            {{ invoice.status !== 'ESTIMATE' ? 'INVOICE' : 'ESTIMATE'}}
          </div>
          <div class="flex flex-column">
            <div class="flex justify-content-between align-items-center mb-2">
              <span class="font-semibold mr-6">Total Amount: </span>
              <span>{{invoice.totalAmount | currency : 'USD'}}</span>
            </div>
            <div class="flex justify-content-between align-items-center mb-2">
              <span class="font-semibold mr-6">Invoice #: </span>
              <span>{{ invoice.id }}</span>
            </div>
            <div class="flex justify-content-between align-items-center mb-2">
              <span class="font-semibold mr-6">Invoice Date: </span>
              <span>{{ invoice.createdAt | date : configService.dateFormat }}</span>
            </div>
            <div class="flex justify-content-between align-items-center mb-2">
              <span class="font-semibold mr-6">Payment term: </span>
              <span>{{ invoiceService.getPaymentTerm(invoice) }}</span>
            </div>

            <div class="flex justify-content-between align-items-center mb-2" *ngIf="invoice.netTerm">
              <span class="font-semibold mr-6">Net Term</span>
              <span>{{invoice.netTerm}} Day(s)</span>
            </div>

            <div class="flex justify-content-between align-items-center" *ngIf="invoice.status != 'ESTIMATE'">
              <span class="font-semibold mr-6">Status: </span>
              <span>{{ invoice.status }}</span>
            </div>

            <div
              class="flex justify-content-between align-items-center"
              *ngIf="invoice.partner && invoice.partner.paymentTerm"
            >
              <span class="font-semibold mr-6">PAYMENT METHOD</span>
              <span>{{ invoice.partner.paymentTerm }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div>
      <ng-container *ngIf=" invoice.partner && invoice.partner.name && invoice.partner.name != '' ">
        <div class="mt-5 mb-5 flex flex-column">
          <div class="mb-3 text-2xl font-semibold">Bill To</div>
          <span class="mb-2 font-medium">{{ invoice.partner.name }}</span>
          <span class="mb-2">Address: {{ invoice.partner.address }}</span>

          <ng-container *ngIf="partnerInvoiceReceiverContacts && partnerInvoiceReceiverContacts.length">
            <ng-container *ngFor="let contact of partnerInvoiceReceiverContacts">
              <div class="mb-2">
                <span class="mb-2">Contact Name: <strong>{{ contact.personName }}</strong></span>
                <div class="ml-3">
                  <div *ngFor="let email of contact.email">
                    <span class="mb-2">Email: {{ email }}</span>
                  </div>
                  <div *ngFor="let phone of contact.phone">
                    <span class="mb-2">Phone: {{ phone }}</span>
                  </div>
                  <div *ngFor="let mobilePhone of contact.mobilePhone">
                    <span class="mb-2">Mobile: {{ mobilePhone }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </div>
        <hr/>
      </ng-container>
      <div>
        <div class="border-round surface-border border-1">
          <div class="table_component" role="region" tabindex="0">
            <table>
              <thead>
              <tr>
                <th class="col-3">Customer</th>
                <th class="col-3">Issued By</th>
                <th class="col-3">Ticket # / PO/WO/SALES</th>
                <th class="col-3">Date of service</th>
                <th class="col-3">Technician's name</th>
                <th class="col-8">Service Report</th>
                <th class="col-2"> Total Amount</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of invoice.items; let i = index">
                <td><span>{{item.customer.name}}</span><br/><span>{{item.customer.address.addressFormatted}}</span></td>
                <td><span>{{item.mainPartnerContactPerson?.name}}</span></td>
                <td style="word-break: break-all; text-align:center;">
                  <span>{{item.ticketId}}</span>
                  <span *ngIf="item.externalReferenceCode">  / {{item.externalReferenceCode}}</span>
                </td>
                <td>
                  <span *ngIf="item.serviceDate">{{item.serviceDate | date: configService.dateFormat}}</span><br/>
                  <span *ngIf="item.serviceTimeStart">{{item.serviceTimeStart | date: configService.dateTimeOnly}}
                    - </span>
                  <span *ngIf="item.serviceTimeEnd">{{item.serviceTimeEnd | date: configService.dateTimeOnly}}</span>
                </td>
                <td>
                  <span *ngFor="let technician of item.technicians; let j = index">
                    <ng-container *ngIf="!isInvoiceOwned()">
                      <span *ngIf="j < item.technicians.length-1">{{technician.name }}, </span>
                      <span *ngIf="j == item.technicians.length-1">{{technician.name }}</span>
                    </ng-container>
                      <ng-container *ngIf="isInvoiceOwned()">
                      <span *ngIf="j < item.technicians.length-1">{{technician.personnelCode }}, </span>
                      <span *ngIf="j == item.technicians.length-1">{{technician.personnelCode }}</span>
                    </ng-container>
                  </span>
                </td>
                <td class="serviceReport"><span style="white-space: pre-line">{{item.report}}</span></td>
                <td><span>{{ item.totalAmount | currency : 'USD' }}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div [innerHTML]="payInstruction"></div>
    <div class="col-12 pagebreak" *ngIf="pagebreak"></div>
  </div>

</ng-container>
