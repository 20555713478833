import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Page, SearchQuery} from '../model/shared/Page.model';
import {ZipCode, ZipCodeSearch} from '../model/ZipCode.model';
import {Injectable} from '@angular/core';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {plainToClass} from 'class-transformer';

@Injectable()
export class ZipCodeApi extends CommunicationFilterableService implements SearchQuery {

  protected apiEndpoint = this.env.apiEndpoint + 'zipcodetag';

  filterMapper = {
    zipCode: 'zipCode:',
    tag: 'tag:',
    travelFee: 'travelFee:'
  };

  searchZipCode(zipCode: string): Observable<ZipCodeSearch> {
    const url = `${this.apiEndpoint}/search/${zipCode}`;
    return this.requestService.get(url).pipe(
      map(response => plainToClass(ZipCodeSearch, response.response as Object) as ZipCodeSearch),
      catchError(this.handleError));
  }

  findList(): Observable<ZipCode[]> {
    return this.requestService.get(`${this.apiEndpoint}/list`).pipe(
      map(response => response as Page<ZipCode>),
      map(response => response.response as ZipCode[]),
      catchError(this.handleError));
  }


  create(zipCode: ZipCode): Observable<ZipCode> {
    const url = this.apiEndpoint;
    return this.requestService.post(url, zipCode)
      .pipe(
        map(response => response.response as ZipCode),
        catchError(this.handleError)
      );
  }

  update(id: number, zipCode: ZipCode): Observable<void> {
    const url = `${this.apiEndpoint}/${id}`;
    return this.requestService.put(url, zipCode)
      .pipe(
        catchError(this.handleError)
      );
  }

  export(): Observable<Blob> {
    const url = `${this.apiEndpoint}/export`;
    return this.requestService.download(url)
      .pipe(
        map(response => response),
        catchError(this.handleError));
  }

  import(file: File): Observable<Blob> {
    const url = `${this.apiEndpoint}/import`;
    return this.requestService.uploadCsv(url, file)
      .pipe(
        map(response => response),
        catchError(this.handleError));
  }

  delete(id: number): Observable<void> {
    const url = `${this.apiEndpoint}/${id}`;
    return this.requestService.remove(url).pipe(
      catchError(this.handleError)
    );
  }

  addInsertNewZipCodes(zipCodes: []) {
    const url = `${this.apiEndpoint}/insert`;
    return this.requestService.post(url, zipCodes)
      .pipe(
        catchError(this.handleError)
      );
  }
}
