<div *ngFor="let group of revision.groups; let last = last">
  <div class="p-badge p-badge-secondary">{{ group.user }}</div>
  - <span><b><i>{{ group.date | date : configService.dateFormatFull }}</i></b></span>
  <ul>
    <li *ngFor="let revision of group.revisions">
      <p>{{ revision.event }}<span *ngIf="revision.value" style="word-break: break-all; word-wrap: break-word;">: {{ revision.value}}</span></p>
    </li>
  </ul>
  <div *ngIf="!last">
    <hr>
  </div>
</div>
