<p-table #dt [value]="areas" [rows]="env.rowsPerPage[0]" [paginator]="true" [globalFilterFields]="['area']"
         [rowsPerPageOptions]="env.rowsPerPage"
         styleClass="p-datatable-gridlines p-datatable-striped"
         [rowHover]="true" dataKey="id"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [showCurrentPageReport]="true">

  <ng-template pTemplate="caption">
    <p-panel header="Filters">
      <div class="flex col-12">
        <div class="col-2">
          <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                  (click)="dt.clear()"></button>
        </div>
        <div class="col-5">
          <p-columnFilter type="text" field="name" placeholder="Name" [matchMode]="'contains'"></p-columnFilter>
        </div>
        <div class="col-5">
          <p-columnFilter type="text" field="code" placeholder="Code" [matchMode]="'contains'"></p-columnFilter>
        </div>
      </div>
    </p-panel>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="index">Index</th>
      <th pSortableColumn="name">Area name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="code">Area code
        <p-sortIcon field="code"></p-sortIcon>
      </th>
      <th pSortableColumn="zipCodes" *tjHasRole="'ZIP_CODE_EDIT'" class="flex align-items-center">Zip codes
      </th>
      <th style="width:75px" *tjHasRole="'ZIP_CODE_EDIT'"></th>
      <th style="width:75px" *tjHasRole="'ZIP_CODE_EDIT'"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-area let-i="rowIndex">
    <tr>
      <td>{{i + 1}}</td>
      <td>{{area.name}}</td>
      <td>{{area.code}}</td>
      <td class="flex w-full" *tjHasRole="'ZIP_CODE_EDIT'">
        <button pButton pRipple label="Edit Zip Codes" icon="pi pi pi-pencil" class="p-button-success mr-2 ml-2"
                (click)="onEditZipCodes(area)"></button>
      </td>
      <td class="text-center" *tjHasRole="'ZIP_CODE_EDIT'">
        <button *tjHasRole="'GLOBAL_CONFIG_EDIT'" pButton pRipple icon="pi pi-pencil"
                class="p-button-rounded p-button-success"
                (click)="onEditArea(area)"></button>
      </td>
      <td class="text-center" *tjHasRole="'ZIP_CODE_EDIT'">
        <button *tjHasRole="'GLOBAL_CONFIG_EDIT'" pButton pRipple icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                (click)="onDeleteArea(area)"></button>
      </td>
    </tr>
  </ng-template>

</p-table>
