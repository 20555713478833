import {AttachmentModel, ImageRequestModel} from '../models/ImageRequest.model';
import {jsUtils} from './Utils';
import {GalleryService} from '../services';
import {saveAs} from 'file-saver';
import * as JSZip from 'jszip';
import downloadFile = jsUtils.downloadFile;
import {ProgressBarService} from '../../../core/services/progressbar.service';

export class GalleryManager {

  readonly selectedAttachments: Set<AttachmentModel>;

  readonly galleryService: GalleryService;

  readonly progressBarService: ProgressBarService;

  constructor(galleryService: GalleryService, progressBarService: ProgressBarService) {
    this.galleryService = galleryService;
    this.selectedAttachments = new Set<ImageRequestModel>();
    this.progressBarService = progressBarService;
  }

  hasAttachment(value: AttachmentModel): boolean {
    return this.selectedAttachments.has(value);
  }

  downloadImageZipFile(ticketId: number): void {
    this.progressBarService.show();
    const zip = new JSZip(); // Create a new zip instance
    const imgFolder = zip.folder('images'); // Create a folder inside the zip

    // Convert the Set to an array and iterate over selectedAttachments
    const promises = Array.from(this.selectedAttachments).map((img: AttachmentModel) => {
      const imageFileName = img.fileName ? img.fileName.replace(/\./g, '') + '_' + img.index : 'Ticket_attachment';

      // Fetch the image as a blob and add it to the zip folder
      return fetch(this.galleryService.getImageUrl((img as ImageRequestModel).cropPath?.split('?v=')[0], {resized: true}))
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to fetch image: ${img.fileName}`);
          }
          return response.blob();
        })
        .then(blob => {
          // Ensure the file is added to the folder
          imgFolder?.file(imageFileName + img.extension, blob); // Add the image to the zip
        })
        .catch(error => {
          console.error(`Error downloading image ${imageFileName}:`, error);
        });
    });

    Promise.all(promises)
      .then(() => {
        return zip.generateAsync({type: 'blob'});
      })
      .then(content => {
        saveAs(content, ticketId + '_images.zip');
        this.progressBarService.hide();
      })
      .catch(error => {
        this.progressBarService.hide();
        console.error('Error generating zip file:', error);
      });
  }

  downloadAll(fileType: AllowedFileTypes) {
    switch (fileType) {
      case 'IMAGE':
        this.selectedAttachments.forEach(img => {
          const imageFileName = img.fileName ? img.fileName.replace(/\./g, '') + '_' + img.index : 'Ticket_attachment';
          downloadFile(this.galleryService.getImageUrl((img as ImageRequestModel).cropPath?.split('?v=')[0], {}), imageFileName, this.progressBarService);
        });
        break;
      case 'VIDEO':
        this.selectedAttachments.forEach(video => {
          const videoName: string = video.fileName + '_' + video.index + video.extension;
          downloadFile(video.url, videoName, this.progressBarService);
        });
        break;
      case 'AUDIO':
        this.selectedAttachments.forEach(audio => {
          const audioName: string = audio.fileName + '_' + audio.index + audio.extension;
          downloadFile(audio.url, audioName, this.progressBarService);
        });
        break;
      case 'DOCUMENT':
        this.selectedAttachments.forEach(document => {
          // const name: string = document.originalFileName.split('.').slice(0, -1).join('.');
          const documentName: string = document.fileName + '_' + document.index + document.extension;
          downloadFile(document.url, documentName, this.progressBarService);
        });
        break;
    }
  }

  add(model: AttachmentModel): void {
    this.selectedAttachments.add(model);
  }

  delete(model: AttachmentModel) {
    this.selectedAttachments.delete(model);
  }

  getIds(): number[] {
    return Array.from(this.selectedAttachments).map(value => value.id);
  }

  clear(): void {
    this.selectedAttachments.clear();
  }
}
