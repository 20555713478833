<p-table *ngIf="areaToZipCodes"
         (onSort)="sort($event)"
         [rowsPerPageOptions]="env.rowsPerPage"
         [rows]="env.rowsPerPage[0]"
         [value]="areaToZipCodes"
         [autoLayout]="true"
         [customSort]="true"
         [responsive]="true"
         styleClass="p-datatable-gridlines p-datatable-striped">

  <ng-template pTemplate="header">
    <tr>
      <th>
        Active
      </th>
      <th pSortableColumn="zipCode.zipCode">Zip Code
        <p-sortIcon field="zipCode"></p-sortIcon>
      </th>
      <th pSortableColumn="zipCode.travelFee">Additional Travel Fee
        <p-sortIcon field="travelFee"></p-sortIcon>
      </th>
      <th pSortableColumn="area.code">Area Code
        <p-sortIcon field="areaCode"></p-sortIcon>
      </th>
      <th style="width:75px" *tjHasRole="'ZIP_CODE_EDIT'"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-areaToZipCode>
    <tr>
      <td style="text-align: center">
        <ng-container *ngIf="areaToZipCode.active">
          <i class="pi pi-check" style="color: var(--primary-color); font-size: 1.3rem"></i>
        </ng-container>
        <ng-container *ngIf="!areaToZipCode.active">
          <i class="pi pi-minus" style="color: red;font-size: 1.3rem"></i>
        </ng-container>

      </td>
      <td>{{areaToZipCode.zipCode}}</td>
      <td>{{areaToZipCode.travelFee | currency: 'USD'}}</td>
      <td>{{areaToZipCode.areaCode}}</td>
      <td class="text-center" *tjHasRole="'ZIP_CODE_EDIT'">
        <button *tjHasRole="'GLOBAL_CONFIG_EDIT'" pButton pRipple icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                (click)="onDeleteZipCode(areaToZipCode)"></button>
      </td>
    </tr>
  </ng-template>

</p-table>
