import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AddressDetails } from '../../../../api/model/Address.model';
import { MapService } from '../../services/map.service';

const DEFAULT_LAT = 40.73061;
const DEFAULT_LNG = -73.935242;

@Component({
  selector: 'tj-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  providers: [MapService],
})
export class AddressSearchComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() showMap = true;

  @Input() address: AddressDetails;

  @Input() mapHeight = 200;

  @Input() disabled = false;

  @Output() addressChange = new EventEmitter();

  @Output() incompleteAddress = new EventEmitter();

  @ViewChild('search') public searchElementRef: ElementRef;

  latestAddress: AddressDetails;

  coords: { lat: number; lng: number } = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };

  constructor(private ngZone: NgZone, private mapService: MapService) {}

  ngAfterViewInit(): void {
    this.initialize();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.searchElementRef &&
      changes.address &&
      (changes.address.currentValue || changes.address.firstChange)
    ) {
      this.initialize();
    }
  }

  initialize() {
    const element = this.searchElementRef.nativeElement;
    if (this.address) {
      this.latestAddress = this.address;
      this.mapService.findAddressLatLng(this.address).then(({ lng, lat }) => {
        this.coords.lng = lng;
        this.coords.lat = lat;
      });
    }

    element.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });

    const autocomplete = this.mapService.getAutocompleteForElement(element, {
      types: ['address'],
    });

    autocomplete.addListener('place_changed', () => {
      this.detectPlace(autocomplete);
    });
  }

  detectPlace(autocomplete) {
    this.ngZone.run(() => {
      const place = autocomplete.getPlace();
      this.latestAddress = this.mapService.parsePlaceToAddress(place);
      const { lng, lat } = this.mapService.findCoordsByPlace(place);
      this.coords.lng = lng;
      this.coords.lat = lat;
      this.addressChange.emit(this.latestAddress);
    });
  }

  public getAddress(): string {
    return this.searchElementRef.nativeElement.value;
  }
}
