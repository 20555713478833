<p-table *ngIf="routes"
         (onSort)="sort($event)"
         [(selection)]="selectedRoutes"
         [responsive]="true"
         [value]="routes">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="id">Route
        <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th pSortableColumn="technicianId">Technician
        <p-sortIcon field="technicianId"></p-sortIcon>
      </th>
      <th pSortableColumn="date">Date
        <p-sortIcon field="date"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template let-route pTemplate="body">
    <tr [pSelectableRow]="route">
      <td>
        <p-tableCheckbox [value]="route"></p-tableCheckbox>
      </td>
      <td>
        <a [routerLink]="['/routes', route.id]">{{route.id}}</a>
      </td>
      <td>
        <a [routerLink]="['/routes', route.id]">{{route.technicianName}}</a>
      </td>
      <td>
        {{route.date | date: config.dateFormat}}
      </td>
    </tr>
  </ng-template>
</p-table>
