import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Contact} from '../../../../api/model/Contact.model';
import {ConfigService} from '../../../../core/services';
import {ContactType} from '../../../shared/enums/contact.type';
import {Observable} from 'rxjs';


@Component({
  selector: 'tj-contact-persons-form',
  templateUrl: './contact-persons-form.component.html',
  styleUrls: ['./contact-persons-form.component.scss']
})
export class ContactPersonsFormComponent extends BaseFormGroup implements OnInit, OnChanges {

  @Input() contactPersons: ContactPerson[] = [];

  @Input() mainContactPerson: ContactPerson;

  @Input() requiredOne = true;

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  @Input() canChangeMainContactPerson = true;

  @Input() receivedByDefaultEnabled = false;

  @Input() receiveNotificationsRequestedRep = false;

  @Input() notificationReceiver = false;

  @Input() invoiceReceiver = false;

  @Input() pdfReceiver = false;

  @Input() receiveInvoiceRequestedRep = false;

  @Input() receivesComments = false;

  @Input() receivesCommentsIfRep = false;

  @Input() canChangeReceivers = true;

  @Input() maxContactPersons = 35;

  @Input() nameObs: Observable<string>;

  @Input() minCount = 1;

  formControls;

  constructor(private fb: UntypedFormBuilder,
              public configService: ConfigService) {
    super();
  }

  ngOnInit(): void {
    this.form.removeControl('contactPersons');
    const initialContactPerson = this.requiredOne ? [this.generateEmptyMainContactPerson()] : [];
    const contactPersons = this.contactPersons.length ? this.generateContactPersonsFormArray(this.contactPersons)
      : initialContactPerson;
    this.formControls = {
      'contactPersons': this.fb.array(contactPersons)
    };
    this.buildForm(this.form, this.formControls, this.group);
    this.defineGetters(this.formControls);
    this.recheckIsMainSelection();
    this.startObservables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // todo refactor this workaround
    if (changes.contactPersons && changes.contactPersons.currentValue.length) {
      this.ngOnInit();
    }
  }

  startObservables() {
    const persons = this.form.get('contactPersons') as UntypedFormArray;
    if (this.nameObs) {
      this.nameObs.subscribe(newName => {
        persons.controls.forEach((cp: UntypedFormGroup) => {
          if (cp.get('isMain').value) {
            cp.get('name').setValue(newName);
          }
        });
      });
    }
  }

  onAddContactPerson() {
    const contactPersons: UntypedFormArray = this.formControls['contactPersons'] as UntypedFormArray;
    contactPersons.push(this.generateContactPerson());
    this.recheckIsMainSelection();
  }

  onRemoveContactPerson(contactPersonIndex) {
    const contactPersons: UntypedFormArray = this.formControls['contactPersons'] as UntypedFormArray;
    contactPersons.removeAt(contactPersonIndex);
    this.recheckIsMainSelection();
  }

  onRemoveContact(contactPersonIndex, contactIndex) {
    const contactPersons: UntypedFormArray = this.formControls['contactPersons'] as UntypedFormArray;
    const contactPerson: UntypedFormGroup = contactPersons.at(contactPersonIndex) as UntypedFormGroup;
    const contacts: UntypedFormArray = contactPerson.controls['contacts'] as UntypedFormArray;
    contacts.removeAt(contactIndex);
  }

  onAddContact(contactPersonIndex) {
    const contactPersons: UntypedFormArray = this.formControls['contactPersons'] as UntypedFormArray;
    const contactPerson: UntypedFormGroup = contactPersons.at(contactPersonIndex) as UntypedFormGroup;
    const contacts: UntypedFormArray = contactPerson.controls['contacts'] as UntypedFormArray;
    contacts.push(this.generateContactControl());
  }

  onMainContactPersonChange(index) {
    const contactPersonsFormArray: AbstractControl[] = this.currentFormGroup.get('contactPersons')['controls'] as AbstractControl[];
    contactPersonsFormArray.forEach((contactPerson, contactPersonIndex) => {
      if (contactPersonIndex !== index) {
        contactPerson.get('isMain').setValue(false);
      }
    });
    this.recheckIsMainSelection();
  }

  private recheckIsMainSelection() {
    const contactPersonsFormArray: UntypedFormArray = this.currentFormGroup.get('contactPersons') as UntypedFormArray;

    // adding second contact person will enable head selection control for the first one
    if (contactPersonsFormArray.length > 1) {
      // contactPersonsFormArray.at(0).get('isMain').enable();
    } else if (contactPersonsFormArray.length !== 0) {
      contactPersonsFormArray.at(0).get('isMain').setValue(true, {emitEvent: false});
      // contactPersonsFormArray.at(0).get('isMain').disable();
    } else {
      return;
    }
  }

  private generateContactsFormArray(contacts = []) {
    if (contacts.length === 0) {
      contacts = [{type: ContactType.EMAIL, value: ''} as Contact];
    }
    return this.fb.array(contacts.map((contact: Contact) => {
      return this.generateContactControl(contact.type, contact.value, contact.id);
    }));
  }

  private generateContactControl(type = null, value = '', id?) {
    return this.fb.group({
      id: this.fb.control(id),
      type: this.fb.control(type, Validators.required),
      value: this.fb.control(value, type === "EMAIL" ? [Validators.required, Validators.email] : Validators.required)
    });
  }

  private generateContactPersonsFormArray(contactPersons) {
    if (!contactPersons || !contactPersons.length) {
      return [];
    }
    return contactPersons.map((contactPerson: ContactPerson) => {
      const contacts = contactPerson.contacts;
      return this.generateContactPerson({
        id: contactPerson.id,
        name: contactPerson.name,
        notes: contactPerson.notes,
        notificationReceiver: contactPerson.notificationReceiver,
        invoiceReceiver: contactPerson.invoiceReceiver,
        pdfReceiver: contactPerson.pdfReceiver,
        receiveNotificationsRequestedRep: contactPerson.receiveNotificationsRequestedRep,
        receiveInvoiceRequestedRep: contactPerson.receiveInvoiceRequestedRep,
        receivesComments: contactPerson.receivesComments,
        receivesCommentsIfRep: contactPerson.receivesCommentsIfRep,
        contacts
      });
    });
  }

  private generateContactPerson({
                                  id = null,
                                  name = null,
                                  notes = null,
                                  contacts = [],
                                  isMain = null,
                                  notificationReceiver = this.notificationReceiver,
                                  invoiceReceiver = this.invoiceReceiver,
                                  pdfReceiver = this.pdfReceiver,
                                  receiveNotificationsRequestedRep = this.receiveNotificationsRequestedRep,
                                  receiveInvoiceRequestedRep = this.receiveInvoiceRequestedRep,
                                  receivesComments = this.receivesComments,
                                  receivesCommentsIfRep = this.receivesCommentsIfRep,
          } = {}) {
    const isMainContactPerson = isMain === null ? this.mainContactPerson?.id === id : isMain;
    return this.fb.group({
      id: this.fb.control(id),
      name: this.fb.control(name, [Validators.required]),
      notes: this.fb.control(notes),
      contacts: this.generateContactsFormArray(contacts),
      isMain: this.fb.control(isMainContactPerson),
      notificationReceiver: this.fb.control(notificationReceiver),
      invoiceReceiver: this.fb.control(invoiceReceiver),
      pdfReceiver: this.fb.control(pdfReceiver),
      receiveNotificationsRequestedRep: this.fb.control(receiveNotificationsRequestedRep),
      receiveInvoiceRequestedRep: this.fb.control(receiveInvoiceRequestedRep),
      receivesComments: this.fb.control(receivesComments),
      receivesCommentsIfRep: this.fb.control(receivesCommentsIfRep),
    });
  }

  private generateEmptyMainContactPerson() {
    return this.generateContactPerson({
      isMain: true,
      notificationReceiver: this.receivedByDefaultEnabled,
      pdfReceiver: this.receivedByDefaultEnabled,
      invoiceReceiver: this.receivedByDefaultEnabled,
      receiveNotificationsRequestedRep: this.receiveNotificationsRequestedRep,
      receiveInvoiceRequestedRep: this.receiveInvoiceRequestedRep,
      receivesComments: this.receivesComments,
      receivesCommentsIfRep: this.receivesCommentsIfRep,
    });
  }

  public onKeyDown(event: KeyboardEvent, type?: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    if (type === 'EMAIL' && (event.key === ' ' || event.key === ',')) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent, formGroup: UntypedFormGroup) {
    const pastedText = event.clipboardData.getData('text/plain');
    const trimmedText = pastedText.trim();
    formGroup.get('value').setValue(trimmedText);
    event.preventDefault();
  }
}
