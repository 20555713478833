<form [formGroup]="form">
  <div formArrayName="weeklyOffs">
    <header>
      <div class="col-12 flex justify-content-center align-items-center custom-header-style p-0"
           *ngIf="weeklyOffArrayForm.controls.length > 0">
        <span class="col-2 text-center custom-title-style">Day of the week</span>
        <span class="col-2 text-center custom-title-style">Whole day</span>
        <span class="col-2 text-center custom-title-style">Start not working time</span>
        <span class="col-2 text-center custom-title-style">End not working time</span>
        <span class="col-2 text-center custom-title-style">Time Add / Delete</span>
        <span class="col-2 text-center custom-title-style">Day Delete</span>
      </div>
    </header>
    <div class="custom-body-style">
      <div *ngFor="let weeklyOffGroup of weeklyOffArrayForm.controls; let i = index; " class="col-12 pt-2 p-0 ">
        <div [formGroupName]="i"
             class="col-12 p-0 flex custom-border-bottom-style justify-content-center align-items-center">
          <div class="col-2 text-center">
            <p-dropdown [options]="weekDays" formControlName="weekDay"
                        appendTo="body"
                        placeholder="Week Day"
                        class="select-item-bold-style"
                        [disabled]="isWeekDayDisabled(weeklyOffGroup.get('weekDay').value)"></p-dropdown>
          </div>
          <ng-container formGroupName="timeOff">
            <div class="col-2">
              <p-checkbox class="ml-2" formControlName="wholeDay" label="Whole Day" binary="true"
                          (onChange)="onWholeDayChange(weeklyOffGroup)"></p-checkbox>
            </div>
            <div class="col-6 grid mr-0 ml-0 flex-wrap justify-content-center custom-border-l-r-style"
                 style="min-height: 89.76px;">
              <ng-container formArrayName="timeRange">
                <ng-container *ngFor="let time of weeklyOffGroup.get('timeOff.timeRange').controls; let j = index;">
                  <div [formGroupName]="j"
                       class="lg:col-8 md:col-9 mr-0 ml-0 grid align-items-center lg:justify-content-center md:justify-content-start">
                    <div class="col-5">
                      <ng-container *ngIf="!weeklyOffGroup.get('timeOff.wholeDay').value">
                        <tj-field label="Start time">
                          <tj-time placeholder="Select" formControlName="startTime"
                                   [currentDate]="currentDate"></tj-time>
                        </tj-field>
                      </ng-container>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-5">
                      <ng-container *ngIf="!weeklyOffGroup.get('timeOff.wholeDay').value">
                        <tj-field label="End time">
                          <tj-time placeholder="Select" formControlName="endTime" [currentDate]="currentDate"></tj-time>
                        </tj-field>
                      </ng-container>
                    </div>
                  </div>
                  <div class="lg:col-4 md:col-3 flex justify-content-center align-items-center">
                    <ng-container *ngIf="!weeklyOffGroup.get('timeOff.wholeDay').value">
                      <button pButton
                              pRipple
                              icon="pi pi-plus"
                              class="p-button-success mr-2"
                              (click)="addTime(i)"
                      ></button>
                    </ng-container>
                    <ng-container *ngIf="weeklyOffGroup.controls.timeOff.controls.timeRange.length > 1">
                      <button pButton
                              pRipple
                              icon="pi pi-trash"
                              class="p-button-danger"
                              (click)="removeTime(i, j)"
                      ></button>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>

          <td class="text-center col-2">
            <button pButton
                    pRipple
                    icon="pi pi-trash"
                    class="p-button-danger"
                    (click)="deleteWeeklyOff(i)"
            ></button>
          </td>
        </div>
      </div>

      <ng-container *ngIf="weeklyOffArrayForm.controls?.length < 7">
        <div [ngClass]="weeklyOffArrayForm.controls?.length !== 0 ? 'text-right mt-3' : 'text-center'" class="col-12 ">
          <button pButton
                  pRipple
                  class="p-button-info p-button-raised"
                  icon="pi pi-plus"
                  label="Technician’s Day / Time Off"
                  (click)="addWeeklyOff()"
          ></button>
        </div>
      </ng-container>
    </div>
  </div>
</form>
