<div class="grid align-items-end justify-content-between">
  <div class="col">
    <label>Select Account Partner
      <ng-container *ngIf="!isAutoAssignedPartner()">/ 3rd Party - None Account</ng-container>
      <tj-partner-combobox (partnerChange)="onPartnerChange($event)"
                           [initPrivatePartner]="!isAutoAssignedPartner()"
                           [disabled]="isAutoAssignedPartner()">
      </tj-partner-combobox>
    </label>
  </div>
</div>
