<ng-container>
  <div class="grid">
    <div class="col-12">
      <p-checkbox [(ngModel)]="checked" label="Business?" binary="true" (onChange)="onBusinessTicket()"
                  [disabled]="isAutoAssignedPartner()">
      </p-checkbox>
      <ng-container *ngIf="ticket.partner?.hasOverdueInvoices">
        <span class="ml-2 font-semibold text-red-600">(Overdue)</span>
      </ng-container>
    </div>
    <ng-container *ngIf="isPartner">
      <div class="col-12">
        <tj-partner-type-selector [partner]="ticket.partner"
                                  [ticketPartnerStatus]="ticket.ticketPartnerStatus"
                                  (partnerChange)="onPartnerChange($event)"
                                  (usePrivatePartner)="onUsePrivatePartner($event)"
                                  (resetPartner)="onResetPartner($event)"
        ></tj-partner-type-selector>
      </div>
      <div class="col-12">
        <ng-container *ngIf="ticket.partner && ticket.partner.id">
          <tj-partner-contact-person-selector
            [form]="form"
            [partner]="ticket.partner"
            [mainContactPerson]="preselectedContactPerson"
            ></tj-partner-contact-person-selector>
        </ng-container>
      </div>
      <div class="col-12">
        <ng-container *ngIf="ticket.partner && ticket.partner.id === null">
          <tj-private-partner-form [partner]="ticket.partner" [form]="form" group="partner"></tj-private-partner-form>
        </ng-container>
      </div>
      <div class="col-12">
        <ng-container *ngIf="ticket.mainPartnerContactPerson && ticket.mainPartnerContactPerson.id">
          <tj-contact-person-details [contactPerson]="ticket.mainPartnerContactPerson"></tj-contact-person-details>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
