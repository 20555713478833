import {Component, OnInit} from '@angular/core';
import {Notification, NotificationService} from '../../services/notification.service';
import {LayoutService} from '../../../layout/service/app.layout.service';


@Component({
  selector: 'tj-notifications',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: Notification[] = [];
  marginTopValue = '190px';

  constructor(private layoutService: LayoutService) {
  }

  ngOnInit() {
    if (this.layoutService.isMobile()) {
      this.marginTopValue = '40vh';
    }
  }

}
