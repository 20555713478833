import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tj-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrls: ['./subtitle.component.scss']
})
export class SubtitleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
