import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, OnInit } from '@angular/core';
import { TjKeycloakService } from '../../services';

@Directive({
  selector: '[tjHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {

  @Input() tjHasRole: string | string[];

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private keycloakService: TjKeycloakService
  ) { }

  ngOnInit(): void {

    const makeItVisible = () => {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    };

    const roles = Array.isArray(this.tjHasRole) ? this.tjHasRole : [this.tjHasRole];

    if (roles.length && roles[0]) {
      const hasAccess = this.keycloakService.hasAnyRole(roles);
      if (hasAccess) {
        makeItVisible();
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    } else {
      makeItVisible();
    }
  }

  ngOnDestroy(): void {

  }

}
