<div [formGroup]="form" class="grid">
  <div class="col">
    <div class="grid">
      <div class="col-12">
        <p-panel header="Technician main data">
          <div class="grid">
            <div class="col-6">
              <tj-field [control]="getControl('firstName')" label="First name">
                <input formControlName="firstName" id="firstname" pInputText placeholder="First name"/>
              </tj-field>
            </div>
            <div class="col-6">
              <tj-field [control]="getControl('lastName')" label="Last name">
                <input formControlName="lastName" id="lastname" pInputText placeholder="Last name"/>
              </tj-field>
            </div>
            <div class="col-12">
              <tj-field [control]="getControl('personnelCode')" label="Personnel code">
                <input formControlName="personnelCode" id="personnelCode" pInputText placeholder="Personnel code"/>
              </tj-field>
            </div>
            <div class="col-6">
              <tj-field [control]="getControl('phoneNumber')" label="Phone">
                <input formControlName="phoneNumber" id="phoneNumber" pInputText placeholder="Phone number"/>
              </tj-field>
            </div>
            <div class="col-6">
              <tj-field [control]="getControl('email')" label="Email">
                <input formControlName="email" id="email" pInputText placeholder="Email"/>
              </tj-field>
            </div>
            <div class="col-6">
              <tj-field [control]="getControl('username')" label="Username">
                <input formControlName="username" id="username" pInputText placeholder="Username"/>
              </tj-field>
            </div>
            <div class="col-6">
              <tj-field [control]="getControl('companyName')" label="Company name">
                <input formControlName="companyName" id="companyName" pInputText placeholder="Company name"/>
              </tj-field>
            </div>
            <div class="col-12">
              <tj-field [control]="getControl('speciality')" label="Specialty/Restrictions">
                <input formControlName="speciality" id="speciality" pInputTextarea
                       placeholder="Speciality"/>
              </tj-field>
            </div>
            <div class="col-12" *ngIf="technician.id == null">
              <tj-field [control]="getControl('password')" label="Password">
                <input formControlName="password" id="password" pInputText placeholder="Password" type="password"/>
              </tj-field>
            </div>
          </div>
        </p-panel>
      </div>

      <div class="col-12">
        <p-panel header="Other details">
          <div class="grid">
            <div class="col-12">
              <tj-field [control]="getControl('notes')" label="Additional notes">
                <textarea formControlName="notes" id="notes" pInputTextarea placeholder="Additional notes"></textarea>
              </tj-field>
            </div>
          </div>
        </p-panel>
      </div>

      <div class="col-12">
        <p-panel header="Calendar / List details">
          <div class="grid">
            <div class="col-fixed mr-4">
              <p-checkbox formControlName="showOnCalendar" label="Show technician on calendar"
                          binary="true"></p-checkbox>
            </div>
            <div class="col-fixed mr-4">
              <p-checkbox formControlName="visibleInsideTicket" label="Show technician on ticket"
                          binary="true"></p-checkbox>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="col-12">
        <p-panel header="Vacations details">
          <div class="col-12 flex justify-content-center align-items-center">
            <div class="col-4">
              <p-calendar [disabledDates]="disabledVacationDates" formControlName="dateRange" dataType="string"
                          selectionMode="range" class="vacationCalendar col-3"
                          placeholder="Click to select a date or range."></p-calendar>
            </div>
            <button type="button"
                    class="p-button-info p-button-raised"
                    pButton
                    pRipple
                    label="Add vacation"
                    icon="pi pi-plus"
                    (click)="addVocationRange()"></button>
          </div>
          <div class="col-12">
            <tj-vacation-list
              [vacations]="getControl('vacations').value"
              (onDelete)="deleteVacation($event)"
              (onSort)="onSortVacations($event)">
            </tj-vacation-list>
          </div>
        </p-panel>
      </div>

      <div class="col-12">
        <p-panel header="Technician’s Daily Stops / Work Hours">
          <div class="col-12 flex justify-content-center">
            <div class="col-8 flex justify-content-center align-items-center">
              <div class="col-6 text-center">
                <label for="maxStops" class="mr-3">Max Stops Per Day</label>
                <p-inputNumber [incrementButtonClass]="'p-button-info'"
                               [decrementButtonClass]="'p-button-info'"
                               [showButtons]="true"
                               [placeholder]="'Stops'"
                               formControlName="maxStops"
                               id="maxStops"
                               [min]="0"
                               [max]="100"
                               class="justify-content-center"></p-inputNumber>
              </div>
              <div class="col-6 text-center">
                <label for="maxWorkHours" class="mr-3">Max Work Hours Per Day</label>
                <p-inputNumber [incrementButtonClass]="'p-button-info'"
                               [decrementButtonClass]="'p-button-info'"
                               [showButtons]="true"
                               [placeholder]="'Hours'"
                               formControlName="maxWorkHours"
                               id="maxWorkHours"
                               [min]="0"
                               [max]="24"
                               class="justify-content-center"></p-inputNumber>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
      <div class="col-12">
        <p-panel header="Technician’s Days / Times Off">
          <div class="col-12">
            <tj-weekly-off-form [form]="form" [weeklyOffs]="technician.weeklyOffs"
                                [technician]="technician"></tj-weekly-off-form>
          </div>
        </p-panel>
      </div>
      <div class="col-12">
        <p-panel header="Payment details">
          <div class="grid">
            <div class="col-6">
              <tj-field [control]="getControl('payType')" label="Pay type">
                <p-dropdown [options]="configService.payTypes"
                            formControlName="payType"
                            (onChange)='onPayTypeChange($event)'
                            placeholder="Select pay type"></p-dropdown>
              </tj-field>
            </div>
            <div class="col-6">
              <tj-field [control]="getControl('payFormDateRangeType')" label="Pay form date range type">
                <p-dropdown [options]="configService.payFormDateRangeTypes"
                            formControlName="payFormDateRangeType"
                            placeholder="Select range type"></p-dropdown>
              </tj-field>
            </div>
            <div class="col-6" *ngIf="getControl('payType').value === PayType.COMMISSION_BASED">
              <tj-field [control]="getControl('percent')" label="Commission">
                <div class="p-inputgroup">
                  <input type="number" formControlName="percent" pInputText placeholder="Commission">
                  <span class="p-inputgroup-addon">%</span>
                </div>
              </tj-field>
            </div>

            <div class="col-6" *ngIf="getControl('payType').value === PayType.HOUR_BASED">
              <tj-field [control]="getControl('unit')" label="Rate">
                <div class="p-inputgroup">
                  <input type="number" formControlName="unit" pInputText placeholder="value">
                  <span class="p-inputgroup-addon">$ per unit/hr.</span>
                </div>
              </tj-field>
            </div>

          </div>
        </p-panel>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <p-panel header="Address details">
          <div class="grid">
            <div class="col-12">
              <tj-address-create-form [form]="form" [address]="technician.address"
                                      group="address"></tj-address-create-form>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
    <div class="grid" *ngIf="configService.isEnabledTerritory() && configService.allowedToSeeAreas()">
      <div class="col-12">
        <p-panel header="Areas / Territory">
          <div class="grid">
            <div class="col-12 p-fluid">
              <p-multiSelect [options]="configService.areas$ | async"
                             formControlName="areas"
                             optionLabel="name"
                             [filter]="true"
                             showClear="true"
                             placeholder="Select technician work Areas"></p-multiSelect>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <p-panel header="Additional contact persons">
          <div class="grid">
            <div class="col-12">
              <tj-contact-persons-form [requiredOne]="false" [canChangeMainContactPerson]="false"
                                       [canChangeReceivers]="false"
                                       [minCount]="0"
                                       [contactPersons]="technician.contactPersons"
                                       [form]="form"></tj-contact-persons-form>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
</div>
