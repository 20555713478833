import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {Page, PageQuery, SortQuery} from '../model/shared/Page.model';
import {PartnerLine, PartnerMinimalListItem} from '../model/PartnerLine.model';
import {Partner} from '../model/Partner.model';
import { plainToClass } from 'class-transformer';

@Injectable()
export class PartnerApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'partners';

  findAll(
    pageQuery: PageQuery,
    sortQuery: SortQuery,
    filter
  ): Observable<Page<PartnerLine>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter
    };

    return this.requestService
      .get(`${this.apiEndpoint}`, {params})
      .pipe(
        map(response => response as Page<PartnerLine>),
        catchError(this.handleError)
      );
  }

  findOne(id: number): Observable<Partner> {
    const url = `${this.apiEndpoint}/${id}`;
    return this.requestService.get(url).pipe(
      map((response) => plainToClass(Partner, response.response)),
      catchError(this.handleError));
  }

  getMe(): Observable<Partner> {
    const url = `${this.apiEndpoint}/me`;
    return this.requestService.get(url).pipe(
      map((response) => plainToClass(Partner, response.response)),
      catchError(this.handleError));
  }

  getBaseMe(): Observable<PartnerMinimalListItem[]> {
    return this.requestService.get(`${this.apiEndpoint}/me`).pipe(
      map(response => response.response as Partner),
      map(partner => {
        const partnerMinimalList = new PartnerMinimalListItem();
        partnerMinimalList.id = partner.id;
        partnerMinimalList.name = partner.name;
        partnerMinimalList.isPrivate = partner.isPrivate;
        return [partnerMinimalList];
      }),
      catchError(this.handleError));
  }

  findList(): Observable<PartnerMinimalListItem[]> {
    return this.requestService.get(`${this.apiEndpoint}/list?sort=name&order=ASC`).pipe(
      map(response => response as Page<PartnerMinimalListItem>),
      map(response => response.response as PartnerMinimalListItem[]),
      catchError(this.handleError));
  }

  create(partner: Partner): Observable<Partner> {
    const url = this.apiEndpoint;
    return this.requestService.post(url, partner)
      .pipe(
        map(response => response.response as Partner),
        catchError(this.handleError)
      );
  }

  update(partner: Partner): Observable<Partner> {
    const url = this.apiEndpoint;
    return this.requestService.put(url, partner)
      .pipe(
        map(response => response.response as Partner),
        catchError(this.handleError)
      );
  }

  deactivate(partnerId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${partnerId}`;
    return this.requestService.remove(url)
      .pipe(
        map((response) => {
        }),
        catchError(this.handleError)
      );
  }

  recover(partnerId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${partnerId}/recover`;
    return this.requestService.put(url, {})
      .pipe(
        map(() => {
        }),
        catchError(this.handleError)
      );
  }

  hasOverdueInvoices() {
    const url = this.apiEndpoint + '/me/configs';
    return this.requestService.get(url).pipe(
      map(response => response.response.hasOverdueInvoices as boolean),
      catchError(this.handleError)
    );
  }
}
