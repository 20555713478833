import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import { PayPalCaptureResult } from '../model/PayPalCaptureResult.model';

@Injectable()
export class PaymentApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'payment';

  createPaypalOrder(extraData?, sandbox = true): Observable<any> {
    const url = `${this.apiEndpoint}/paypal/order?sandbox=${sandbox}`;

    return this.requestService.post(url, {
      ...extraData
    })
      .pipe(
        map(response => response.response),
        catchError(this.handleError)
      );
  }

  capturePaypalOrder(orderId: string, sandbox = true): Observable<PayPalCaptureResult> {
    const url = `${this.apiEndpoint}/paypal/order/${orderId}/capture?sandbox=${sandbox}`;
    return this.requestService.post(url, {})
      .pipe(
        map(response => response.response),
        catchError(this.handleError)
      );
  }

}
