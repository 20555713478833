import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'tj-topbar',
  templateUrl: './app.notfound.component.html',
})
export class AppNotFoundComponent implements OnInit {
  ngOnInit(): void {

  }
}
