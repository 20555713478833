<div class="grid">
  <div class="col">
    <tj-page-header>
      <tj-page-title>New ticket</tj-page-title>
    </tj-page-header>
    <form novalidate autocomplete="false">
      <div class="grid">
        <div class="col-12 lg:col-6">
          <p-panel header="Customer">
            <tj-customer-create-form [customer]="ticket.customer" [form]="ticketForm"
                                     group="customer"></tj-customer-create-form>
          </p-panel>
        </div>
        <div class="col-12 lg:col-6">
          <div class="grid">
            <div class="col-12">
              <p-panel header="Account / Requested by">
                <tj-ticket-create-partner [form]="ticketForm" [ticket]="ticket"></tj-ticket-create-partner>
              </p-panel>
            </div>
            <div class="col-12">
              <p-panel header="Request">
                <div class="grid">
                  <div class="col-12">
                    <div class="grid">
                      <div class="col-12">
                        <tj-issue-form [form]="ticketForm"
                                       [ticket]="ticket"></tj-issue-form>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </div>
      <div class="grid justify-content-end">
        <div class="col-fixed">
          <p-button (click)="onCreate()" [loading]="isSaving" class="primary button" label="Save"></p-button>
        </div>
        <div class="col-fixed">
          <p-button (click)="onCreateAndClose()" [loading]="isSaving" class="primary button" label="Save & Close"></p-button>
        </div>
        <div class="col-fixed">
          <p-button (click)="onClose()" class="secondary button" label="Cancel"></p-button>
        </div>
      </div>
    </form>
  </div>
</div>
