import { Tag } from '../model/Tag.model';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommunicationService } from '../service/communication.service';
import { Page } from '../model/shared/Page.model';

@Injectable()
export class TagApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'tags';

  findAll(): Observable<Tag[]> {
    return this.requestService.get(this.apiEndpoint).pipe(
      map(response => response.response),
      catchError(this.handleError), );
  }

  findList(): Observable<Tag[]> {
    return this.requestService.get(`${this.apiEndpoint}/list`).pipe(
      map(response => response as Page<Tag>),
      map(response => response.response as Tag[]),
      catchError(this.handleError), );
  }


  create(tag: Tag): Observable<Tag> {
    const url = this.apiEndpoint;
    return this.requestService.post(url, tag)
      .pipe(
        map(response => response.response as Tag),
        catchError(this.handleError)
      );
  }

  update(tag: Tag): Observable<void> {
    const url = `${this.apiEndpoint}/${tag.id}`;
    return this.requestService.put(url, tag)
      .pipe(
        map((response)  => {}),
        catchError(this.handleError)
      );
  }

  delete(id: number): Observable<void> {
    return this.requestService.remove(this.apiEndpoint + '/' + id).pipe(
      map(
        (response) => {}
      ),
      catchError(this.handleError)
    );
  }
}
