<div class="p-0 lg:flex md:flex sm:flex-row col-12">
  <p-autoComplete dataKey="id"
                  field="name"
                  [placeholder]="'Technician'"
                  [(ngModel)]="value"
                  [disabled]="disabled"
                  [suggestions]="filteredTechnicians"
                  [dropdown]="true"
                  (onSelect)="onTechnicianChange($event)"
                  [forceSelection]="true"
                  (completeMethod)="onFilterSuggestions($event)"
                  [ngClass]="addStyleClass">
    <ng-template let-value pTemplate="item">
      <div class="flex flex-column">
        <span [class]="showSpeciality ? 'font-semibold' : '' ">
          <i class="pi pi-map-marker" *ngIf="configService.isEnabledTerritory() && hasAreaCode(value)"></i>
          {{value.name}}
          <ng-container *ngIf="value.personnelCode">
            / {{value.personnelCode}}
          </ng-container>
          <ng-container *ngIf="value.companyName">
            / {{value.companyName}}
          </ng-container>
        </span>
        <span class="white-space-normal ml-3 md:text-sm sm:text-xs"
              *ngIf="showSpeciality && value.speciality != null && value.speciality != ''">
          {{value.speciality}}
        </span>
      </div>
    </ng-template>
  </p-autoComplete>
  <div *ngIf="showInfo && value" class="lg:col-4 md:col-4 sm:col-12 flex justify-content-center align-items-center">
    Code: <span class="ml-2 font-semibold" [pTooltip]="getTechnicianInfo()">{{value.personnelCode}}</span>
    <span class="ml-2 cursor-pointer pi pi-envelope text-blue-600"
          [cdkCopyToClipboard]="value.email"
          (cdkCopyToClipboardCopied)="onCopySuccess($event)"></span>
  </div>
</div>
