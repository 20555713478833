import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {Partner} from '../../../../api/model/Partner.model';
import {ConfigService} from '../../../../core/services';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';

@Component({
  selector: 'tj-private-partner-form',
  templateUrl: './private-partner-form.component.html',
  styleUrls: ['./private-partner-form.component.scss']
})
export class PrivatePartnerFormComponent extends BaseFormGroup implements OnInit {

  private _partner: Partner;

  @Input() set partner(partner: Partner) {
    this._partner = partner;
    if (this.form) {
      this.form.patchValue(partner);
    }
  }

  get partner() {
    return this._partner;
  }

  @Input() form: UntypedFormGroup;
  @Input() group: string;
  @Input() mainContactPerson: ContactPerson;

  private formControls;

  constructor(public configService: ConfigService) {
    super();
  }

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm() {
    const privatePartnerDefaultNetTerm = this.configService.getPrivateAccountDefaultNetTerm();
    const partner = this.partner;
    this.formControls = {
      name: new UntypedFormControl(partner.name || ''),
      netTerm: new UntypedFormControl(partner.netTerm || privatePartnerDefaultNetTerm || 15),
    };

    this.buildForm(this.form, this.formControls, this.group);
  }

}
