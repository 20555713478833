<form [formGroup]="zipCodeForm" (submit)="onSaveZipCode()" *ngIf="zipCodeForm">
  <div class="field">
    <div class="col-12">
      <div class="mt-2">
        <p-checkbox name="active" formControlName="active" binary="true" label="Active"></p-checkbox>
      </div>
    </div>

    <div class="col-12">
      <tj-field label="Zip Code" [control]="getControl('zipCode')">
        <input type="text" pInputText formControlName="zipCode" pKeyFilter="num"/>
      </tj-field>
      <small>Only 5 numbers</small>
    </div>

    <div class="col-12">
      <tj-field label="Travel Fee" [control]="getControl('travelFee')">
        <tj-money-input formControlName="travelFee"></tj-money-input>
      </tj-field>
      <small>Only number or decimal ( use <strong class="text-lg">․</strong> for decimal )</small>
    </div>
    <div class="flex flex-grow-1 justify-content-end">
      <div>
        <button pButton pRipple type="button"
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                (click)="onCancel()"></button>
      </div>
      <div>
        <button *tjHasRole="'ZIP_CODE_EDIT'" pButton pRipple type="submit" label="Save" icon="pi pi-check"
                class="p-button-text"
                [disabled]="!zipCodeForm.valid"></button>
      </div>
    </div>
  </div>
</form>
