<p-autoComplete dataKey="id"
                field="name"
                [(ngModel)]="value"
                [placeholder]="'Account'"
                [suggestions]="filteredPartners"
                [dropdown]="true"
                [forceSelection]="true"
                (completeMethod)="onFilterSuggestions($event)"
                [disabled]="disabled">
  <ng-template let-value pTemplate="item">
    <span [ngClass]="value.id == -1 ? 'font-bold' : ''">{{value.name}}</span><span *ngIf="value.isPrivate === true">(PRIVATE)</span>
  </ng-template>
</p-autoComplete>
