<div [formGroup]="currentFormGroup">
  <tj-field [control]="fcMessage">
    <tj-custom-input>
      <div class="p-inputgroup">
        <textarea pInputTextarea formControlName="message" rows="2" (keydown)="handleKeyDown($event)"></textarea>
        <button pButton pRipple icon="pi pi-send" styleClass="p-button-warn" type="submit"></button>
      </div>
    </tj-custom-input>
  </tj-field>
</div>
