export class Vacation {
  id?: number;
  startDate: Date | string;
  endDate: Date | string;

  constructor(startDate: Date | string, endDate: Date | string) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
