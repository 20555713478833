import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {PayFormSearchFilter} from '../../models/pay-form-search.filter';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'tj-pay-form-search-labels',
  templateUrl: './pay-form-search-labels.component.html',
  styleUrls: ['./pay-form-search-labels.component.scss']
})
export class PayFormSearchLabelsComponent implements OnChanges, OnInit {
  @Input() payFormSearchFilter: PayFormSearchFilter;
  @Input() filterForm: UntypedFormGroup;
  @Output() remove = new EventEmitter();

  filterNames = {
    technicianId: 'Technician',
    technicianCompanyName: 'Company name',
    technicianPersonnelCode: 'Technician code',
    status: 'Status',
    startDate: 'Start date',
    endDate: 'End date',
    ticketId: 'Ticket',
  };

  constructor(public config: ConfigService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  onRemoveFilter(filterName: string) {
    const updatedFilter = {...this.payFormSearchFilter};
    if (updatedFilter[filterName]) {
      updatedFilter[filterName] = null;
      if (this.filterForm && this.filterForm.get(`payFormFilter.${filterName}`)) {
        this.filterForm.get(`payFormFilter.${filterName}`).setValue(null);
      }
    }
    this.remove.emit(updatedFilter);
  }
}
