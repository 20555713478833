<form novalidate
      autocomplete="off"
      [formGroup]="filterForm"
      (reset)="onSearchReset()"
      (ngSubmit)="onFilterTickets()">
  <div class="grid">
    <div class="col-12">
      <tj-ticket-list-filter-form [ticketFilter]="ticketFilter"
                                  [form]="filterForm"
                                  group="ticketFilter"></tj-ticket-list-filter-form>
    </div>
    <div class="col-12 flex">
      <tj-ticket-search-chips
        [ticketFilter]="ticketFilter" [filterForm]="filterForm"
        (remove)="onRemoveFilter($event)"></tj-ticket-search-chips>
    </div>

    <div class="col-12 align-content-end flex justify-content-between">
      <div class="flex align-items-end">
        <span *ngIf="page">Total: {{page.totalElements}}</span>
      </div>
      <div>
        <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
        <button pButton type="submit" label="Search" icon="pi pi-search"></button>
      </div>
    </div>

  </div>
</form>
