<ng-container>
  <ng-container *ngIf="technicians && technicians.length">
    <ng-container *ngFor="let technician of technicians">
      <ng-container *ngIf="!canViewTechnicianPersonCode()">
        <span class="head"
              *ngIf="technician.head === true">{{shortMode ? getNameAbbr(technician.name) : technician.name}}</span>
        <span class="regular"
              *ngIf="technician.head === false">{{shortMode ? getNameAbbr(technician.name) : technician.name}}</span>
      </ng-container>
      <ng-container *ngIf="canViewTechnicianPersonCode()">
        <span class="regular">{{shortMode ? technician.personnelCode : technician.personnelCode}}</span>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!technicians || !technicians.length">
    <span
      [ngClass]="{'text-red-600 text-lg font-semibold': ticketStatus === TicketStatus.SCHEDULED }">No assignee</span>
  </ng-container>
</ng-container>
