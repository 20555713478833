import {map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Ticket} from '../../../../api/model/Ticket.model';
import {NotificationService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Messages} from '../../../../common/messages';

@Component({
  selector: 'tj-ticket-create',
  templateUrl: 'ticket-create.component.html',
  styleUrls: ['ticket-create.component.scss']
})
export class TicketCreateComponent implements OnInit {

  ticket: Ticket;

  ticketForm: UntypedFormGroup;

  isSaving = false;

  constructor(private router: Router,
              private notificationService: NotificationService,
              private api: ApiService) {
  }

  ngOnInit() {
    this.ticket = new Ticket();
    this.ticketForm = new UntypedFormGroup({});
  }

  onCreateAndClose() {
    if (this.ticketForm.valid) {
      this.isSaving = true;
      const ticket: Ticket = Ticket.buildBasedOnForm(this.ticket, this.ticketForm.value);
      this.createTicket(ticket)
        .subscribe(() => {
            this.isSaving = false;
            this.navigateToTickets();
          },
          error => {
            this.isSaving = false;
            this.notificationService.error(error);
          }
        );
    } else {
      FormHelpers.validateAllFormFields(this.ticketForm);
    }
  }

  onCreate() {
    if (this.ticketForm.valid) {
      this.isSaving = true;
      const ticket: Ticket = Ticket.buildBasedOnForm(this.ticket, this.ticketForm.value);
      this.createTicket(ticket)
        .subscribe(createdTicket => {
            this.isSaving = false;
            this.navigateToTicketEdit(createdTicket.id);
          },
          error => {
            this.isSaving = false;
            this.notificationService.error(error);
          }
        );
    } else {
      FormHelpers.validateAllFormFields(this.ticketForm);
    }
  }

  onClose() {
    this.navigateToTickets();
  }

  private createTicket(ticket): Observable<Ticket> {
    ticket.ticketPartnerStatus = ticket.detectTicketPartnerStatus();
    return this.api.ticket.create(ticket).pipe(
      map((createdTicket) => {
        this.ticket = createdTicket;
        this.notificationService.success(Messages.TICKET_CREATED);
        return this.ticket;
      }));
  }

  private navigateToTickets() {
    this.router.navigate(['/tickets']);
  }

  private navigateToTicketEdit(id: number) {
    this.router.navigate(['/tickets', id]);
  }

}
